const messages = {
  title: "Mitt personliga kontoutdrag",
  desc: "Här kan du se ditt personliga Bridgestone Plus-kontoutdrag med dina tjänade och använda bonuspoäng. Tänk på att det kan ta upp till 6 veckor att lägga till poäng för dina däckbeställningar, beroende på källa.",
  score: "Poängsaldo",
  regularPoints: "Standardpoäng",
  boosterPoints: "Bonuspoäng",
  date: "Datum",
  supplySourceReason: "Källa/Köporsak",
  article: "Artikel",
  brand: "Märke",
  product: "Produkt",
  type: "Typ",
  inch: "Tum",
  season: "Säsong",
  amount: "Antal",
  standardPoints: "Poäng",
  boosterProduct: "Bonusprodukt",
  boosterSeason: "Bonussäsong",
  otherActions: "Andra åtgärder eller skäl",
  totalPoints: "Totalt antal poäng",
  income: "Inkomst",
  spending: "Utgifter",
  lastMonth: "Slutsaldo",
  loadMore: "Ladda fler 20 transaktioner",
  typeFilter: "Filtrera efter källa",
  dateFilter: "Filtrera efter period",
  customer_number: "Kundnummer",
  currentPoints: "Aktuella poäng",
  calculationDate: "Beräkningsdatum",
  positivePoints: "Positiva poäng",
  negativePoints: "Redan spenderade",
  tyresAmount: "Antal däck per år",
  pointsTotal: "Totalt antal poäng",
  currentPointState: "Aktuellt poängsaldo",
  currentKickbackState: "Aktuell återbetalning", // "Kickback" kan översättas som "återbetalning"
};
export default {
  messages,
};

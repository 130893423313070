const messages = {
    "titleColorRev": "patiesi",
    "buttons": {
        "pending": "Gaida apstiprinājumu",
        "open": "Atvērts",
        "requestAccess": "Pieprasīt piekļuvi"
    },
    "my": "Manas lietotnes",
    "directRoute": "Tavi tiešie saites uz Bridgestone tīmekļa lietotnēm.",
    "welcomeMessage": "Laipni lūdzam BRIDGESTONE PLUS <span class=\"text-danger\">PLUS</span>, %{first_name} %{last_name}",
    "showProfile": "Rādīt profilu",
    "newMessages": "Jauna ziņa iesūtnē",
    "myApps": "Manas lietotnes",
    "allApps": "Visas lietotnes",
    "myAppsDescription": "Visas Bridgestone tīmekļa lietotnes, kurās esat reģistrējies.",
    "allAppsDescription": "Visas Bridgestone tīmekļa lietotnes, kas varētu būt jums aktuālas.",
    "myProfile": {
        "welcomeMessage": "Laipni lūdzam jūsu personīgajā sadaļā. Šeit varat atjaunināt vai pielāgot savus datus un apskatīt savu iesūtni.",
        "masterData": "Pamatdati",
        "completeDetails": "Aizpildiet savus personīgos un uzņēmuma datus, lai nodrošinātu pareizu reģistrāciju.",
        "goToMasterData": "Doties uz maniem datiem",
        "goToInbox": "Doties uz iesūtni"
    },
    "masterData": {
        "welcomeMessage": "Laipni lūdzam jūsu personīgajā sadaļā. Šeit varat atjaunināt vai pielāgot savus pamatdatus.",
        "personalDetails": "Personas dati",
        "companyDetails": "Uzņēmuma dati",
        "emailAddress": "E-pasta adrese",
        "title": "Tituls",
        "surname": "Uzvārds",
        "firstName": "Vārds",
        "jobPosition": "Amats",
        "streetAndHouseNumber": "Uzņēmuma adrese",
        "postalCode": "Pasta indekss",
        "city": "Pilsēta",
        "country": "Valsts",
        "legalStructure": "Juridiskā forma",
        "customerNumber": "Bridgestone klienta numurs",
        "importantPleaseRead": "Svarīgi, lūdzu, izlasiet",
        "customerNumberInfo": "Lai nodrošinātu pareizu jūsu riepu pasūtījumu saistīšanu ar dažādiem piegādātājiem, ir svarīgi norādīt savu klienta numuru.",
        "multipleCustomerNumbers": "Ja jums ir vairāki klientu numuri pie piegādātāja, piemēram, jūsu galvenajai kompānijai un filiālēm, vispirms jānorāda galvenās kompānijas klienta numurs.",
        "salesAssignment": "Pārdošanas dati, kas piesaistīti citiem klienta numuriem, tiks attiecināti uz jūsu galveno kompāniju.",
        "supplier": "Piegādātājs",
        "addAnotherCustomerNumber": "Pievienot klienta numuru",
        "save": "Saglabāt",
        "delete": "Dzēst"
    },
    "inbox": {
        "welcomeMessage": "Šeit varat atrast paziņojumus un svarīgas ziņas.",
        "sender": "Nosūtītājs",
        "subject": "Temats",
        "date": "Datums",
        "formConsulting": "Forma saņemta - Paldies, ka to aizpildījāt",
        "back": "Atpakaļ"
    },
    "legalAndContact": {
        "cookieSettings": "Sīkdatņu iestatījumi",
        "dataPolicy": "Datu politika",
        "termsOfUse": "Lietošanas noteikumi",
        "helpAndContact": "Palīdzība un kontakti"
    },
    "logInSignUp": {
        "logIn": "Pieteikties",
        "signUp": "Reģistrēties",
        "logInMessage": "Pieteikties ar savu e-pasta adresi un paroli.",
        "signUpMessage": "Ja esat jauns lietotājs, lūdzu, reģistrējieties."
    }
}

export default {
    messages
}
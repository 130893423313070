const messages = {
  subtitle: "Meet Our Bridgestone team",
  title: "Contactos e Serviços",
  description:
    "Bem-vindo à Área de Assistência da BRIDGESTONE PLUS. Aqui podes encontrar os detalhes dos contactos da equipa de vendas.",
  teams: {
    direct: "Direct",
    indirect: "In Direct",
    subtitle: "Meet Our Bridgestone team",
  },
};

export default {
  messages,
};

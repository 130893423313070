const messages = {
  "title": "Welcome to BRIDGESTONE <span class='text-danger'>PLUS</span>",
  "description": "<b>Already have an account?</b><br/>Log in with your email address and password.<br/><br/><b>New here?</b><br/>If you are a new user, please register.<br/><br/>",
  "info": "",
  "buttons": {
    "action": "Log in / Register",
    "back": "Back to login",
    "changeLanguage": "Change language",
    "start": "Start"
  },
  "languageSelect": {
    "label": "Language selection",
    "description": "Please select your language"
  }
}

export default {
  messages
}
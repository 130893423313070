<template>
    <div class="px-5 py-5 bg-gray">
        <h1 v-html="$t('onboarding.title')"></h1>
        <div class="pb-2">{{ $t('onboarding.tailorOffer') }}</div>
        <h5 class="w-100 text-end pb-2 text-uppercase">{{ $t('onboarding.stage') }} 2/3</h5>
        <MDBCard>
            <MDBCardBody>
                <div class="pb-3">
                    <div>{{ $t('onboarding.selectAreas') }}</div>
                    <div><i>{{ $t('onboarding.chooseOne') }}</i></div>
                </div>
                <div>
                    <input class="form-check-input" type="checkbox" :value="true" :unchecked-value="false"
                        @change="checkValues('carTyres')" v-model="selections['carTyres']" />
                    <label class="form-check-label">
                        {{ $t('onboarding.carTyres') }}
                    </label>
                </div>
                <div>
                    <input class="form-check-input" type="checkbox" :value="true" :unchecked-value="false"
                        @change="checkValues('motorcyclesTyres')" v-model="selections['motorcyclesTyres']" />
                    <label class="form-check-label">
                        {{ $t('onboarding.motorcyclesTyres') }}
                    </label>
                </div>
                <div>
                    <input class="form-check-input" type="checkbox" :value="true" :unchecked-value="false"
                        @change="checkValues('truckBusTyres')" v-model="selections['truckBusTyres']" />
                    <label class="form-check-label">
                        {{ $t('onboarding.truckBusTyres') }}
                    </label>
                </div>
                <div>
                    <input class="form-check-input" type="checkbox" :value="true" :unchecked-value="false"
                        @change="checkValues('agricultureTyres')" v-model="selections['agricultureTyres']" />
                    <label class="form-check-label">
                        {{ $t('onboarding.agricultureTyres') }}
                    </label>
                </div>
                <div>
                    <input class="form-check-input" type="checkbox" :value="true" :unchecked-value="false"
                        v-model="selections['otrTyres']" @change="checkValues('otrTyres')" />
                    <label class="form-check-label">
                        {{ $t('onboarding.otrTyres') }}
                    </label>
                </div>
            </MDBCardBody>
        </MDBCard>

        <div class="w-100 d-flex justify-content-between mt-5 mb-1">
            <div>{{ $t('onboarding.required') }}</div>
            <div class="d-flex">
                <MDBBtn @click="goBack()" color="danger">{{ $t("global.buttons.back") }}</MDBBtn>
                <MDBBtn type="button" color="danger" class="d-flex justify-content-center align-items-center "
                    :disabled="disableBtn" @click="onSubmit()">
                    <span>{{ $t('global.buttons.save') }}</span>
                </MDBBtn>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { MDBCardBody, MDBCard, MDBBtn } from "mdb-vue-ui-kit";
import { ProfileService } from "@/services/profile.service";

const me = computed(() => store.getters.getMe);
const bussinessUnits = computed(() => store.getters.getBussinessUnits);
bussinessUnits
const selections = ref({
    carTyres: false,
    motorcyclesTyres: false,
    truckBusTyres: false,
    agricultureTyres: false,
    otrTyres: false
})

const options = ref([])

const disableBtn = ref(true)
const store = useStore();

const checkValues = (entry) => {

    store.dispatch('addInitialParentAttributes', []);
    const valueExists = options.value.includes(entry);

    if (valueExists) {
        options.value.splice(options.value.indexOf(entry), 1);
    } else {
        options.value.push(entry);
    }

    const data = {
        user_id: me.value.id,
        options: options.value,
    }

    store.dispatch('addInitialParentAttributes', data);

    disableBtn.value = options.value.length == 0
}

const goBack = async () => {
    const data = {
        id: me.value.id,
        intro: 1,
    };
    await ProfileService.updateUserLiteIntro(data);
    await store.dispatch("me");
}

const onSubmit = async () => {
    if (!disableBtn.value) {
        disableBtn.value = false

        const data = {
            id: me.value.id,
            intro: 3,
        };

        await ProfileService.updateUserLiteIntro(data);
        store.dispatch('displayInitialSurvey', 3)
    }
}
</script>
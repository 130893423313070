// src/composables/useTenantConfig.js
import { ref, watchEffect } from 'vue'
import { tenantConfigs } from '@/configs/tenantConfig.js'
import store from '@/store' // Import your Vuex store or equivalent

export function useTenantConfig() {
    const tenantConfig = ref(tenantConfigs.DEFAULT)
    const show = ref(false)

    watchEffect(() => {
        const tenantId = store.getters.tenantId;
        //alert(tenantId)
        tenantConfig.value = tenantConfigs.tenants[tenantId] || tenantConfigs.DEFAULT
        //console.log(tenantConfig.value)
        show.value = true
    })

    return {
        tenantConfig,
        show
    }
}

<template>
  <CategoryTop :title="$t('contactService.title')" :breadcrumbs-parent-routes="isLite ? [] : ['contact_service']"
    :description="$t('contactService.description')" />
</template>

<script setup>
import CategoryTop from "../../General/CategoryTop/index.vue";
import { computed } from "vue";
import { useStore } from "vuex";
// CHECK if country is in LiteVersion array
const store = useStore();
const isLite = computed(() => store.getters.isLiteVersion);
</script>

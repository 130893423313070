const messages = {
  makeChoice: "Vyberte si tu.",
  seeAll: "Zobraziť všetko",
  clear: "Vyčistiť",
  back: "Späť",
  save: "Uložiť",
  close: "Zatvoriť",
  of: "z",
  to: "do",
  copyrights: "© {date} Bridgestone Solutions. Všetky práva vyhradené.",
  pageNotFound: "Stránka nebola nájdená",
  accessDenied: "Prístup zamietnutý",
  search: "Hľadať",
  buttons: {
    export: "Exportovať",
    delete: "Vymazať",
    save: "Uložiť",
    cancel: "Zrušiť",
    next: "Ďalej",
    back: "Späť",
    skip: "Preskočiť",
    send: "Odoslať",
    readNow: "Přečtěte si nyní",
    pointsList: "Zoznam bodov",
    clear: "Vyčistiť",
    more: "Viac",
    close: "Zatvoriť",
  },
  tooltips: {
    info: "Potrebujete pomoc? +421 905 306 674",
  },
  confirmation: {
    header: "Vyžaduje sa potvrdenie",
    message: "Chcete túto položku vymazať?",
  },
  nav: {
    dashboard: "Domov",
    account: "Môj Profil",
    mailbox: "Schránka",
    singleMailbox: "Správy",
    stammdaten: "Profil",
    news_feeds: "Správy",
    pointsOverview: "Points overview",
    about: "O BS Plus",
    service_number: "Contact service",
  },
  menu: {
    account: "Môj Profil",
    plus: 'O Bridgestone <span class="text-danger">Plus</span>',
    salesSupport: "Podpora predaja",
    mailbox: "Schránka",
    about: "O BS Plus",
    trainingAndCourses: "Školenia a kurzy",
    externalTraining: "Externé školenie",
    productInfo: "Informácie o produktoch",
    productBrochures: "Produktové brožúry",
    priceLists: "Cenníky",
    price: "Cena",
    complaintForm: "Formulár sťažnosti",
    sales_support: "Podpora predaja",
    contact_service: "Kontaktné údaje a servis",
    logOut: "Odhlásiť sa",
    news: "Novinky",
    news_feeds: "Správy",
    service_number: "Contact service",
  },
  validation: {
    requiredField: "Povinné pole",
  },
};

export default {
  messages,
};

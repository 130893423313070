import TokenService from "./storage.service";
import router from "../router";
import axios from "axios";
import store from "@/store/index";
import { toast } from "vue3-toastify";
import { TENANCY_SETTINGS } from "@/config";

const LANG = localStorage.getItem("currentLocale");

const ApiService = {
  async init(baseURL, tenantId, locale) {
    // Set headers and other configuration
    axios.defaults.headers.common["X-Tenant"] = tenantId.toUpperCase();
    axios.defaults.headers.common["Accept-Language"] = locale;
    axios.defaults.baseURL = baseURL;

    // Set up interceptors
    this.setupInterceptors();
  },

  setupInterceptors() {
    axios.interceptors.request.use((config) => {
      store.dispatch("addPending", true);
      config.headers.common.Authorization = `Bearer ${TokenService.getToken()}`;
      return config;
    });

    axios.interceptors.response.use(
      (response) => {
        toast.clearAll();
        store.dispatch("addPending", false);
        if (response.config.method !== "get") {
          // this.showToast('success', 'message.toast.success')
        }
        return response;
      },
      (error) => {
        store.dispatch("addPending", false);
        // this.showToast('error', 'message.toast.error')
        if (error.response && error.response.status === 401) {
          router.push("/error");
        }
        return Promise.reject(error);
      }
    );
  },

  setHeader(contentType = "application/json") {
    axios.defaults.headers.common["Content-Type"] = contentType;
    axios.defaults.withCredentials = true;
  },

  removeHeader() {
    delete axios.defaults.headers.common["Authorization"];
    delete axios.defaults.headers.common["Content-Type"];
  },

  csrfCookie() {
    return axios.get("/sanctum/csrf-cookie");
  },

  request(method, resource, data = null, headers = {}) {
    const urlPrefix = TENANCY_SETTINGS.TENANCY
      ? "/api/v1/tenancy/"
      : `/api/v1/${LANG}/`;
    const url = urlPrefix + resource;
    return axios({ method, url, data, headers });
  },

  get(resource) {
    return this.request("get", resource);
  },

  post(resource, data, headers) {
    return this.request("post", resource, data, headers);
  },

  logout(resource, data, headers) {
    return this.request("post", "/users/logout", data, headers);
  },

  put(resource, data) {
    return this.request("put", resource, data);
  },

  delete(resource, data = undefined) {
    return this.request("delete", resource, data);
  },

  patch(resource, data) {
    return this.request("patch", resource, data);
  },
};

export default ApiService;

const messages = {
  subtitle: "Meet Our Bridgestone team",
  title: "Contact service",
  description: "Welcome to contact service",
  teams: {
    direct: "Direct",
    indirect: "In Direct",
    subtitle: "Meet Our Bridgestone team",
  },
};

export default {
  messages,
};

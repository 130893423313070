const messages = {
  makeChoice: "Treffen Sie hier Ihre Wahl.",
  seeAll: "Alle anzeigen",
  clear: "Löschen",
  back: "Zurück",
  save: "Speichern",
  close: "Schließen",
  of: "Von",
  to: "Bis",
  copyrights: "© {date} Bridgestone Solutions. Alle Rechte vorbehalten.",
  pageNotFound: "Seite nicht gefunden",
  accessDenied: "Zugriff verweigert",
  search: "Suche",
  buttons: {
    export: "Exportieren",
    delete: "Löschen",
    save: "Speichern",
    cancel: "Abbrechen",
    next: "Weiter",
    back: "Zurück",
    skip: "Überspringen",
    send: "Senden",
    pointsList: "Punkteliste",
    clear: "Löschen",
    readNow: "Jetzt lesen",
    more: "Mehr",
    close: "Schließen",
  },
  tooltips: {
    info: "Brauchen Sie Hilfe? +43 1 6141388",
  },
  confirmation: {
    header: "Bestätigung erforderlich",
    message: "Möchten Sie diesen Eintrag löschen?",
  },
  nav: {
    dashboard: "Startseite",
    account: "Mein Profil",
    mailbox: "Posteingang",
    singleMailbox: "Nachrichten",
    stammdaten: "Stammdaten",
    news_feeds: "News feeds",
    pointsOverview: "Points overview",
    about: "Über Brdigestone Plus",
    service_number: "Contact service",
  },
  menu: {
    account: "Mein Profil",
    plus: 'Über Bridgestone <span class="text-danger">Plus</span>',
    salesSupport: "Vertriebsunterstützung",
    mailbox: "Posteingang",
    about: "Über Brdigestone Plus",
    trainingAndCourses: "Schulungen und Kurse",
    externalTraining: "Externe Schulungen",
    productInfo: "Produktinformationen",
    productBrochures: "Produktbroschüren",
    priceLists: "Preislisten",
    price: "Preis",
    complaintForm: "Beschwerdeformular",
    sales_support: "Vertriebsunterstützung",
    contact_service: "Kontaktinformationen und Service",
    logOut: "Abmelden",
    news: "Neuigkeiten",
    news_feeds: "Neuigkeiten",
    service_number: "Contact service",
  },
  validation: {
    requiredField: "Pflichtfeld",
  },
};

export default {
  messages,
};

const messages = {
  title: "Kezdőlap",
  welcomeMessage:
    'Üdvözöljük a BRIDGESTONE <span class="text-danger">PLUS</span>-ban, %{first_name} %{last_name}',
  home: "Kezdőlap",
  search: "Keresés",
  sortBy: "Rendezés",
  seeMore: "Több megtekintése",
  bankstatement: {
    yourScore: "Jelenlegi marketingpontjainak száma:",
    lastUpdate: "Legutóbbi frissítés:",
    link: "Pontegyensúly áttekintése",
  },
};
export default {
  messages,
};

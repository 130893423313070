// src/composables/useProfileFieldCheck.js
export function useProfileFieldShow(tenantConfig) {
    const showProfileField = (fieldName) => {
        return tenantConfig.value?.profileFieldsExtraShow?.includes(fieldName) || false;
    };

    return {
        showProfileField
    };
}

export function useProfileFieldHide(tenantConfig) {
    const showProfileField = (fieldName) => {
        return tenantConfig.value?.profileFieldsExtraHide?.includes(fieldName) || false;
    };

    return {
        showProfileField
    };
}

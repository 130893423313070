<template>
  <div style="padding: 10px; background-color: red; color: white" class="d-none">
    DEBUG: LiteVersion: {{ isLite }}
  </div>
  <router-view />
  <MDBModal side direction="right" tabindex="-1" labelledby="exampleModalLabel" v-model="showHint" class="intro__modal"
    style="background: transparent" :class="[
      `${user?.commitment_letter_accept && user?.intro == 3 && isGermany
        ? 'intro__bottom'
        : ''
      }`,
      `${user?.commitment_letter_accept && user?.intro == 4 && isNetherland
        ? 'intro__bottom'
        : ''
      }`,
    ]">
    <Content />
  </MDBModal>
  <div class="intro__bg" v-if="showHint"></div>
</template>

<script setup>
import Content from "@/components/Intro/Content.vue";
import { MDBModal } from "mdb-vue-ui-kit";

import { computed, watch, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { useRouter } from "vue-router";
import TokenService from "@/services/storage.service";

const store = useStore();
const route = useRoute();
const router = useRouter();
const user = computed(() => store.getters.getMe);
const isUnknown = computed(() => store.getters.getIsUnknown);
const LANG = localStorage.getItem("lang");
const isGermany = computed(() => LANG === "de");
const isNetherland = computed(() => LANG === "nl");
// CHECK if country is in LiteVersion array
const isLite = computed(() => store.getters.isLiteVersion).value;

const showHint = computed(() => {
  if (isUnknown.value || isLite) {
    return false;
  }
  if (!user.value?.commitment_letter_accept) {
    return false;
  }
  if (isGermany.value) {
    return user.value?.intro < 4;
  } else if (isNetherland.value) {
    return user.value?.intro < 5;
  } else {
    return user.value?.intro < 4;
  }
});
const firstLoad = ref(true);

watch(
  () => route.query,
  (newVal) => {
    if (newVal.token && newVal.userId) {
      store.dispatch("userB2CLogin", {
        token: newVal.token,
        userId: newVal.userId,
      });
    }
  }
);

watch(
  () => route.meta,
  (newVal) => {
    if (!isLite) {
      if (newVal.requiredLang && !newVal.requiredLang.includes(LANG)) {
        router.push({
          name: "accessDenied",
        });
      }
    }
  }
);

watch(() => route.name, (newVal) => {
  const routes = ["shopCategory", "productDetails"]
  if (!routes.includes(newVal)) {
    TokenService.removeProductPage()
    TokenService.removeProductName()
    TokenService.removeProductPointsFrom()
    TokenService.removeProductPointsTo()
  }
})

onMounted(() => {
  if (route.query.token && route.query.userId) {
    store.dispatch("userB2CLogin", {
      token: route.query.token,
      userId: route.query.userId,
    });
  }

  const sliders = JSON.parse(TokenService.getSliders());
  const mainBoxes = JSON.parse(TokenService.getMainBoxes());
  const textPages = JSON.parse(TokenService.getFooter());
  const menu = JSON.parse(TokenService.getMenu());

  if (sliders) {
    store.commit("SET_USER_SLIDES", sliders);
    store.commit("SET_USER_MAIN_BOXES", mainBoxes);
    store.commit("SET_TEXT_PAGES", textPages);
    store.commit("SET_MENU", menu);
  }
});

setInterval(() => {
  if (firstLoad.value) {
    firstLoad.value = false;
  } else {
    window.location.reload(true);
  }
}, 360000);

</script>

<style lang="scss">
@import "./styles/fonts";
@import "./styles/global";

body>#app>.intro__bg {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  z-index: 1050;
}
</style>

const messages = {
    "titleColorRev": "liivakarva",
    "buttons": {
        "pending": "Ootel",
        "open": "Avatud",
        "requestAccess": "Taotle juurdepääsu"
    },
    "my": "Minu rakendused",
    "directRoute": "Teie otselingid Bridgestone'i veebirakendustesse.",
    "welcomeMessage": "Tere tulemast BRIDGESTONE PLUS <span class=\"text-danger\">PLUS</span>, %{first_name} %{last_name}",
    "showProfile": "Kuva profiil",
    "newMessages": "Uus sõnum postkastis",
    "myApps": "Minu rakendused",
    "allApps": "Kõik rakendused",
    "myAppsDescription": "Kõik Bridgestone'i veebirakendused, kuhu olete registreerunud.",
    "allAppsDescription": "Kõik Bridgestone'i veebirakendused, mis võivad teile asjakohased olla.",
    "myProfile": {
        "welcomeMessage": "Tere tulemast oma isiklikule alale. Siin saate uuendada või kohandada oma andmeid ja vaadata oma postkasti.",
        "masterData": "Põhiandmed",
        "completeDetails": "Täitke oma isiklikud ja ettevõtte andmed korrektseks registreerimiseks.",
        "goToMasterData": "Mine minu andmetesse",
        "goToInbox": "Mine postkasti"
    },
    "masterData": {
        "welcomeMessage": "Tere tulemast oma isiklikule alale. Siin saate uuendada või kohandada oma põhiandmeid.",
        "personalDetails": "Isiklikud andmed",
        "companyDetails": "Ettevõtte andmed",
        "emailAddress": "E-posti aadress",
        "title": "Tiitel",
        "surname": "Perekonnanimi",
        "firstName": "Eesnimi",
        "jobPosition": "Ametikoht",
        "streetAndHouseNumber": "Ettevõtte aadress",
        "postalCode": "Postiindeks",
        "city": "Linn",
        "country": "Riik",
        "legalStructure": "Õiguslik struktuur",
        "customerNumber": "Bridgestone kliendinumber",
        "importantPleaseRead": "Tähtis, palun lugege",
        "customerNumberInfo": "Et saaksime teie rehvitellimusi korrektselt siduda erinevate edasimüüjatega, on oluline, et te esitaksite oma kliendinumbri.",
        "multipleCustomerNumbers": "Kui teil on ühe edasimüüjaga mitu kliendinumbrit, näiteks teie peakontori ja filiaalide jaoks, sisestage esmalt peakontori kliendinumber.",
        "salesAssignment": "Täiendavate kliendinumbritega seotud müük määratakse teie peakontorile.",
        "supplier": "Tarnija",
        "addAnotherCustomerNumber": "Lisa kliendinumber",
        "save": "Salvesta",
        "delete": "Kustuta"
    },
    "inbox": {
        "welcomeMessage": "Siit leiate teavitused ja olulised uudised.",
        "sender": "Saatja",
        "subject": "Teema",
        "date": "Kuupäev",
        "formConsulting": "Vorm on vastu võetud - Aitäh täitmise eest",
        "back": "Tagasi"
    },
    "legalAndContact": {
        "cookieSettings": "Küpsiste seaded",
        "dataPolicy": "Andmekaitsepoliitika",
        "termsOfUse": "Kasutustingimused",
        "helpAndContact": "Abi ja kontakt"
    },
    "logInSignUp": {
        "logIn": "Logi sisse",
        "signUp": "Registreeru",
        "logInMessage": "Logige sisse oma e-posti aadressi ja parooliga.",
        "signUpMessage": "Kui olete uus kasutaja, registreeruge palun."
    }
}

export default {
    messages
}
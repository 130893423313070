const messages = {
  title: "Pagrindinis",
  welcomeMessage:
    'Sveiki atvykę į BRIDGESTONE <span class="text-danger">PLUS</span>, %{first_name} %{last_name}',
  home: "Pagrindinis",
  search: "Ieškoti",
  sortBy: "Rūšiuoti pagal",
  seeMore: "Rodyti daugiau",
  bankstatement: {
    yourScore: "Dabartinis jūsų rinkodaros taškų skaičius:",
    lastUpdate: "Naujausias atnaujinimas:",
    link: "Taškų balanso apžvalga",
  },
};

export default {
  messages,
};

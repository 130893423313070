const messages = {
  title: "Môj profil",
  desc: "Vitajte vo svojej osobnej oblasti. Tu môžete aktualizovať alebo upraviť svoje <br/>údaje a zobraziť svoju schránku.",
  descStaff:
    "Vitajte vo svojej osobnej oblasti. Tu môžete aktualizovať alebo upraviť svoje údaje.",
  buttons: {
    showProfile: "Zobraziť profil",
    userProfile: "PREJSŤ NA ZÁKLADNÉ ÚDAJE",
    mailbox: "PREJSŤ DO SCHRÁNKY",
  },
  categories: {
    baseData: {
      title: "Základné údaje",
      desc: "Vyplňte svoje osobné údaje a údaje o firme pre správnu registráciu.",
    },
    mailbox: {
      desc: "Dostávajte dôležité oznámenia, potvrdenia objednávok a novinky do svojej schránky.",
    },
    bankstatement: {
      title: "Výpis z účtu",
      desc: "Tu môžete vidieť stav svojho osobného účtu Bridgestone Plus a skontrolovať získané a použité body odmien.",
    },
  },
  profile: {
    general: "OSOBNÉ ÚDAJE ÚDAJE O FIRME",
    title: "Základné údaje",
    description:
      "Vitajte vo svojej osobnej oblasti. Tu môžete aktualizovať alebo upraviť svoje údaje.",
    personalData: "OSOBNÉ ÚDAJE",
    password: "Heslo",
    password1: "Heslo (nepovinné)*",
    password2: "Heslo (zopakovať)*",
    formOfAddress: "Titul",
    choose: "Vyberte titul",
    mr: "Pán",
    mrs: "Pani",
    diverse: "Iné",
    firstname: "Krstné meno",
    surname: "Priezvisko",
    position: "POZÍCIA VO FIRME",
    operatingData: "ÚDAJE O FIRME",
    company: "Firma",
    legalForm: "Právna forma",
    name: "Názov",
    streetHouseNr: "Adresa firmy",
    street: "Ulica",
    postcode: "PSČ",
    location: "Mesto",
    country: "Krajina",
    de: "Nemecko",
    at: "Rakúsko",
    ch: "Švajčiarsko",
    nl: "Holandsko",
    it: "Taliansko",
    language: "Jazyk",
    french: "Francúzština",
    italian: "Taliančina",
    german: "Nemčina",
    nederland: "Holandčina",
    openingHours: "Otváracie hodiny & kontakt",
    phone: "Telefón",
    website: "Webová stránka",
    operatingIndicators: "Prevádzkové ukazovatele",
    tireSale: "Ročný predaj pneumatík (približne v kusoch)",
    tireSaleLabel: "Predaj pneumatík vo veľkých množstvách",
    inches: "Z toho ≥ 18 palcov (v %)",
    inchesLabel: "Z toho 18 palcov alebo viac v %",
    segmentSale:
      "Predaj pneumatík podľa segmentu (podiel Economy / Stredný / Premium v %)",
    premBudget: "Premium",
    midBudget: "Stredný",
    budBudget: "Economy",
    distribution: "ROZDELENIE LETO / ZIMA / CELOROČNÉ (v %)",
    tyreSizes: "Počet miest na sezónne uskladnenie pneumatík (sady)",
    diffDeliveryAddres: "Pridať dodaciu adresu",
    yourEmailAddress: "Vaša e-mailová adresa.",
    clientNr: "Čísla zákazníkov",
    clientNrSingular: "Zákaznícke číslo",
    clientNrContent:
      "<span class='text-danger fw-bold'>DÔLEŽITÉ - PROSÍM, PREČÍTAJTE:</span><br /><br /> Pre správne priradenie vašich nákupov pneumatík u veľkoobchodníka a/alebo Bridgestone je potrebné, aby ste <b>uviedli svoje príslušné zákaznícke čísla.</b><br/><br/>Ak máte <b>viac zákazníckych čísel u veľkoobchodníka a/alebo Bridgestone,</b> napríklad pre svoju hlavnú predajňu a pobočky, najprv uveďte číslo hlavnej predajne.<br/><br/><b>Predaje z ostatných zákazníckych čísel budú priradené vašej hlavnej predajni.</b>",
    supplySource: "Zdroj dodávok",
    email: "E-mailová adresa",
    staffEmail: "E-mailová adresa účastníka",
    registered: "Registrované dňa",
    registeredAt: "Registrované dňa",
    lastLogIn: "Posledné prihlásenie",
    details: "Detaily",
    inDetail: "{name} v detailoch",
    bsClientNr: "Vaše zákaznícke číslo Bridgestone",
    bftClientNr: "Vaše zákaznícke číslo Best4Tires",
    acceptTerms: "Prijať podmienky účasti.",
    newCustomerNumbersInfo:
      "<span class='text-danger fw-bold'>DÔLEŽITÉ - PROSÍM, PREČÍTAJTE:</span><br />Pri prvom zadaní zákazníckeho čísla budú vaše údaje overené na správnosť a splnenie našich distribučných kritérií. Rozhodnutie o aktivácii čísla/čísel a ich prepojení s vaším profilom môže trvať až 5 pracovných dní.",
    selectWholeSaler: "u dodávateľa (prosím, vyberte)",
    cooperation: "ČLENSTVO / SPOLUPRÁCA",
    error100: "Súčet hodnôt musí byť rovný 100%.",
    errorRange: "Hodnota musí byť medzi 0 a 100",
    btw_number_label: "Vaše DIČ spoločnosti",
    btw_number: "DIČ",
    mobile: "Telefónne číslo",
    mobile_label: "Vaše telefónne číslo",
    iban_number: "IBAN vašej spoločnosti",
    iban_number_label: "IBAN číslo",
    yourCsNr: "Vaše zákaznícke čísla",
    yourLastName: "Vaše priezvisko",
    positionLabel: "Vyberte pozíciu",
    yourFirstName: "Vaše meno",
    companyLabel: "Spoločnosť",
    numberOfSetsOfSeasonalTiresBudget: "Ročné uskladnenie",
    contactInfo: "Ak máte otázky, kontaktujte nás prosím:",
    addAnotherCsNumber: "Pridať zákaznícke číslo Bridgestone",
    activityAndProfile: "OBCHODNÉ VZŤAHY A PROFIL",
  },
};
export default {
  messages,
};

const messages = {
  title: "Home",
  welcomeMessage:
    'Benvenuto in BRIDGESTONE <span class="text-danger">PLUS</span>, %{first_name} %{last_name}',
  home: "Home",
  search: "Ricerca",
  sortBy: "Ordina per",
  seeMore: "Vedi di più",
  bankstatement: {
    yourScore: "Il tuo attuale numero di punti marketing:",
    lastUpdate: "Ultimo aggiornamento:",
    link: "Panoramica del saldo punti",
  },
};

export default {
  messages,
};

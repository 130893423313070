const messages = {
  title: "Tere tulemast Bridgestone <span class='text-danger'>PLUS</span>",
  termsAndConditions: "Tingimused ja eeskirjad",
  accept: "Nõustu",
  decline: "Keeldu",
  dataModal:
    "Selle pakkumise kohandamiseks vastavalt teie huvidele palume teil lühidalt märkida oma tootehuvid või tegevusvaldkonnad.",
  scrollToAccept: "Jätkamiseks nõustuge meie tingimustega, kerides allapoole",
  welcomeMessage: "Tere tulemast Bridgestone Plus'i",
  tailorOffer:
    "Selle pakkumise kohandamiseks vastavalt teie huvidele palume teil lühidalt märkida oma tootehuvid või tegevusvaldkonnad.",
  selectAreas: "Valige palun järgmistest valdkondadest",
  multipleAnswers: "(mitu vastust on võimalik):",
  personalPreferences: "Isiklikud eelistused",
  carTyres: "Rehvid autodele, maasturitele ja tarbesõidukitele",
  motorcyclesTyres: "Rehvid mootorratastele",
  truckBusTyres: "Rehvid veoautodele ja bussidele",
  agricultureTyres: "Rehvid põllumajandussõidukitele",
  otrTyres: "OTR-rehvid",
  save: "Salvesta",
  businessRelationship: "Milline on teie ärisuhe Bridgestone'iga?",
  selectRelationship: "Valige palun*:",
  tradingPartner: "Olen kaubanduspartner, kes...",
  tradingPartnerDirect: "... tellib otse Bridgestone'ist",
  tradingPartnerIndirect: "... tellib kaudselt (näiteks hulgimüügi kaudu)",
  tradingPartnerBoth: "... tellib nii otse kui ka kaudselt",
  fleetResponsibleDirect: "Olen autopargi eest vastutav, kes...",
  fleetResponsibleIndirect:
    "... tellib kaudselt (kaubanduse või hulgimüügi kaudu)",
  fleetResponsibleBoth: "... tellib nii otse kui ka kaudselt",
  noBusinessRelationship:
    "<b>Mul ei ole ärisuhteid Bridgestone'iga </b>(ei otseseid ega kaudseid)",
  yourCustomerNumber: "Teie Bridgestone'i kliendinumber*:",
  enterCustomerNumber: "Sisestage oma kliendinumber",
  waitPersonalisedView:
    "Oodake hetk, teie isikupärastatud BRIDGESTONE PLUS vaade luuakse.",
  personalised: "ISIKUPÄRASTATUD",
  plusInfo: "Siit leiate teavet oma Plus-konto ja Plus-postkasti kohta.",
  continue: "Jätka",
  skip: "Jäta vahele",
  oneOfTwo: "1 / 2",
  openApps:
    "Saate avada rakendused, kuhu olete juba registreeritud. Samuti saate taotleda juurdepääsu teistele rakendustele, mis võivad teile asjakohased olla.",
  requiredFields:
    "Palun täitke kohustuslikud väljad: Tiitel, Eesnimi, Perekonnanimi",
  stage: "etapp",
  chooseOne: "(Valige vähemalt 1 lahter)",
  required: "*kohustuslikud väljad",
  lengthCsNumber: "Kliendinumbri pikkus: 6-10 numbrit",
};

export default {
  messages,
};

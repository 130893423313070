const messages = {
  title: "Il mio profilo",
  desc: "Benvenuto nella tua area personale. Qui puoi aggiornare o modificare i tuoi dettagli e visualizzare la tua casella di posta.",
  descStaff:
    "Benvenuto nella tua area personale. Qui puoi aggiornare o modificare i tuoi dettagli.",
  buttons: {
    showProfile: "Mostra profilo",
    userProfile: "Accedi all'anagrafica",
    mailbox: "Accedi alla posta in arrivo.",
  },
  categories: {
    baseData: {
      title: "Dati di base",
      desc: "Compila i tuoi dati personali e aziendali per una corretta registrazione.",
    },
    mailbox: {
      desc: "Ricevi messaggi importanti, conferme d'ordine e novità nella tua casella di posta.",
    },
    bankstatement: {
      title: "Estratto conto bancario",
      desc: "Qui puoi visualizzare lo stato del tuo account personale Bridgestone Plus e controllare i tuoi punti premio accumulati e utilizzati.",
    },
  },
  profile: {
    general: "DATI PERSONALI DATI AZIENDALI",
    title: "Anagrafica",
    description:
      "Benvenuto nella tua area personale. Qui puoi aggiornare o modificare i tuoi dettagli.",
    personalData: "DATI PERSONALI",
    password: "Password",
    password1: "Password (facoltativo)*",
    password2: "Password (ripeti)*",
    formOfAddress: "Titolo",
    choose: "Seleziona il titolo",
    mr: "Sig.",
    mrs: "Sig.ra",
    diverse: "Altro",
    firstname: "Nome",
    surname: "Cognome",
    position: "POSIZIONE AZIENDALE",
    operatingData: "DATI AZIENDALI",
    company: "Azienda",
    legalForm: "Struttura legale",
    name: "Nome",
    streetHouseNr: "Indirizzo aziendale",
    street: "Strada",
    postcode: "CAP",
    location: "Città",
    country: "Paese",
    de: "Germania",
    at: "Austria",
    ch: "Svizzera",
    nl: "Paesi Bassi",
    it: "Italia",
    language: "Lingua",
    french: "Francese",
    italian: "Italiano",
    german: "Tedesco",
    nederland: "Olandese",
    openingHours: "Orari di apertura & contatti",
    phone: "Telefono",
    website: "Sito web",
    operatingIndicators: "Indicatori aziendali",
    tireSale: "Vendita annuale di pneumatici circa in pezzi",
    tireSaleLabel: "Vendita di pneumatici in grandi quantità",
    inches: "Di cui ≥ 18 pollici (in %)",
    inchesLabel: "Di cui 18 pollici o più in %",
    segmentSale:
      "Vendita di pneumatici per segmento (percentuale Budget / Medio / Premium)",
    premBudget: "Premium",
    midBudget: "Medio",
    budBudget: "Budget",
    distribution: "DISTRIBUZIONE ESTATE / INVERNO / ANNUALE (in %)",
    tyreSizes: "Numero di spazi di stoccaggio stagionali (set di pneumatici)",
    diffDeliveryAddres: "Aggiungi un indirizzo di consegna",
    yourEmailAddress: "Il tuo indirizzo email.",
    clientNr: "Numeri cliente",
    clientNrSingular: "Numero cliente",
    clientNrContent:
      "<span class='text-danger fw-bold'>IMPORTANTE - LEGGI ATTENTAMENTE:</span><br /><br /> Per l'attribuzione corretta dei tuoi acquisti di pneumatici presso un grossista e/o Bridgestone, è necessario che <b>indichi i tuoi numeri di cliente rispettivi.</b><br/><br/>Se hai <b>più numeri cliente presso un grossista e/o Bridgestone,</b> ad esempio per il tuo negozio principale e le filiali, indica prima il numero cliente del negozio principale.<br/><br/><b>Le vendite degli altri numeri cliente saranno quindi attribuite al tuo negozio principale.</b>",
    supplySource: "Fonte di approvvigionamento",
    email: "Indirizzo email",
    staffEmail: "Indirizzo email del partecipante",
    registered: "Registrato il",
    registeredAt: "Registrato il",
    lastLogIn: "Ultimo accesso",
    details: "Dettagli",
    inDetail: "{name} in dettaglio",
    bsClientNr: "Il tuo numero cliente Bridgestone",
    bftClientNr: "Il tuo numero cliente Best4Tires",
    acceptTerms: "Accetta i termini e le condizioni di partecipazione.",
    newCustomerNumbersInfo:
      "<span class='text-danger fw-bold'>IMPORTANTE - LEGGI ATTENTAMENTE:</span><br />Al primo inserimento del numero cliente, i tuoi dati verranno verificati per l'accuratezza e per i criteri di distribuzione. La decisione di attivare il(i) numero(i) cliente e di associarlo(i) al tuo profilo potrebbe richiedere fino a 5 giorni lavorativi.",
    selectWholeSaler: "presso il fornitore (seleziona per favore)",
    cooperation: "MEMBRO / COLLABORAZIONE",
    error100: "La somma dei valori deve essere uguale al 100%.",
    errorRange: "Il valore deve essere compreso tra 0 e 100",
    btw_number_label: "il numero di identificazione IVA della tua azienda",
    btw_number: "Numero di registrazione IVA",
    mobile: "Numero di telefono",
    mobile_label: "Il tuo numero di telefono (cellulare)",
    iban_number: "Numero IBAN della TUA AZIENDA",
    iban_number_label: "Numero IBAN",
    yourCsNr: "I tuoi numeri cliente",
    yourLastName: "Il tuo cognome",
    positionLabel: "Seleziona una posizione",
    yourFirstName: "Il tuo nome",
    companyLabel: "Azienda",
    numberOfSetsOfSeasonalTiresBudget: "Stoccaggio annuale",
    contactInfo: "Se hai domande, contattaci per favore:",
    addAnotherCsNumber: "Aggiungi numero cliente Bridgestone",
    activityAndProfile: "RELATORE AZIENDALE E PROFILO",
  },
};

export default {
  messages,
};

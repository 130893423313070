const messages = {
  makeChoice: "Tee valintasi tästä.",
  seeAll: "Näytä kaikki",
  clear: "Poista",
  back: "Takaisin",
  save: "Tallenna ",
  close: "Sulje",
  of: "Of",
  to: "osoitteeseen ",
  copyrights: "© {date} Bridgestone Solutions. Kaikki oikeudet pidätetään.",
  pageNotFound: "Sivua ei löydy",
  accessDenied: "Pääsy evätty",
  search: "Etsi",
  buttons: {
    export: "Export",
    delete: "Poista",
    save: "Tallenna",
    cancel: "Peruuta",
    next: "Jatka",
    back: "Takaisin",
    skip: "Ohita",
    send: "Lähetä",
    readNow: "Lue nyt",
    pointsList: "Pisteiden lista",
    clear: "Poista",
    more: "Lisää",
    close: "Sulje",
  },
  tooltips: {
    info: "Tarvitsetko apua? +358 207 936 200",
  },
  confirmation: {
    header: "Vaadittu vahvistus",
    message: "Haluatko poistaa tämän tietueen?",
  },
  nav: {
    dashboard: "Etusivu",
    account: "Oma Profiili",
    mailbox: "Saapuneet-kansio",
    singleMailbox: "Viestit",
    stammdaten: "Perustiedot",
    news_feeds: "Uutiset",
    pointsOverview: "Points overview",
    about: "Noin BS Plus",
    service_number: "Contact service",
  },
  menu: {
    account: "Oma Profiili",
    plus: 'Noin Bridgestone <span class="text-danger">Plus</span>',
    salesSupport: "Soutien à la vente",
    mailbox: "Saapuneet-kansio",
    about: "Noin BS Plus",
    trainingAndCourses: "Formations et cours",
    externalTraining: "Formations externes",
    productInfo: "Informations sur les produits",
    productBrochures: "Brochures sur les produits",
    priceLists: "Listes de prix",
    price: "Pricat",
    complaintForm: "Formulaire de réclamation",
    sales_support: "Soutien à la vente",
    contact_service: "Yhteystiedot ja palvelu",
    logOut: "Kirjaudu ulos",
    news: "Uutiset",
    news_feeds: "Uutiset",
    service_number: "Contact service",
  },
  validation: {
    requiredField: "Pakollinen kenttä",
  },
};

export default {
  messages,
};

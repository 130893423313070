const messages = {
    title: "Bienvenue sur BRIDGESTONE <span class='text-danger'>PLUS</span>",
    description: "<b>Vous avez déjà un compte?</b><br/>Connectez-vous à l'aide de votre adresse e-mail et de votre mot de passe.<br/><br/><b>Vous êtes nouveau ici?</b><br/>Cliquez ci-dessous pour créer votre compte.<br/><br/><b>Une question?</b><br/>Veuillez nous écrire à plusbelux@bridgestone.eu",
    info: "",
    buttons: {
        action: "S'identifier / S'inscrire",
        back: "Continuer en français",
        changeLanguage: "Taal wijzigen",
        start: "Français"
    },
    languageSelect: {
        label: "Sélection de la langue",
        description: "Veuillez choisir une langue pour continuer",
        start: {
            nlBE: "Nederlands",
            frBE: "Français"
        }
    }
}

export default {
    messages
}
// **Config**
import API from "./config";

// **Library Imports**
import "mdb-vue-ui-kit/css/mdb.min.css";
import { createApp } from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import Vue3Toastify, { toast } from "vue3-toastify";

// **Core Framework Imports**
import App from "./App.vue";
import router from "./router";
import store from "./store";

// **Plugins and Services**
import ApiService from "./services/api.service";
import { MaintenanceService } from "./services/maintanace.service";
import { detectCountryAndLoadConfig } from "./utils/tenantUtils";
import { createI18n } from "vue-i18n";

// **Validation Libraries**
import { defineRule, configure } from "vee-validate";
import AllRules from "@vee-validate/rules";
import { localize, setLocale } from "@vee-validate/i18n";
import de from "@vee-validate/i18n/dist/locale/de.json";
import en from "@vee-validate/i18n/dist/locale/en.json";
import nl from "@vee-validate/i18n/dist/locale/nl.json";
import fr from "@vee-validate/i18n/dist/locale/fr.json";
import it from "@vee-validate/i18n/dist/locale/it.json";
import ch from "./i18n/ch_vee_validate.json";

// **Mixins**
import languageMixin from "@/mixins/languageMixin";
import permissionMixin from "@/mixins/permissionMixin";

// **Styles**
import "@vuepic/vue-datepicker/src/VueDatePicker/style/main.scss";

// **VeeValidate Configuration**
configure({
  generateMessage: localize({
    de,
    nl,
    ch,
    en,
    fr,
    it,
  }),
});
setLocale(API.LANG);

if (AllRules !== undefined) {
  Object.keys(AllRules).forEach((rule) => {
    defineRule(rule, AllRules[rule]);
  });
}

// **Reusable SetupApp Function**
function setupApp(component, i18n, availableLocales) {
  const app = createApp(component)
    .use(VueAxios, axios)
    .use(router)
    .use(store)
    .use(i18n)
    .use(Vue3Toastify, {
      autoClose: 3000,
      position: toast.POSITION.TOP_RIGHT,
      transition: toast.TRANSITIONS.SLIDE,
    })
    .mixin(permissionMixin) // Register the mixin globally
    .mixin(languageMixin) // Register the mixin globally

  // Provide availableLocales globally for language switching
  app.provide("availableLocales", availableLocales);

  app.mount("#app");
}

// **Languages**
import enGB from "@/locales/en-GB";
import enUK from "@/locales/en-UK";
import enIE from "@/locales/en-IE";
import frFR from "@/locales/fr-FR";
import ptPT from "@/locales/pt-PT";
import esES from "@/locales/es-ES";
import frBE from "@/locales/fr-BE";
import nlBE from "@/locales/nl-BE";
import fiFI from "@/locales/fi-FI";
import seSE from "@/locales/sv-SE";
import dkDK from "@/locales/da-DK";
import deAT from "@/locales/de-AT";
import itIT from "@/locales/it-IT";
import roRO from "@/locales/ro-RO";
import ltLT from "@/locales/lt-LT";
import lvLV from "@/locales/lv-LV";
import huHU from "@/locales/hu-HU";
import plPL from "@/locales/pl-PL";
import csCZ from "@/locales/cs-CZ";
import skSK from "@/locales/sk-SK";
import bgBG from "@/locales/bg-BG";
import etEE from "@/locales/et-EE";

// **Dynamic I18n Initialization**
// **Dynamic I18n Initialization**
function initializeI18n(locale, availableLocales) {
  // Check localStorage for a stored locale
  const storedLocale = localStorage.getItem("currentLocale");

  // Validate the stored locale against the available locales
  const activeLocale =
    storedLocale && availableLocales.includes(storedLocale)
      ? storedLocale
      : locale; // Use stored locale if valid, otherwise fallback to the default

  // Update localStorage with the active locale if it's different
  if (storedLocale !== activeLocale) {
    localStorage.setItem("currentLocale", activeLocale);
  }

  store.commit("SET_CURRENT_LOCALE", activeLocale);

  console.log("Active Locale for i18n:", activeLocale);

  return createI18n({
    legacy: false,
    globalInjection: true,
    locale: activeLocale, // Use the validated or default locale
    // fallbackLocale: 'en-GB', // Optional: fallback to English if translations are missing
    messages: {
      "en-GB": enGB.messages,
      "en-UK": enUK.messages,
      "en-IE": enIE.messages,
      "fr-FR": frFR.messages,
      "pt-PT": ptPT.messages,
      "es-ES": esES.messages,
      "fr-BE": frBE.messages,
      "nl-BE": nlBE.messages,
      "da-DK": dkDK.messages,
      "fi-FI": fiFI.messages,
      "de-AT": deAT.messages,
      "sv-SE": seSE.messages,
      "it-IT": itIT.messages,
      "ro-RO": roRO.messages,
      "lv-LV": lvLV.messages,
      "lt-LT": ltLT.messages,
      "hu-HU": huHU.messages,
      "pl-PL": plPL.messages,
      "cs-CZ": csCZ.messages,
      "sk-SK": skSK.messages,
      "bg-BG": bgBG.messages,
      "et-EE": etEE.messages,
      // Add additional locales as needed
    },
  });
}

// **Tenant Detection, Maintenance Check and App Initialization**
detectCountryAndLoadConfig()
  .then(({ countryCode, baseURL, defaultLocale, availableLocales }) => {
    console.log("Tenant:", countryCode);
    console.log("API URL:", baseURL);
    console.log("Default Locale:", defaultLocale);
    console.log("Available Locales:", availableLocales);

    // Initialize API Service
    ApiService.init(baseURL, countryCode, defaultLocale);

    // Initialize i18n dynamically with validation
    const i18n = initializeI18n(defaultLocale, availableLocales);

    return { i18n, availableLocales };
  })
  .then(({ i18n, availableLocales }) => {
    // Perform Maintenance Check
    return MaintenanceService.maintenance().then((response) => ({
      response,
      i18n,
      availableLocales,
    }));
  })
  .then(({ response, i18n, availableLocales }) => {
    const isUnderMaintenance = Object.keys(response.data).length > 0;
    const currentPath = window.location.pathname;

    if (isUnderMaintenance && currentPath !== "/maintenance") {
      window.location.href = "/maintenance";
    } else if (!isUnderMaintenance && currentPath === "/maintenance") {
      window.location.href = "/";
    } else {
      // Check if the user is logged in
      const loggedIn = localStorage.getItem('token')

      if (loggedIn) {
        localStorage.removeItem('tenantPermissions')
        // Fetch permissions if the user is logged in, then setup and mount the app
        store
            .dispatch('fetchTenantPermissions')
            .then(() => {
              setupApp(App, i18n, availableLocales); // Proceed to mount the app even if permissions fail to load
            })
            .catch((error) => {
              console.error('Failed to fetch permissions:', error)
              // Optionally handle errors here, for example, redirect to login
              setupApp(App, i18n, availableLocales); // Proceed to mount the app even if permissions fail to load
            })
      } else {
        // If the user is not logged in, setup and mount the app directly
        setupApp(App, i18n, availableLocales);
      }

    }
  })
  .catch((error) => {
    console.error("Error during app initialization:", error);
    setupApp(App);
  });

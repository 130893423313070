const messages = {
  title: "Bejövő üzenetek",
  desc: "Itt találhat fontos értesítéseket és híreket.",
  newMessages: "Új üzenetek az Ön bejövő mappájában",
  link: "Üzenetek megtekintése",
  showMessages: "Üzenetek megjelenítése",
  message: {
    sender: "Feladó",
    thread: "Téma",
    date: "Dátum",
  },
  sender: {
    system: "BRIDGESTONE PLUS",
  },
  back: "Vissza",
  time: {
    secondsAgo: "{time} másodpercekkel ezelőtt",
    minutesAgo: "{time} perccel ezelőtt",
    hoursAgo: "{time} órával ezelőtt",
    daysAgo: "{time} nappal ezelőtt",
  },
};

export default {
  messages,
};

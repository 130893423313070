const messages = {
  title: "Profilom",
  desc: "Üdvözöljük a személyes területén. Itt frissítheti vagy módosíthatja az <br/>adatait, és megtekintheti a beérkezett üzeneteit.",
  descStaff:
    "Üdvözöljük a személyes területén. Itt frissítheti vagy módosíthatja az adatait.",
  buttons: {
    showProfile: "Profil megtekintése",
    userProfile: "UGRÁS AZ ALAPADATOKHOZ",
    mailbox: "UGRÁS A BEJÖVŐ ÜZENETEKHEZ",
  },
  categories: {
    baseData: {
      title: "Alapadatok",
      desc: "Töltse ki személyes és vállalati adatait a helyes regisztráció érdekében.",
    },
    mailbox: {
      desc: "Fogadjon fontos értesítéseket, megrendelés-visszaigazolásokat és híreket a beérkező üzenetek között.",
    },
    bankstatement: {
      title: "Bankszámlakivonat",
      desc: "Itt megtekintheti személyes Bridgestone Plus számlájának állapotát, valamint a megszerzett és felhasznált jutalompontjait.",
    },
  },
  profile: {
    general: "SZEMÉLYES ADATOK VÁLLALATI ADATOK",
    title: "Alapadatok",
    description:
      "Üdvözöljük a személyes területén. Itt frissítheti vagy módosíthatja az adatait.",
    personalData: "SZEMÉLYES ADATOK",
    password: "Jelszó",
    password1: "Jelszó (opcionális)*",
    password2: "Jelszó (ismétlés)*",
    formOfAddress: "Megszólítás",
    choose: "Válasszon megszólítást",
    mr: "Úr",
    mrs: "Asszony",
    diverse: "Egyéb",
    firstname: "Keresztnév",
    surname: "Vezetéknév",
    position: "POZÍCIÓ A VÁLLALATBAN",
    operatingData: "VÁLLALATI ADATOK",
    company: "Cég",
    legalForm: "Jogi forma",
    name: "Név",
    streetHouseNr: "Cég címe",
    street: "Utca",
    postcode: "Irányítószám",
    location: "Helység",
    country: "Ország",
    de: "Németország",
    at: "Ausztria",
    ch: "Svájc",
    nl: "Hollandia",
    it: "Olaszország",
    language: "Nyelv",
    french: "Francia",
    italian: "Olasz",
    german: "Német",
    nederland: "Holland",
    openingHours: "Nyitvatartás és kapcsolat",
    phone: "Telefon",
    website: "Weboldal",
    operatingIndicators: "Működési mutatók",
    tireSale: "Éves gumiabroncs értékesítés darabszámban",
    tireSaleLabel: "Gumiabroncs értékesítés nagy mennyiségben",
    inches: "Ebből ≥ 18 col (százalékban)",
    inchesLabel: "Ebből 18 col vagy nagyobb (százalékban)",
    segmentSale:
      "Gumiabroncs értékesítés szegmensenként (Budget / Közép / Prémium százalékos arány)",
    premBudget: "Prémium",
    midBudget: "Közép",
    budBudget: "Budget",
    distribution: "NYÁRI / TÉLI / EGÉSZ ÉVES MEGOSZLÁS (százalékban)",
    tyreSizes: "Szezonális tárolóhelyek száma (garnitúrák)",
    diffDeliveryAddres: "Adjon hozzá szállítási címet",
    yourEmailAddress: "Az Ön e-mail címe.",
    clientNr: "Ügyfélszámok",
    clientNrSingular: "Ügyfélszám",
    clientNrContent:
      "<span class='text-danger fw-bold'>FONTOS - KÉRJÜK, OLVASSA EL:</span><br /><br /> Az Ön gumiabroncs-vásárlásainak pontos hozzárendelése érdekében a nagykereskedőknél és/vagy a Bridgestone-nál szükséges, hogy <b>megadja a megfelelő ügyfélszámokat.</b><br/><br/>Ha <b>több ügyfélszáma van a nagykereskedőknél és/vagy a Bridgestone-nál,</b> például a főüzlethez és a fiókokhoz, először a főüzlet ügyfélszámát kell megadnia.<br/><br/><b>A többi ügyfélszám eladása ezután a főüzlethez lesz hozzárendelve.</b>",
    supplySource: "Beszállítási forrás",
    email: "E-mail cím",
    staffEmail: "Résztvevő e-mail címe",
    registered: "Regisztrálva",
    registeredAt: "Regisztrálva",
    lastLogIn: "Utolsó bejelentkezés",
    details: "Részletek",
    inDetail: "{name} részletesen",
    bsClientNr: "Az Ön Bridgestone ügyfélszáma",
    bftClientNr: "Az Ön Best4Tires ügyfélszáma",
    acceptTerms: "Fogadja el a részvételi feltételeket.",
    newCustomerNumbersInfo:
      "<span class='text-danger fw-bold'>FONTOS - KÉRJÜK, OLVASSA EL:</span><br />Az ügyfélszám első megadásakor az adatok ellenőrzésre kerülnek a pontosság és a forgalmazási kritériumaink teljesítése érdekében. Az ügyfélszám(ok) aktiválásáról és profiljához való hozzárendeléséről szóló döntés akár 5 munkanapot is igénybe vehet.",
    selectWholeSaler: "beszállítónál (kérjük, válasszon)",
    cooperation: "TAG / EGYÜTTMŰKÖDÉS",
    error100: "Az értékek összegének 100%-nak kell lennie.",
    errorRange: "Az értéknek 0 és 100 között kell lennie",
    btw_number_label: "az Ön vállalatának adószáma",
    btw_number: "Adószám",
    mobile: "Telefonszám",
    mobile_label: "Az Ön mobiltelefonszáma",
    iban_number: "Az Ön vállalatának IBAN száma",
    iban_number_label: "IBAN szám",
    yourCsNr: "Az Ön ügyfélszámai",
    yourLastName: "Az Ön vezetékneve",
    positionLabel: "Válasszon pozíciót",
    yourFirstName: "Az Ön keresztneve",
    companyLabel: "Cég",
    numberOfSetsOfSeasonalTiresBudget: "Éves tárolás",
    contactInfo: "Ha kérdése van, kérjük, lépjen kapcsolatba velünk:",
    addAnotherCsNumber: "Adjon hozzá Bridgestone ügyfélszámot",
    activityAndProfile: "ÜZLETI KAPCSOLATOK ÉS PROFIL",
  },
};

export default {
  messages,
};

const messages = {
  "title": "Üdvözöljük a BRIDGESTONE <span class='text-danger'>PLUS</span> oldalon",
  "description": "<b>Már van fiókja?</b><br/>Jelentkezzen be e-mail címével és jelszavával.<br/><br/><b>Új itt?</b><br/>Ha új felhasználó, kérjük, regisztráljon.<br/><br/>",
  "info": "",
  "buttons": {
    "action": "Bejelentkezés / Regisztráció",
    "back": "Vissza a bejelentkezéshez",
    "changeLanguage": "Nyelv váltása",
    "start": "Indítás"
  },
  "languageSelect": {
    "label": "Nyelvválasztás",
    "description": "Kérjük, válassza ki a nyelvét "
  }
}

export default {
  messages
}
const messages = {
  title: "Bandeja de entrada",
  desc: "Aquí puedes encontrar notificaciones y noticias importantes.",
  newMessages: "Nuevo mensaje en la bandeja",
  link: "Mostrar los mensajes",
  showMessages: "Mostrar los mensajes",
  message: {
    sender: "Remitente",
    thread: "Asunto",
    date: "Fecha",
  },
  sender: {
    system: "BRIDGESTONE PLUS",
  },
  back: "Atrás",
  time: {
    secondsAgo: "{time} sekundit tagasi",
    minutesAgo: "{time} minutit tagasi",
    hoursAgo: "{time} tundi tagasi",
    daysAgo: "hace {time} días",
  },
};

export default {
  messages,
};

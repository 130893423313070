const messages = {
  makeChoice: "Make your choice",
  seeAll: "See all",
  clear: "Clear",
  back: "Back",
  save: "Save",
  close: "Close",
  of: "Of",
  to: "To",
  copyrights: "© {date} Bridgestone Solutions. All rights reserved.",
  pageNotFound: "Page not found",
  accessDenied: "Access denied",
  search: "Search",
  buttons: {
    export: "Export",
    delete: "Delete",
    save: "Save",
    cancel: "Cancel",
    next: "Next",
    back: "Back",
    skip: "Skip",
    send: "Send",
    clear: "Clear",
    close: "Close",
    readNow: "Read now",
    pointsList: "Points list",
    more: "More",
  },
  tooltips: {
    info: "Need help? 01 92 64 88 50 0",
  },
  confirmation: {
    header: "Confirmation required",
    message: "Do you want to delete this entry?",
  },
  nav: {
    dashboard: "Dashboard",
    account: "Profile",
    mailbox: "Inbox",
    singleMailbox: "Messages",
    stammdaten: "Master Data",
    news_feeds: "News feeds",
    pointsOverview: "Points overview",
    about: "About BS Plus",
    service_number: "Contact service",
  },
  menu: {
    account: "Profile",
    plus: 'About Bridgestone <span class="text-danger">Plus</span>',
    salesSupport: "Sales Support",
    mailbox: "Inbox",
    about: "About BS Plus",
    trainingAndCourses: "Training and Courses",
    externalTraining: "External Training",
    productInfo: "Product Information",
    productBrochures: "Product Brochures",
    priceLists: "Price Lists",
    price: "Price",
    complaintForm: "Complaint Form",
    sales_support: "Sales Support",
    contact_service: "Contact Information and Service",
    logOut: "Log out",
    news: "News",
    news_feeds: "News feeds",
    service_number: "Contact service",
  },
  validation: {
    requiredField: "Field required",
  },
};

export default {
  messages,
};

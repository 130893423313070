const messages = {
  title: "Etusivu",
  welcomeMessage:
    'Tervetuloa BRIDGESTONE <span class="text-danger">PLUS</span>, %{first_name} %{last_name}',
  home: "Etusivu",
  search: "Etsi",
  sortBy: "Lajittelu",
  seeMore: "Katso lisää",
  bankstatement: {
    yourScore: "Nykyinen markkinointipisteesi määrä:",
    lastUpdate: "Viimeisin päivitys:",
    link: "Pistetasapainon yleiskatsaus",
  },
};

export default {
  messages,
};

<template>
  <div id="opening_hours">
    <h4 class="mb-3 text-uppercase">{{ $t("account.profile.openingHours") }}</h4>

    <div v-if="isGermany">
      <MDBRow v-for="(openHour, index) in models.openingHours" :key="index">
        <MDBCol col="12" lg="3">
          <label for="dates1" class="label text-uppercase pb-2">{{
            openHour.day_name
            }}</label>
          <div class="d-flex justify-content-space-between align-items-center">
            <input type="time" id="dates1" class="form-control" placeholder="00:00" aria-label="00:00"
              aria-describedby="basic-addon1" v-model="openHour.start_hour" />
            <div class="divider mx-2"></div>
            <input type="time" class="form-control" placeholder="00:00" aria-label="00:00"
              aria-describedby="basic-addon1" v-model="openHour.end_hour" />
          </div>
        </MDBCol>
        <MDBCol col="12" lg="3">
          <label for="dates1" class="label text-uppercase pb-2">&nbsp;</label>
          <div class="d-flex justify-content-space-between align-items-center">
            <div>
              <input type="checkbox" :name="'is_closed' + index" v-model="openHour.is_closed"
                :rules="{ required: true }" :value="true" :unchecked-value="false" class="form-check-input"
                :label="$t('restDay')" />
              <span class="px-2">{{ $t("restDay") }}</span>
            </div>
            <div>
              <input type="checkbox" :name="'is_break' + index" v-model="openHour.is_break" :rules="{ required: true }"
                :value="true" :unchecked-value="false" class="form-check-input" :label="$t('breakTimes')" />
              <span class="px-2">{{ $t("breakTimes") }}</span>
            </div>
          </div>
        </MDBCol>
        <MDBCol col="12" lg="6">
          <label for="dates1" class="label text-uppercase pb-2">{{
            $t("pause")
            }}</label>
          <div class="d-flex justify-content-space-between w-100 flex-column flex-sm-row">
            <div class="d-flex justify-content-space-between align-items-center">
              <input type="time" id="dates1" class="form-control mb-0" placeholder="00:00" aria-label="00:00"
                aria-describedby="basic-addon1" v-model="openHour.break_from" :disabled="!openHour.is_break" />
              <div class="divider mx-2"></div>
              <input type="time" class="form-control mb-0" placeholder="00:00" aria-label="00:00"
                aria-describedby="basic-addon1" v-model="openHour.break_to" :disabled="!openHour.is_break" />
            </div>

            <div class="pt-1 pt-sm-0">
              <MDBBtn @click="storeOpenTime(openHour)" type="button" color="danger" class="px-2"><span>{{
                $t("global.buttons.send") }}</span>
              </MDBBtn>

              <MDBBtn @click="storeNextDay(index, openHour)" type="button" color="danger" class="px-2"
                v-if="index != models.openingHours.length - 1"><span>{{ $t("buttons.next") }}</span>
              </MDBBtn>
            </div>
          </div>
        </MDBCol>
      </MDBRow>
    </div>

    <MDBRow>
      <MDBCol col="12" md="3">
        <label for="phone" class="label text-uppercase pb-2 px-3">{{
          $t("account.profile.phone")
          }}</label>
        <input type="text" class="form-control" :placeholder="$t('account.profile.phone')"
          :aria-label="$t('account.profile.phone')" aria-describedby="basic-addon1" v-model="models.phone" id="phone" />
      </MDBCol>
      <MDBCol col="12" md="3">
        <label for="website" class="label text-uppercase pb-2 px-3">{{
          $t("account.profile.website")
          }}</label>
        <input type="text" class="form-control" :placeholder="$t('account.profile.website')"
          :aria-label="$t('account.profile.website')" aria-describedby="basic-addon1" v-model="models.website"
          id="website" />
      </MDBCol>
    </MDBRow>
  </div>
</template>

<script setup>
import { watch, reactive, ref, defineExpose, computed } from "vue";
import { useStore } from "vuex";
import { MDBRow, MDBCol, MDBBtn } from "mdb-vue-ui-kit";

import { ProfileService } from "@/services/profile.service";
import i18n from "@/plugins/i18n";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

const store = useStore();

const percentage = ref(0);
const LANG = localStorage.getItem("lang");
const isGermany = computed(() => LANG === "de");

const models = reactive({
  phone: null,
  website: null,
  openingHours: null,
});

const storeOpenTime = async (data) => {
  await ProfileService.updateOpeningHour(data);

  toast(i18n.global.t("toast.openHourSaved"), {
    type: "success",
    theme: "colored",
    transition: toast.TRANSITIONS.SLIDE,
    position: toast.POSITION.BOTTOM_RIGHT,
  });
};

const storeNextDay = async (index, openHour) => {
  const id = models.openingHours[index + 1].id;
  const data = openHour;
  data.id = id;

  await storeOpenTime(data);
};

watch(
  () => ({ ...models }),
  (newVal) => {
    percentage.value =
      (Object.values(newVal).filter((i) => i).length /
        Object.keys(models).length) *
      100;
  }
);

watch(
  () => store.getters.getProfile,
  (newVal) => {
    models.phone = newVal.phone;
    models.website = newVal.website;
    models.openingHours = newVal.openingHours;
  }
);

defineExpose({
  models,
  percentage,
});
</script>

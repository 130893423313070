const messages = {
  title: "Добре дошли, Bridgestone <span class='text-danger'>PLUS</span>",
  termsAndConditions: "Условия и правила",
  accept: "Приемам",
  decline: "Отказвам",
  dataModal:
    "За да персонализираме това предложение спрямо вашите интереси, молим ви да посочите вашите продуктови интереси или бизнес области.",
  scrollToAccept:
    "Превъртете надолу, за да приемете нашите условия и да продължите",
  welcomeMessage: "Добре дошли в Bridgestone Plus",
  tailorOffer:
    "За да персонализираме това предложение спрямо вашите интереси, молим ви да посочите вашите продуктови интереси или бизнес области.",
  selectAreas: "Моля, изберете от следните области",
  multipleAnswers: "(възможни са няколко отговора):",
  personalPreferences: "Лични предпочитания",
  carTyres: "Гуми за автомобили, SUV и лекотоварни превозни средства",
  motorcyclesTyres: "Гуми за мотоциклети",
  truckBusTyres: "Гуми за камиони и автобуси",
  agricultureTyres: "Гуми за селскостопански превозни средства",
  otrTyres: "OTR гуми",
  save: "Запази",
  businessRelationship: "Какво е вашето бизнес отношение с Bridgestone?",
  selectRelationship: "Моля, изберете*: ",
  tradingPartner: "Аз съм търговски партньор, който...",
  tradingPartnerDirect: "... поръчва директно от Bridgestone",
  tradingPartnerIndirect:
    "... поръчва индиректно (чрез търговци на едро и др.)",
  tradingPartnerBoth: "... поръчва както директно, така и индиректно",
  fleetResponsibleDirect: "Аз съм отговорник за автопарк, който...",
  fleetResponsibleIndirect: "... поръчва индиректно (чрез търговци или едро)",
  fleetResponsibleBoth: "... поръчва както директно, така и индиректно",
  noBusinessRelationship:
    "<b>Нямам бизнес отношения с Bridgestone </b>(нито директни, нито индиректни)",
  yourCustomerNumber: "Вашият Bridgestone клиентски номер*: ",
  enterCustomerNumber: "Въведете вашия клиентски номер",
  waitPersonalisedView:
    "Моля, изчакайте момент, вашият персонализиран изглед на BRIDGESTONE PLUS ще бъде генериран.",
  personalised: "ПЕРСОНАЛИЗИРАНО",
  plusInfo:
    "Тук ще намерите информация за вашия Plus акаунт и вашата Plus входяща поща.",
  continue: "Продължи",
  skip: "Пропусни",
  oneOfTwo: "1 от 2",
  openApps:
    "Можете директно да отворите приложенията, за които вече сте регистрирани. Също така можете да заявите достъп до други приложения, които може да са релевантни за вас.",
  requiredFields:
    "Моля, попълнете задължителните полета: Обръщение, Име, Фамилия",
  stage: "стъпка",
  chooseOne: "(Моля, маркирайте поне 1 поле)",
  required: "*задължителни полета",
  lengthCsNumber: "Дължина на клиентския номер: 6-10 цифри",
};

export default {
  messages,
};

const messages = {
  title: "Hem",
  welcomeMessage:
    'Välkommen till BRIDGESTONE <span class="text-danger">PLUS</span>, %{first_name} %{last_name}',
  home: "Hem",
  search: "Sök",
  sortBy: "Sortera efter",
  seeMore: "Se mer om",
  bankstatement: {
    yourScore: "Ditt nuvarande antal marknadsföringspoäng:",
    lastUpdate: "Senaste uppdatering:",
    link: "Översikt över poängbalans",
  },
};

export default {
  messages,
};

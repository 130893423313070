const messages = {
  title: "Skrzynka odbiorcza",
  desc: "Tutaj znajdziesz ważne powiadomienia i wiadomości.",
  newMessages: "Nowe wiadomości w skrzynce odbiorczej",
  link: "Pokaż wiadomości",
  showMessages: "Pokaż wiadomości",
  message: {
    sender: "Nadawca",
    thread: "Podmiot",
    date: "Data",
  },
  sender: {
    system: "BRIDGESTONE PLUS",
  },
  back: "Wróć",
  time: {
    secondsAgo: "{time} sekund temu",
    minutesAgo: "{time} minut temu",
    hoursAgo: "{time} godzin temu",
    daysAgo: "{time} dni temu",
  },
};

export default {
  messages,
};

const messages = {
  seeAll: "Ver todo",
  clear: "Eliminar",
  back: "Atrás",
  save: "Guardar",
  close: "Cerrar",
  of: "De",
  to: "A",
  copyrights: "© {date} Bridgestone. Todos los derechos reservados.",
  pageNotFound: "Página no encontrada",
  accessDenied: "Acceso denegado",
  search: "Buscar",
  buttons: {
    export: "Exportar",
    delete: "Eliminar",
    save: "Guardar",
    cancel: "Cancelar",
    next: "Siguiente",
    back: "Atrás",
    skip: "Saltar",
    send: "Enviar",
    clear: "Claro",
    close: "Cerca",
    readNow: "Lee ahora",
    pointsList: "Lista de puntos",
    more: "Más",
  },
  tooltips: {
    info: "¿Necesitas ayuda? 00 00 00 0",
  },
  confirmation: {
    header: "Confirmación necesaria",
    message: "¿Deseas eliminar esta entrada?",
  },
  nav: {
    dashboard: "Inicio",
    account: "Mi Perfil",
    mailbox: "Bandeja de entrada",
    singleMailbox: "Mensajes",
    stammdaten: "Mis datos",
    news_feeds: "Noticias",
    pointsOverview: "Points overview",
    about: "Todo sobre BRIDGESTONE",
    service_number: "Contactos y Servicios",
  },
  menu: {
    account: "Mi Perfil",
    plus: 'Sobre Bridgestone <span class="text-danger">Plus</span>',
    salesSupport: "Soporte de Ventas",
    mailbox: "Bandeja de entrada",
    about: "Todo sobre BRIDGESTONE",
    trainingAndCourses: "Formación  y Cursos",
    externalTraining: "Formación Externa",
    productInfo: "Información del Producto",
    productBrochures: "Folletos de Productos",
    priceLists: "Listas de Precios",
    price: "Precio",
    complaintForm: "Formulario de Quejas",
    sales_support: "Soporte de Ventas",
    contact_service: "Contacto y Servicio",
    logOut: "Cerrar sesión",
    news: "Noticias",
    news_feeds: "Noticias",
    service_number: "Contactos y Servicios",
  },
  validation: {
    requiredField: "Campo obligatorio",
  },
};

export default {
  messages,
};

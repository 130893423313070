const messages = {
  "title": "Welkom bij BRIDGESTONE <span class='text-danger'>PLUS</span>",
  "description": "<b>Heeft u al een account?</b><br/>Log in met uw e-mailadres en wachtwoord.<br/><br/><b>Bent u nieuw hier?</b><br/>Als u een nieuwe gebruiker bent, registreer u dan.<br/><br/><b>Heeft u een vraag?</b><br/>Neem dan contact met ons op plus-nl@bridgestone.eu",
  "info": "",
  "buttons": {
    "action": "Inloggen",
    "back": "Verder in het Nederlands",
    "changeLanguage": "Changer de langue",
    "start": "Nederlands"
  },
  "languageSelect": {
    "label": "Taalkeuze",
    "description": "Selecteer een taal om verder te gaan",
    start: {
      nlBE: "Nederlands",
      frBE: "Français"
    }
  }
}

export default {
  messages
}
<template>
  <div v-if="!loading">
    <div v-if="canSeeExamples">
      <MDBRow :class="`${user?.commitment_letter_accept && user?.intro == 2 ? 'intro__step' : ''
        }`">
        <MDBCol col="12" class="my-3">
          <h5 class="title">
            <router-link :to="{ name: 'shop' }" class="text-dark text-uppercase"
              v-html="$t('pageName.shop')"></router-link>
          </h5>
          <div class="text-lg-end font-12 fw-bold all-products">
            <router-link :to="{ name: 'shop' }" class="text-uppercase">
              <span v-html="$t('dashboard.showAllProducts')"></span>
              <MDBIcon icon="chevron-right" size="1x" />
            </router-link>
          </div>
        </MDBCol>
      </MDBRow>

      <MDBRow class="mt-2">
        <MDBCol col="12" xl="4" class="mb-3 mb-lg-0" :class="`${user?.commitment_letter_accept && user?.intro == 2
          ? 'intro__step'
          : ''
          }`">
          <ProductSlider :slides="bestSoldProducts" :category="$t('shop.bestSold')"></ProductSlider>
        </MDBCol>

        <MDBCol col="12" xl="4" class="mb-3 mb-lg-0" :class="`${user?.commitment_letter_accept && user?.intro == 2
          ? 'intro__step'
          : ''
          }`">
          <ProductSlider :slides="seasonalProducts" :category="$t('shop.seasonal')"></ProductSlider>
        </MDBCol>

        <MDBCol col="12" xl="4" class="mb-3 mb-lg-0" :class="`${user?.commitment_letter_accept && user?.intro == 2
          ? 'intro__step'
          : ''
          }`">
          <ProductSlider :slides="affordableProducts" :category="$t('shop.affordable')"></ProductSlider>
        </MDBCol>
      </MDBRow>
    </div>
    <div v-else-if="showShopSoonBanners">
      <MDBRow>
        <MDBCol col="12" class="my-3">
          <h5>&nbsp;</h5>
        </MDBCol>

        <MDBCol col="12">
          <img :src="require('@/assets/img/bs_plus_shop_soon.jpg')" />
        </MDBCol>
      </MDBRow>
    </div>
  </div>

  <div v-if="loading">
    <MDBRow>
      <MDBCol col="12" xl="4">
        <Loader :show="loading" />
      </MDBCol>
      <MDBCol col="12" xl="4">
        <Loader :show="loading" />
      </MDBCol>
      <MDBCol col="12" xl="4">
        <Loader :show="loading" />
      </MDBCol>
    </MDBRow>
  </div>
</template>

<script setup>
import { useStore } from "vuex";
import { computed, onMounted, ref, watch } from "vue";
import ProductSlider from "@/components/Dashboard/ProductSlider.vue";
import { MDBIcon, MDBCol, MDBRow } from "mdb-vue-ui-kit";
import TokenService from "@/services/storage.service";
import Loader from "@/components/General/Loader/index";

const store = useStore();

const LANG = localStorage.getItem("lang");
const isNetherland = computed(() => LANG === "nl");
const canSeeExamples = ref(false);
const showShopSoonBanners = ref(false);
const loading = ref(true)

const user = computed(() => store.getters.getMe);

const bestSoldProducts = computed(() => {
  if (store.getters.getBestSoldProducts) {
    return store.getters.getBestSoldProducts.map((item) => {
      return {
        id: item.id,
        imageUrl: item.imageUrl,
        name: item.name,
        points: item.points,
        variants: item.variants,
        type: item.type,
      };
    });
  } else {
    return [];
  }
});

const seasonalProducts = computed(() => {
  if (store.getters.getSeasonalProducts) {
    return store.getters.getSeasonalProducts.map((item) => {
      return {
        id: item.id,
        imageUrl: item.imageUrl,
        name: item.name,
        points: item.points,
        variants: item.variants,
        type: item.type,
      };
    });
  } else {
    return [];
  }
});

const affordableProducts = computed(() => {
  if (store.getters.getSoonAffordableProducts) {
    return store.getters.getSoonAffordableProducts.map((item) => {
      return {
        id: item.id,
        imageUrl: item.imageUrl,
        name: item.name,
        points: item.points,
        variants: item.variants,
        type: item.type,
      };
    });
  } else {
    return [];
  }
});

watch(user, () => {
  checkShopTeaserAccess()
});

const checkShopTeaserAccess = () => {
  canSeeExamples.value = TokenService.showElement("shopTeaser.see");

  if (isNetherland.value) {
    showShopSoonBanners.value = TokenService.showElement("shopComingSoon.see");
  }
}

onMounted(async () => {
  checkShopTeaserAccess()
  if (canSeeExamples.value) {
    await store.dispatch("loadExampleProducts", user.value.id);
  }
  loading.value = false
});
</script>

<style scoped lang="scss">
@import "../../styles/_variables.scss";

.all-products {
  margin: -25px 0 0 0;

  @media screen and (max-width: $lgBreakpoint) {
    margin: 1rem 0 0 0;
  }

  a {
    color: $darkGray;
  }

  i {
    padding-left: 5px;
  }
}

.title {
  &::before {
    content: "";
    display: inline-block;
    width: 27px;
    height: 4px;
    background: #ed1c24;
    transform: rotate(110deg);
    margin: 8px 0;
  }
}
</style>

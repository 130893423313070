const messages = {
  title: "Postkast",
  desc: "Siit leiate olulisi teateid ja uudiseid.",
  newMessages: "Uued sõnumid teie postkastis",
  link: "Kuva sõnumid",
  showMessages: "Kuva sõnumid",
  message: {
    sender: "Saatja",
    thread: "Teema",
    date: "Kuupäev",
  },
  sender: {
    system: "BRIDGESTONE PLUS",
  },
  back: "Tagasi",
  time: {
    secondsAgo: "{time} sekundit tagasi",
    minutesAgo: "{time} minutit tagasi",
    hoursAgo: "{time} tundi tagasi",
    daysAgo: "{time} päeva tagasi",
  },
};

export default {
  messages,
};

const messages = {
  makeChoice: "Tehke oma valik siin.",
  seeAll: "Vaata kõiki",
  clear: "Puhasta",
  back: "Tagasi",
  save: "Salvesta",
  close: "Sulge",
  of: "Kuni",
  to: "jaoks",
  copyrights: "© {date} Bridgestone Solutions. Kõik õigused kaitstud.",
  pageNotFound: "Lehte ei leitud",
  accessDenied: "Juurdepääs keelatud",
  search: "Otsi",
  buttons: {
    export: "Ekspordi",
    delete: "Kustuta",
    save: "Salvesta",
    cancel: "Tühista",
    next: "Järgmine",
    back: "Tagasi",
    skip: "Jäta vahele",
    send: "Saada",
    readNow: "Lugege kohe",
    pointsList: "Punktide loend",
    clear: "Puhasta",
    more: "Rohkem",
    close: "Sulge",
  },
  tooltips: {
    info: "Kas vajate abi? +371 29 16 22 12",
  },
  confirmation: {
    header: "Kinnitus vajalik",
    message: "Kas soovite selle kirje kustutada?",
  },
  nav: {
    dashboard: "Avaleht",
    account: "Minu Profiil",
    mailbox: "Postkast",
    singleMailbox: "Sõnumid",
    stammdaten: "Põhiandmed",
    news_feeds: "Uudised",
    pointsOverview: "Points overview",
    about: "BS Plusi kohta",
    service_number: "Contact service",
  },
  menu: {
    account: "Minu Profiil",
    plus: 'Bridgestone <span class="text-danger">Plus</span> kohta',
    salesSupport: "Müügitoetus",
    mailbox: "Postkast",
    about: "BS Plusi kohta",
    trainingAndCourses: "Koolitused ja kursused",
    externalTraining: "Väline koolitus",
    productInfo: "Tooteinfo",
    productBrochures: "Tootebrošüürid",
    priceLists: "Hinnakirjad",
    price: "Hind",
    complaintForm: "Kaebuse vorm",
    sales_support: "Müügitoetus",
    contact_service: "Kontakt ja teenindus",
    logOut: "Logi välja",
    news: "Uudised",
    news_feeds: "Uudised",
    service_number: "Contact service",
  },
  validation: {
    requiredField: "Kohustuslik väli",
  },
};

export default {
  messages,
};

const messages = {
  "title": "Posta in arrivo",
  "desc": "Qui potete trovare importanti notifiche e novità.",
  "newMessages": "Nuovi messaggi nella posta in arrivo",
  "link": "Mostra messaggi",
  "showMessages": "Mostra messaggi",
  "message": {
    "sender": "Mittente",
    "thread": "Oggetto",
    "date": "Data"
  },
  "sender": {
    "system": "BRIDGESTONE PLUS"
  },
  "back": "Indietro",
  "time": {
    secondsAgo: "{time} secondi fa",
    minutesAgo: "{time} minuti fa",
    hoursAgo: "{time} ore fa",
    daysAgo: "{time} giorni fa"
  }
};

export default {
  messages,
};

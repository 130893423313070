const messages = {
  "title": "Sveiki atvykę į BRIDGESTONE <span class='text-danger'>PLUS</span>",
  "description": "<b>Jau turite paskyrą?</b><br/>Prisijunkite naudodami savo el. pašto adresą ir slaptažodį.<br/><br/><b>Esate naujas čia?</b><br/>Jei esate naujas vartotojas, prašome registruotis.<br/><br/>",
  "info": "",
  "buttons": {
    "action": "Prisijungti / Registruotis",
    "back": "Grįžti į prisijungimą",
    "changeLanguage": "Keisti kalbą",
    "start": "Pradėti"
  },
  "languageSelect": {
    "label": "Kalbos pasirinkimas",
    "description": "Prašome pasirinkti savo kalbą"
  }
}

export default {
  messages
}
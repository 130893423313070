<template>
    <div class="px-5 py-5 bg-gray">
        <h1 v-html="$t('onboarding.title')"></h1>
        <div class="pb-2">{{ $t('onboarding.dataModal') }}</div>
        <h5 class="w-100 text-end pb-2 text-uppercase">{{ $t('onboarding.stage') }} 1/3</h5>
        <VeeForm class="form user-form" @submit="onSubmit">
            <MDBCard>
                <MDBCardBody>
                    <MDBRow>
                        <MDBCol xl="4" md="6" class="mb-4">
                            <PersonalData ref="personalData" :showEmailField="false"
                                :requiredSection="personalDataRequired" :showDots="personalDataRequired">
                            </PersonalData>

                            <div v-if="errorMessage" class="text-danger">{{ errorMessage }}</div>
                        </MDBCol>

                        <MDBCol xl="5" md="6" class="mb-4">
                            <OperatingData ref="operatingData" @diffAddress="diffAddress"
                                :requiredCompany="personalDataRequired">
                            </OperatingData>
                        </MDBCol>

                        <MDBCol xl="3" md="6" class="mb-4">
                            <div>
                                <label for="legalForm" class="label px-3 text-uppercase pt-5">
                                    {{ $t("account.profile.legalForm") }}
                                </label>
                                <input type="text" class="form-control" :placeholder="$t('account.profile.legalForm')"
                                    :aria-label="$t('account.profile.legalForm')" aria-describedby="basic-addon1"
                                    v-model="legalForm" id="legalForm" />
                            </div>
                        </MDBCol>
                    </MDBRow>
                </MDBCardBody>
            </MDBCard>


            <div class="w-100 d-flex justify-content-between mt-5 mb-1">
                <div v-if="personalDataRequired">{{ $t('onboarding.required') }}</div>
                <MDBBtn type="submit" color="danger" class="d-flex justify-content-center align-items-center"
                    :disabled="loading">
                    <span>{{ $t('global.buttons.save') }}</span>
                </MDBBtn>
            </div>
        </VeeForm>
    </div>
</template>

<script setup>
import { Form as VeeForm } from "vee-validate";
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { MDBCardBody, MDBCard, MDBBtn, MDBRow, MDBCol } from "mdb-vue-ui-kit";
import PersonalData from "@/components/User/ProfileForms/PersonalData.vue";
import OperatingData from "@/components/User/ProfileForms/OperatingData.vue";
import { ProfileService } from "@/services/profile.service";
import i18n from "@/plugins/i18n";

const storedLocale = localStorage.getItem("currentLocale");
const store = useStore();
const loading = computed(() => store.getters.getPending);
const me = computed(() => store.getters.getMe);
const personalData = ref(null);
const operatingData = ref(null);

const personalDataRequired = computed(() => {
    const notAvailableCodes = store.getters.getNoRequiredPersonalData
    const currentCountry = storedLocale.split('-')

    return !notAvailableCodes.includes(currentCountry[1])
})

const legalForm = ref(null);
const diffDeliveryAddress = ref(false);
const diffAddress = (bool) => {
    diffDeliveryAddress.value = bool;
};

const errorMessage = ref(null)

const onSubmit = async () => {
    errorMessage.value = personalDataRequired.value && (!personalData.value.models.forename || !personalData.value.models.name || !personalData.value.models.salutation)

    if (!errorMessage.value) {
        const { vat, email, username, ...personal } = personalData.value.models;
        const { ...operating } = operatingData.value.models;

        let args = {
            id: me.value.id,
            user: {
                username,
                email,
                country: operating.country,
                vat,
                is_active: true,
            },
            profile: {
                legalForm: legalForm.value,
                ...personal,
                ...operating,
                user_id: me.value.id,
            },
            addresses: null
        };
        const data = {
            id: me.value.id,
            intro: 2,
        };
        await ProfileService.updateInitialData(args);
        await ProfileService.updateUserLiteIntro(data);
        await store.dispatch("me");
        store.dispatch('displayInitialSurvey', 2)
    } else {
        errorMessage.value = i18n.global.t('onboarding.requiredFields')
    }
}
</script>
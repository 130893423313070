const messages = {
  title: "Входяща поща",
  desc: "Тук можете да намерите важни съобщения и новини.",
  newMessages: "Нови съобщения във вашата входяща поща",
  link: "Показване на съобщения",
  showMessages: "Показване на съобщения",
  message: {
    sender: "Изпращач",
    thread: "Тема",
    date: "Дата",
  },
  sender: {
    system: "BRIDGESTONE PLUS",
  },
  back: "Назад",
  time: {
    secondsAgo: "{time} секунди преди",
    minutesAgo: "{time} минути преди",
    hoursAgo: "{time} часа преди",
    daysAgo: "{time} дни преди",
  },
};

export default {
  messages,
};

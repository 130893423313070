const messages = {
  title: "Acasă",
  welcomeMessage:
    'Bine ați venit la  BRIDGESTONE <span class="text-danger">PLUS</span>, %{first_name} %{last_name}',
  home: "Acasă",
  search: "Căutare",
  sortBy: "Sortare după",
  seeMore: "Vedeți mai multe",
  bankstatement: {
    yourScore: "Numărul dvs. actual de puncte de marketing:",
    lastUpdate: "Ultima actualizare:",
    link: "Prezentare generală a echilibrului de puncte",
  },
};

export default {
  messages,
};

const messages = {
  makeChoice: "Izdari savu izvēli šeit.",
  seeAll: "Skatīt visu",
  clear: "Notīrīt",
  back: "Atpakaļ",
  save: "Saglabāt",
  close: "Aizvērt",
  of: "No",
  to: "līdz",
  copyrights: "© {date} Bridgestone Solutions. Visas tiesības aizsargātas.",
  pageNotFound: "Lapa nav atrasta",
  accessDenied: "Piekļuve liegta",
  search: "Meklēt",
  buttons: {
    export: "Eksportēt",
    delete: "Dzēst",
    save: "Saglabāt",
    cancel: "Atcelt",
    next: "Nākamais",
    back: "Atpakaļ",
    skip: "Izlaist",
    send: "Nosūtīt",
    readNow: "Skaityk dabar",
    pointsList: "Punktu saraksts",
    clear: "Notīrīt",
    more: " Vairāk",
    close: "Aizvērt",
  },
  tooltips: {
    info: "Vai nepieciešama palīdzība? +371 29 16 22 12",
  },
  confirmation: {
    header: "Nepieciešams apstiprinājums",
    message: "Vai tiešām vēlaties dzēst šo ierakstu?",
  },
  nav: {
    dashboard: "Mājas",
    account: "Mans Profils",
    mailbox: "Iesūtne",
    singleMailbox: "Ziņojumi",
    stammdaten: "Pamatdati",
    news_feeds: "Jaunumi",
    pointsOverview: "Points overview",
    about: "Par BS Plus",
    service_number: "Contact service",
  },
  menu: {
    account: "Mans Profils",
    plus: 'Par Bridgestone <span class="text-danger">Plus</span>',
    salesSupport: "Pārdošanas atbalsts",
    mailbox: "Iesūtne",
    about: "Par BS Plus",
    trainingAndCourses: "Apmācības un kursi",
    externalTraining: "Ārējā apmācība",
    productInfo: "Produkta informācija",
    productBrochures: "Produktu brošūras",
    priceLists: "Cenu saraksti",
    price: "Cena",
    complaintForm: "Sūdzības forma",
    sales_support: "Pārdošanas atbalsts",
    contact_service: "Kontakti un serviss",
    logOut: "Izrakstīties",
    news: "Jaunumi",
    news_feeds: "Jaunumi",
    service_number: "Contact service",
  },
  validation: {
    requiredField: "Obligāts lauks",
  },
};

export default {
  messages,
};

const messages = {
  title: "Моят профил",
  desc: "Добре дошли във вашата лична зона. Тук можете да актуализирате или коригирате вашите <br/>данни и да прегледате входящата си поща.",
  descStaff:
    "Добре дошли във вашата лична зона. Тук можете да актуализирате или коригирате вашите данни.",
  buttons: {
    showProfile: "Покажи профил",
    userProfile: "КЪМ ОСНОВНИ ДАННИ",
    mailbox: "КЪМ ВХОДЯЩА ПОЩА",
  },
  categories: {
    baseData: {
      title: "Основни данни",
      desc: "Попълнете вашите лични данни и фирмени сведения за коректна регистрация.",
    },
    mailbox: {
      desc: "Получавайте важни съобщения, потвърждения на поръчки и новини във вашата входяща поща.",
    },
    bankstatement: {
      title: "Извлечение по сметка",
      desc: "Тук можете да видите състоянието на вашата лична Bridgestone Plus сметка и да следите натрупаните и използваните наградни точки.",
    },
  },
  profile: {
    general: "Лична и фирмена информация ФИРМЕНИ ДАННИ",
    title: "Основни данни",
    description:
      "Добре дошли във вашата лична зона. Тук можете да актуализирате или коригирате вашите данни.",
    personalData: "ЛИЧНИ ДАННИ",
    password: "Парола",
    password1: "Парола (по избор)*",
    password2: "Парола (повторете)*",
    formOfAddress: "Обръщение",
    choose: "Изберете обръщение",
    mr: "Г-н",
    mrs: "Г-жа",
    diverse: "Друго",
    firstname: "Име",
    surname: "Фамилия",
    position: "ПОЗИЦИЯ В КОМПАНИЯТА",
    operatingData: "ФИРМЕНИ ДАННИ",
    company: "Компания",
    legalForm: "Правна форма",
    name: "Наименование",
    streetHouseNr: "Адрес на компанията",
    street: "Улица",
    postcode: "Пощенски код",
    location: "Град",
    country: "Държава",
    de: "Германия",
    at: "Австрия",
    ch: "Швейцария",
    nl: "Нидерландия",
    it: "Италия",
    language: "Език",
    french: "Френски",
    italian: "Италиански",
    german: "Немски",
    nederland: "Нидерландски",
    openingHours: "Работно време и контакт",
    phone: "Телефон",
    website: "Уебсайт",
    operatingIndicators: "Показатели за работа",
    tireSale: "Годишни продажби на гуми (брой)",
    tireSaleLabel: "Продажба на гуми в големи количества",
    inches: "От тях ≥ 18 инча (в %)",
    inchesLabel: "От тях 18 инча или повече в %",
    segmentSale:
      "Продажба на гуми по сегмент (дял Икономични / Средни / Премиум в %)",
    premBudget: "Премиум",
    midBudget: "Среден",
    budBudget: "Икономичен",
    distribution: "РАЗПРЕДЕЛЕНИЕ ЛЯТО / ЗИМА / ЦЕЛОГОДИШНИ (в %)",
    tyreSizes: "Брой места за сезонно съхранение (комплекти гуми)",
    diffDeliveryAddres: "Добавете адрес за доставка",
    yourEmailAddress: "Вашият имейл адрес.",
    clientNr: "Клиентски номера",
    clientNrSingular: "Клиентски номер",
    clientNrContent:
      "<span class='text-danger fw-bold'>ВАЖНО - МОЛЯ ПРОЧЕТЕТЕ:</span><br /><br /> За коректно приписване на покупките ви от гуми при търговец на едро и/или Bridgestone е необходимо да <b>посочите вашите съответни клиентски номера.</b><br/><br/>Ако имате <b>повече клиентски номера при търговец на едро и/или Bridgestone,</b> например за основния ви магазин и филиалите, първо въведете номера на основния магазин.<br/><br/><b>Продажбите от останалите клиентски номера ще бъдат приписани на основния магазин.</b>",
    supplySource: "Източник на доставки",
    email: "Имейл адрес",
    staffEmail: "Имейл адрес на служителя",
    registered: "Регистриран на",
    registeredAt: "Регистриран на",
    lastLogIn: "Последно влизане",
    details: "Детайли",
    inDetail: "{name} в детайли",
    bsClientNr: "Вашият Bridgestone клиентски номер",
    bftClientNr: "Вашият Best4Tires клиентски номер",
    acceptTerms: "Приемам условията за участие.",
    newCustomerNumbersInfo:
      "<span class='text-danger fw-bold'>ВАЖНО - МОЛЯ ПРОЧЕТЕТЕ:</span><br />При първоначално въвеждане на клиентски номер вашите данни ще бъдат проверени за точност и съответствие с нашите критерии за разпространение. Решението за активиране на клиентския номер(а) и тяхното свързване с вашия профил може да отнеме до 5 работни дни.",
    selectWholeSaler: "при доставчика (моля, изберете)",
    cooperation: "ЧЛЕНСТВО / СЪТРУДНИЧЕСТВО",
    error100: "Сумата на стойностите трябва да е равна на 100%.",
    errorRange: "Стойността трябва да бъде между 0 и 100",
    btw_number_label: "Вашият фирмен ДДС номер",
    btw_number: "ДДС номер",
    mobile: "Телефонен номер",
    mobile_label: "Вашият мобилен номер",
    iban_number: "IBAN номер на вашата фирма",
    iban_number_label: "IBAN номер",
    yourCsNr: "Вашите клиентски номера",
    yourLastName: "Вашата фамилия",
    positionLabel: "Изберете позиция",
    yourFirstName: "Вашето име",
    companyLabel: "Компания",
    numberOfSetsOfSeasonalTiresBudget: "Годишно съхранение",
    contactInfo: "Ако имате въпроси, моля свържете се с нас:",
    addAnotherCsNumber: "Добавете Bridgestone клиентски номер",
    activityAndProfile: "ТЪРГОВСКА ДЕЙНОСТ И ПРОФИЛ",
  },
};

export default {
  messages,
};

const messages = {
  title: "Mi estado de cuenta personal",
  desc: "Aquí puedes ver tu estado de cuenta personal de Bridgestone Plus con tus puntos de bonificación ganados y utilizados. Ten en cuenta que puede tardar hasta 6 semanas en acreditarse los puntos de tus pedidos de neumáticos, dependiendo de la fuente.",
  score: "Saldo de puntos",
  regularPoints: "Puntos regulares",
  boosterPoints: "Puntos de bonificación",
  date: "Fecha",
  supplySourceReason: "Fuente/Razón de compra",
  article: "Artículo",
  brand: "Marca",
  product: "Producto",
  type: "Tipo",
  inch: "Pulgada",
  season: "Temporada",
  amount: "Cantidad",
  standardPoints: "Puntos",
  boosterProduct: "Producto de bonificación",
  boosterSeason: "Temporada de bonificación",
  otherActions: "Otras acciones o razones",
  totalPoints: "Total de puntos",
  income: "Ingresos",
  spending: "Gastos",
  lastMonth: "Saldo final",
  loadMore: "Cargar 20 transacciones más",
  typeFilter: "Filtrar por fuente",
  dateFilter: "Filtrar por período",
  customer_number: "Número de cliente",
  currentPoints: "Puntos actuales",
  calculationDate: "Fecha de cálculo",
  positivePoints: "Puntos positivos",
  negativePoints: "Ya gastados",
  tyresAmount: "Número de neumáticos por año",
  pointsTotal: "Total de puntos",
  currentPointState: "Estado actual de puntos",
  currentKickbackState: "Cashback actual",
};
export default {
  messages,
};

const messages = {
  "title": "Laipni lūdzam BRIDGESTONE <span class='text-danger'>PLUS</span>",
  "description": "<b>Vai jums jau ir konts?</b><br/>Piesakieties ar savu e-pasta adresi un paroli.<br/><br/><b>Vai esat šeit jauns?</b><br/>Ja esat jauns lietotājs, lūdzu, reģistrējieties.<br/><br/>",
  "info": "",
  "buttons": {
    "action": "Pieteikties / Reģistrēties",
    "back": "Atpakaļ uz pieteikšanos",
    "changeLanguage": "Mainīt valodu",
    "start": "Sākt"
  },
  "languageSelect": {
    "label": "Valodas izvēle",
    "description": "Lūdzu, izvēlieties savu valodu"
  }
}

export default {
  messages
}
const messages = {
  "title": "Tere tulemast BRIDGESTONE <span class='text-danger'>PLUS</span>",
  "description": "<b>Kas teil on juba konto?</b><br/>Logige sisse oma e-posti aadressi ja parooliga.<br/><br/><b>Olete siin uus?</b><br/>Kui olete uus kasutaja, registreeruge palun.<br/><br/>",
  "info": "",
  "buttons": {
    "action": "Logi sisse / Registreeru",
    "back": "Tagasi sisselogimise juurde",
    "changeLanguage": "Muuda keelt",
    "start": "Alusta"
  },
  "languageSelect": {
    "label": "Keele valik",
    "description": "Palun valige oma keel"
  }
}

export default {
  messages
}
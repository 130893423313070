const messages = {
  title: "Oma profiilini",
  desc: "Tervetuloa henkilökohtaiseen alueeseesi. Täällä voit päivittää tai muokata <br/>tietojasi ja tarkastella postilaatikkoasi.",
  descStaff:
    "Tervetuloa henkilökohtaiseen alueeseesi. Täällä voit päivittää tai muokata tietojasi.",
  buttons: {
    showProfile: "Näytä profiili",
    userProfile: "SIIRRY PERUSTIETOIHIN",
    mailbox: "SIIRRY POSTILAATIKKOON",
  },
  categories: {
    baseData: {
      title: "Perustiedot",
      desc: "Täytä henkilö- ja yritystietosi oikeaa rekisteröintiä varten.",
    },
    mailbox: {
      desc: "Vastaanota tärkeitä viestejä, tilausvahvistuksia ja uutisia postilaatikkoosi.",
    },
    bankstatement: {
      title: "Tilin yhteenveto",
      desc: "Täältä voit tarkastella henkilökohtaisen Bridgestone Plus -tilisi tilaa sekä kertyneitä ja käytettyjä palkintopisteitäsi.",
    },
  },
  profile: {
    general: "HENKILÖTIEDOT YRITYSTIEDOT",
    title: "Perustiedot",
    description:
      "Tervetuloa henkilökohtaiseen alueeseesi. Täällä voit päivittää tai muokata tietojasi.",
    personalData: "HENKILÖTIEDOT",
    password: "Salasana",
    password1: "Salasana (valinnainen)*",
    password2: "Salasana (uudelleen)*",
    formOfAddress: "Osoitteen aloitus",
    choose: "Valitse otsikko",
    mr: "Herra",
    mrs: "Rouva",
    diverse: "Muu",
    firstname: "Etunimi",
    surname: "Sukunimi",
    position: "ASEMA YRITYKSESSÄ",
    operatingData: "YRITYSTIEDOT",
    company: "Yritys",
    legalForm: "Oikeudellinen muoto",
    name: "Nimi",
    streetHouseNr: "Yrityksen osoite",
    street: "Katu",
    postcode: "Postinumero",
    location: "Kaupunki",
    country: "Maa",
    de: "Saksa",
    at: "Itävalta",
    ch: "Sveitsi",
    nl: "Alankomaat",
    it: "Italia",
    language: "Kieli",
    french: "Ranska",
    italian: "Italia",
    german: "Saksa",
    nederland: "Hollanti",
    openingHours: "Aukioloajat & yhteystiedot",
    phone: "Puhelin",
    website: "Verkkosivusto",
    operatingIndicators: "Toimintatunnusluvut",
    tireSale: "Vuosittainen renkaiden myynti, noin kappaleina",
    tireSaleLabel: "Renkaiden myynti suurina määrinä",
    inches: "Näistä ≥ 18 tuumaa (%)",
    inchesLabel: "Näistä 18 tuumaa tai enemmän (%)",
    segmentSale:
      "Renkaiden myynti segmentin mukaan (osuus Budjetti / Keskitaso / Premium %)",
    premBudget: "Premium",
    midBudget: "Keskitaso",
    budBudget: "Budjetti",
    distribution: "KESÄ / TALVI / YMPÄRIVUOTISET JAKAUMA (%)",
    tyreSizes: "Kausisäilytyspaikkojen määrä (renkaita)",
    diffDeliveryAddres: "Lisää toimitusosoite",
    yourEmailAddress: "Sähköpostiosoitteesi.",
    clientNr: "Asiakasnumerot",
    clientNrSingular: "Asiakasnumero",
    clientNrContent:
      "<span class='text-danger fw-bold'>TÄRKEÄÄ - LUE:</span><br /><br /> Jotta renkaiden ostot tukkurilta ja/tai Bridgestonelta voidaan kohdistaa oikein, sinun on <b>annettava vastaavat asiakasnumerosi.</b><br/><br/>Jos sinulla on <b>useita asiakasnumeroita tukkurilta ja/tai Bridgestonelta,</b> esimerkiksi pääliikkeelle ja sivutoimipisteille, anna ensin pääliikkeen asiakasnumero.<br/><br/><b>Muiden asiakasnumeroiden myynnit kohdistetaan sen jälkeen pääliikkeeseesi.</b>",
    supplySource: "Hankintalähde",
    email: "Sähköpostiosoite",
    staffEmail: "Osallistujan sähköpostiosoite",
    registered: "Rekisteröitynyt",
    registeredAt: "Rekisteröitynyt",
    lastLogIn: "Viimeinen kirjautuminen",
    details: "Tiedot",
    inDetail: "{name} yksityiskohtaisesti",
    bsClientNr: "Bridgestone-asiakasnumerosi",
    bftClientNr: "Best4Tires-asiakasnumerosi",
    acceptTerms: "Hyväksy osallistumisehdot.",
    newCustomerNumbersInfo:
      "<span class='text-danger fw-bold'>TÄRKEÄÄ - LUE:</span><br />Kun syötät asiakasnumeron ensimmäistä kertaa, tietosi tarkistetaan niiden tarkkuuden ja jakelukriteerien täyttämisen suhteen. Päätös asiakasnumeroiden aktivoimisesta ja niiden liittämisestä profiiliisi voi kestää jopa 5 arkipäivää.",
    selectWholeSaler: "toimittajalta (valitse)",
    cooperation: "JÄSEN / YHTEISTYÖ",
    error100: "Arvojen summan on oltava 100%.",
    errorRange: "Arvon on oltava välillä 0–100",
    btw_number_label: "yrityksesi ALV-tunniste",
    btw_number: "ALV-numero",
    mobile: "Puhelinnumero",
    mobile_label: "Matkapuhelinnumerosi",
    iban_number: "Yrityksesi IBAN-numero",
    iban_number_label: "IBAN-numero",
    yourCsNr: "Asiakasnumerosi",
    yourLastName: "Sukunimesi",
    positionLabel: "Valitse asema",
    yourFirstName: "Etunimesi",
    companyLabel: "Yritys",
    numberOfSetsOfSeasonalTiresBudget: "Vuosittainen säilytys",
    contactInfo: "Jos sinulla on kysyttävää, ota meihin yhteyttä:",
    addAnotherCsNumber: "Lisää Bridgestone-asiakasnumero",
    activityAndProfile: "TOIMINTA JA PROFIILI",
  },
};

export default {
  messages,
};

const messages = {
  title: "Caixa de entrada ",
  desc: "Encontre aqui notificações e notícias importantes.",
  newMessages: "Tem novas mensagens na sua caixa de entrada",
  link: "MOSTRAR MENSAGENS",
  showMessages: "MOSTRAR MENSAGENS",
  message: {
    sender: "Remetente",
    thread: "Assunto",
    date: "Data",
  },
  sender: {
    system: "BRIDGESTONE PLUS",
  },
  back: "Voltar atrás",
  time: {
    secondsAgo: "{time} segundos atrás",
    minutesAgo: "{time} minutos atrás",
    hoursAgo: "{time} horas atrás",
    daysAgo: "{time} dias atrás",
  },
};

export default {
  messages,
};

const messages = {
  title: "Inbox",
  desc: "Here you will find important notifications and news.",
  newMessages: "New messages in the inbox",
  link: "Show messages",
  showMessages: "Show messages",
  message: {
    sender: "Sender",
    thread: "Subject",
    date: "Date",
  },
  sender: {
    system: "BRIDGESTONE PLUS",
  },
  back: "Back",
  time: {
    secondsAgo: "{time} seconds ago",
    minutesAgo: "{time} minutes ago",
    hoursAgo: "{time} hours ago",
    daysAgo: "{time} days ago",
  },
};
export default {
  messages,
};

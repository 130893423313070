const messages = {
  title: "Hjem",
  welcomeMessage:
    'Velkommen til BRIDGESTONE <span class="text-danger">PLUS</span>, %{first_name} %{last_name}',
  home: "Hjem",
  search: "Søg efter",
  sortBy: "Sorter efter",
  seeMore: "Se mere",
  bankstatement: {
    yourScore: "Dit nuværende antal marketingpoint:",
    lastUpdate: "Seneste opdatering:",
    link: "Point balance oversigt",
  },
};
export default {
  messages,
};

const messages = {
  title: "Início",
  welcomeMessage:
    'Bem-vindo ao BRIDGESTONE <span class="text-danger">PLUS</span>, %{first_name} %{last_name}',
  home: "Início",
  search: "Pesquisar",
  sortBy: "Filtrar por",
  seeMore: "Ver mais",
  bankstatement: {
    yourScore: "O seu número atual de pontos de marketing:",
    lastUpdate: "Última atualização:",
    link: "Visão geral do saldo de pontos",
  },
};

export default {
  messages,
};

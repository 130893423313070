const messages = {
  title: "Meu extrato de conta pessoal",
  desc: "Aqui você pode ver seu extrato de conta pessoal Bridgestone Plus com seus pontos de recompensa recebidos e utilizados. Observe que os créditos de pontos para seus pedidos de pneus, dependendo da fonte, podem levar até 6 semanas após o pedido para serem creditados.",
  score: "Saldo de pontos",
  regularPoints: "Pontos regulares",
  boosterPoints: "Pontos bônus",
  date: "Data",
  supplySourceReason: "Fonte/Razão da compra",
  article: "Artigo",
  brand: "Marca",
  product: "Produto",
  type: "Tipo",
  inch: "Polegadas",
  season: "Estação",
  amount: "Quantidade",
  standardPoints: "Pontos",
  boosterProduct: "Produto bônus",
  boosterSeason: "Estação bônus",
  otherActions: "Outras ações ou causas",
  totalPoints: "Pontos totais",
  income: "Entradas",
  spending: "Saídas",
  lastMonth: "Saldo final",
  loadMore: "Carregar mais 20 transações",
  typeFilter: "Filtrar por fonte",
  dateFilter: "Filtrar por período de data",
  customer_number: "Nº do cliente",
  currentPoints: "Pontos atuais",
  calculationDate: "Data de cálculo",
  positivePoints: "Pontos positivos",
  negativePoints: "Já gastos",
  tyresAmount: "Quantidade de pneus por ano",
  pointsTotal: "Total de pontos",
  currentPointState: "Saldo de pontos atual",
  currentKickbackState: "Kickback atual", // Tenha cuidado com este termo, pode precisar de adaptação
  seasons: {
    summer: "Summer",
    winter: "Winter",
    all: "All",
  },
};
export default {
  messages,
};

import ApiService from "./api.service";

const ProductService = {
  async categories() {
    try {
      return await ApiService.get("user/categories");
    } catch (e) {
      console.log(e);
    }
  },

  async categoryProducts(id, page, sort, perPage = 9) {
    let pageUrl = "";

    if (page) {
      pageUrl = `?page=${page}&sort=${sort}&perPage=${perPage}`;
    } else {
      pageUrl = `?sort=${sort}&perPage=${perPage}`;
    }

    try {
      return await ApiService.get(`categories/${id}/products${pageUrl}`);
    } catch (e) {
      console.log(e);
    }
  },

  async products(page) {
    let pageUrl = "";

    if (page) {
      pageUrl = "?page=" + page;
    }

    try {
      return await ApiService.get("products" + pageUrl);
    } catch (e) {
      console.log(e);
    }
  },

  async product(id) {
    try {
      return await ApiService.get(`products/${id}/show`);
    } catch (e) {
      console.log(e);
    }
  },

  async getProductsByName(name, pointsFrom = "", pointsTo = "") {
    try {
      const response = await ApiService.get(
        `products/byname/${name}?pointsFrom=${pointsFrom}&pointsTo=${pointsTo}`
      );
      if (response.status == 200) {
        return response.data.data;
      }
    } catch (error) {
      return error;
    }
  },

  async saveDeleteFavorite(data) {
    try {
      return await ApiService.post("save-favorite-product", data);
    } catch (e) {
      return e.response;
    }
  },

  async userFavoriteProducts(userId) {
    try {
      return await ApiService.get(`favorite-products/${userId}`);
    } catch (e) {
      console.log(e);
    }
  },

  async exmapleProducts(userId) {
    try {
      return await ApiService.get(`products/examples/${userId}`);
    } catch (e) {
      console.log(e);
    }
  },
};

export { ProductService };

const messages = {
  title: "Mans profils",
  desc: "Laipni lūdzam jūsu personīgajā zonā. Šeit jūs varat atjaunināt vai pielāgot savas pamatdatus un pārbaudīt savu iesūtni.",
  descStaff:
    "Laipni lūdzam jūsu personīgajā zonā. Šeit jūs varat atjaunināt vai pielāgot savus pamatdatus.",
  buttons: {
    showProfile: "Rādīt profilu",
    userProfile: "Doties uz pamatdatiem",
    mailbox: "Doties uz iesūtni",
  },
  categories: {
    baseData: {
      title: "Pamatdati",
      desc: "Aizpildiet savus personīgos un uzņēmuma datus veiksmīgai reģistrācijai.",
    },
    mailbox: {
      desc: "Šeit jūs atradīsiet svarīgus paziņojumus un jaunākās ziņas.",
    },
    bankstatement: {
      title: "Mani punkti",
      desc: "Šeit varat pārbaudīt savu Bridgestone Plus konta stāvokli un apskatīt saņemtos un izmantotos atlīdzības punktus.",
    },
  },
  profile: {
    general: "Personas dati Uzņēmuma dati",
    title: "Mani pamatdati",
    description:
      "Laipni lūdzam jūsu personīgajā zonā. Šeit jūs varat atjaunināt vai pielāgot savas pamatdatus un pārbaudīt savu iesūtni.",
    personalData: "Personas dati",
    password: "Parole",
    password1: "Parole (pēc izvēles)*",
    password2: "Parole (atkārtojums)*",
    formOfAddress: "Tituls",
    choose: "Izvēlieties titulu",
    mr: "Kungs",
    mrs: "Kundze",
    diverse: "Cits",
    firstname: "Vārds",
    surname: "Uzvārds",
    position: "Amats",
    operatingData: "Uzņēmuma dati",
    company: "Uzņēmums",
    legalForm: "Juridiskā forma",
    name: "Nosaukums",
    streetHouseNr: "Iela un mājas numurs",
    street: "Iela",
    postcode: "Pasta indekss",
    location: "Pilsēta",
    country: "Valsts",
    de: "Vācija",
    at: "Austrija",
    ch: "Šveice",
    nl: "Nīderlande",
    it: "Itālija",
    language: "Valoda",
    french: "Franču",
    italian: "Itāļu",
    german: "Vācu",
    nederland: "Holandiešu",
    openingHours: "Darba laiks un kontakti",
    phone: "Telefons",
    website: "Mājaslapa",
    operatingIndicators: "Galvenie darbības rādītāji",
    tireSale: "Riepu pārdošanas apjoms gadā (gab.)",
    tireSaleLabel: "Pārdot riepas lielos apjomos",
    inches: "No tiem ≥ 18 collas (procentos)",
    inchesLabel: "No tiem 18 collas un lielākas (procentos)",
    segmentSale:
      "Riepu pārdošana pēc segmentiem (Budžeta / Vidējā / Premium daļa procentos)",
    premBudget: "Premium",
    midBudget: "Vidējais",
    budBudget: "Budžets",
    distribution: "VASARA / ZIEMA / VISU SEZONU SADALĪJUMS (procentos)",
    tyreSizes: "Sezonālo riepu komplektu skaits (gab.)",
    diffDeliveryAddres: "Pievienot piegādes adresi",
    yourEmailAddress: "Jūsu e-pasta adrese",
    clientNr: "Klienta numuri",
    clientNrSingular: "Klienta numurs",
    clientNrContent:
      "<span class=/text-danger fw-bol/‚>SVARĪGI - LŪDZU, IZLASIET:</span><br /><br /> Lai piesaistītu riepu pasūtījumus pie vairumtirgotāja un/vai Bridgestone, jums jānorāda <b>attiecīgie klienta numuri.</b><br/><br/>Ja <b>jums ir vairāki klienta numuri pie vairumtirgotāja un/vai Bridgestone,</b> piemēram, galvenajam veikalam un filiālēm, vispirms norādiet galvenā veikala klienta numuru.<br/><br/><b>Pārdošanas dati no citiem klienta numuriem tiks piesaistīti galvenajam veikalam.</b>",
    supplySource: "Piegādes avots",
    email: "E-pasta adrese",
    staffEmail: "Dalībnieka e-pasta adrese",
    registered: "Reģistrēts",
    registeredAt: "Reģistrēts",
    lastLogIn: "Pēdējā pieteikšanās",
    details: "Detaļas",
    inDetail: "{name} detaļās",
    bsClientNr: "Bridgestone klienta numurs",
    bftClientNr: "Jūsu Best4Tires klienta numurs",
    acceptTerms: "Piekrītu noteikumiem un dalības nosacījumiem.",
    newCustomerNumbersInfo:
      "<span class='text-danger fw-bold'>SVARĪGI - LŪDZU, IZLASIET:</span><br />Ievadot klienta numuru pirmo reizi, jūsu dati tiks pārbaudīti atbilstībai mūsu sadales kritērijiem. Lēmums par klienta numura aktivizēšanu un tā saistīšanu ar jūsu profilu var ilgt līdz 5 darba dienām.",
    selectWholeSaler: "pie piegādātāja (lūdzu, izvēlieties)",
    cooperation: "DALĪBA / SADARBĪBA",
    error100: "Vērtību summai jābūt vienādai ar 100%.",
    errorRange: "Vērtībai jābūt robežās no 0 līdz 100",
    btw_number_label: "Jūsu uzņēmuma PVN reģistrācijas numurs",
    btw_number: "PVN numurs",
    mobile: "Mobilais tālrunis",
    mobile_label: "Jūsu mobilā tālruņa numurs",
    iban_number: "Jūsu uzņēmuma IBAN numurs",
    iban_number_label: "IBAN numurs",
    yourCsNr: "Jūsu klienta numuri",
    yourLastName: "Jūsu uzvārds",
    positionLabel: "Izvēlieties amatu",
    yourFirstName: "Jūsu vārds",
    companyLabel: "Uzņēmums",
    numberOfSetsOfSeasonalTiresBudget: "Krājumi gadā",
    contactInfo: "Ja jums ir kādi jautājumi, lūdzu, sazinieties ar mums:",
    addAnotherCsNumber: "Pievienot vēl vienu Bridgestone klienta numuru",
    activityAndProfile: "DARBĪBA UN PROFILS",
  },
};

export default {
  messages,
};

const messages = {
  title: "Mijn profiel",
  desc: "Welkom in uw persoonlijke ruimte. Hier kunt u uw<br/>gegevens aanpassen of bijwerken en uw inbox bekijken.",
  descStaff:
    "Welkom in uw persoonlijke omgeving. Hier kunt u uw gegevens aanpassen of bijwerken.",
  buttons: {
    showProfile: "Profiel weergeven",
    userProfile: "Ga naar uw gegevens",
    mailbox: "Ga naar inbox ",
  },
  categories: {
    baseData: {
      title: "Basisgegevens",
      desc: "Vul uw persoonlijke en bedrijfsgegevens in voor een succesvolle registratie.",
    },
    mailbox: {
      desc: "Bekijk belangrijke meldingen en nieuws in uw inbox.",
    },
    bankstatement: {
      title: "Rekeningoverzicht",
      desc: "Hier kunt u uw huidige puntensaldo en de transacties op uw account bekijken.",
    },
  },
  profile: {
    general: "Persoonlijke & bedrijfsgegevens",
    title: "Referentiegegevens",
    description:
      "Welkom in uw persoonlijke omgeving. Hier kunt u uw gegevens aanpassen of bijwerken.",
    personalData: "Persoonlijke gegevens",
    password: "Wachtwoord",
    password1: "Wachtwoord (zelf te kiezen)*",
    password2: "Wachtwoord (herhaling)*",
    formOfAddress: "Titel",
    choose: "Maak een keuze",
    mr: "De heer",
    mrs: "Mevrouw",
    diverse: "Divers",
    firstname: "Voornaam",
    surname: "Achternaam",
    position: "Functie in het bedrijf",
    operatingData: "Bedrijfsgegevens",
    company: "Bedrijf",
    legalForm: "Juridische structuur ",
    name: "Naam",
    streetHouseNr: "Straat en huisnummer",
    street: "Straat",
    postcode: "POSTCODE",
    location: "Stad",
    country: "Land",
    de: "Duitsland",
    at: "Oostenrijk",
    ch: "Zwitserland",
    nl: "Nederland",
    it: "Italië",
    language: "Taal",
    french: "Frans",
    italian: "Italiaans",
    german: "Duits",
    nederland: "Nederlands",
    openingHours: "Openingstijden & contact",
    phone: "Telefoon",
    website: "Website",
    operatingIndicators: "Belangrijke bedrijfsgegevens",
    tireSale: "Jaarlijkse bandenverkoop (aantal)",
    tireSaleLabel: "Bandenverkoop in grote aantallen",
    inches: "Waarvan ≥ 18 inch (in %)",
    inchesLabel: "Waarvan 18 inch en groter in %",
    segmentSale: "Verkoop per segment (Budget / Midden / Premium in %)",
    premBudget: "Premium",
    midBudget: "Midden",
    budBudget: "Budget",
    distribution: "VERDELING ZOMER / WINTER / ALL-SEASON (in %)",
    tyreSizes: "Aantal opslagplaatsen per seizoen (sets banden)",
    diffDeliveryAddres: "Voeg een afleveradres toe",
    yourEmailAddress: "Uw e-mailadres*",
    clientNr: "Klantnummers",
    clientNrSingular: "Klantnummer",
    clientNrContent:
      "<span class='text-danger fw-bold'>BELANGRIJK - LEES DIT ALSTUBLIEFT:</span><br /><br /> Voor de toewijzing van uw bandenbestellingen bij een groothandel en / of bij Bridgestone is het essentieel dat <b>u uw respectieve klantnummers opgeeft.</b><br/><br/>Als u <b>meerdere klantnummers hebt bij een groothandel en / of bij Bridgestone,</b> bijvoorbeeld voor uw hoofdvestiging en filialen, geef dan eerst het klantnummer van de hoofdvestiging op.<br/><br/><b>De verkoop van de andere klantnummers wordt vervolgens toegewezen aan uw hoofdvestiging.</b>",
    supplySource: "Leveringsbron",
    email: "E-mailadres",
    staffEmail: "E-mailadres van de deelnemer",
    registered: "Geregistreerd op",
    registeredAt: "Geregistreerd op",
    lastLogIn: "Laatste login",
    details: "Details",
    inDetail: "{name} in detail",
    bsClientNr: "Uw Bridgestone-klantnummer",
    bftClientNr: "Uw Best4Tires-klantnummer",
    acceptTerms: "Accepteer de algemene voorwaarden en deelnamevoorwaarden.",
    newCustomerNumbersInfo:
      "<span class='text-danger fw-bold'>BELANGRIJK - LEES DIT ALSTUBLIEFT:</span><br />Bij de eerste invoer van het klantnummer worden uw gegevens gecontroleerd op juistheid en aan onze distributiecriteria getoetst. Het kan tot 5 werkdagen duren voordat een besluit wordt genomen om het klantnummer te activeren en aan uw profiel te koppelen.",
    selectWholeSaler: "bij de leverancier (maak een keuze)",
    cooperation: "LID / SAMENWERKING",
    error100: "De som van de waarden moet gelijk zijn aan 100%.",
    errorRange: "De waarde moet tussen 0 en 100 liggen",
    btw_number_label: "het btw-identificatienummer van uw bedrijf",
    btw_number: "Btw-identificatienummer",
    mobile: "MOBIEL NUMMER",
    mobile_label: "Uw telefoonnummer (mobiel)",
    iban_number: "IBAN-nummer VAN UW BEDRIJF",
    iban_number_label: "IBAN-nummer",
    yourCsNr: "Uw klantnummers",
    yourLastName: "Uw achternaam",
    positionLabel: "Functie binnen het bedrijf",
    yourFirstName: "Uw voornaam",
    companyLabel: "Bedrijf",
    numberOfSetsOfSeasonalTiresBudget: "Opslag per jaar",
    contactInfo: "Als u vragen heeft, neem dan contact op met:",
    addAnotherCsNumber: "Voeg een ander Bridgestone-klantnummer toe",
    activityAndProfile: "BEDRIJFSACTIVITEIT EN PROFIEL",
  },
};
export default {
  messages,
};

const messages = {
  title: "Willkommen Bridgestone <span class='text-danger'>PLUS</span>",
  termsAndConditions: "Allgemeine Geschäftsbedingungen",
  accept: "Akzeptieren",
  decline: "Ablehnen",
  dataModal:
    "Um dieses Angebot auf Ihre Interessen zuzuschneiden, bitten wir Sie, kurz Ihre Produktinteressen oder Geschäftsbereiche anzugeben.",
  scrollToAccept:
    "Scrollen Sie nach unten, um unsere Bedingungen zu akzeptieren und fortzufahren",
  welcomeMessage: "Willkommen bei Bridgestone Plus",
  tailorOffer:
    "Um dieses Angebot auf Ihre Interessen zuzuschneiden, bitten wir Sie, kurz Ihre Produktinteressen oder Geschäftsbereiche anzugeben.",
  selectAreas: "Bitte wählen Sie aus den folgenden Bereichen",
  multipleAnswers: "(Mehrfachantworten möglich):",
  personalPreferences: "Persönliche Präferenzen",
  carTyres: "Reifen für Autos, SUVs und Nutzfahrzeuge",
  motorcyclesTyres: "Reifen für Motorräder",
  truckBusTyres: "Reifen für Lkw und Busse",
  agricultureTyres: "Reifen für landwirtschaftliche Fahrzeuge",
  otrTyres: "OTR-Reifen",
  save: "Speichern",
  businessRelationship: "Welche Geschäftsbeziehung haben Sie zu Bridgestone?",
  selectRelationship: "Bitte wählen Sie*:",
  tradingPartner: "Ich bin ein Handelspartner, der...",
  tradingPartnerDirect: "... direkt bei Bridgestone bestellt",
  tradingPartnerIndirect: "... indirekt bestellt (z. B. über den Großhandel)",
  tradingPartnerBoth: "... sowohl direkt als auch indirekt bestellt",
  fleetResponsibleDirect: "Ich bin ein Flottenverantwortlicher, der...",
  fleetResponsibleIndirect:
    "... indirekt bestellt (über Handel oder Großhandel)",
  fleetResponsibleBoth: "... direkt und indirekt bestellt",
  noBusinessRelationship:
    "<b>Ich habe keine Geschäftsbeziehung mit Bridgestone</b> (weder direkt noch indirekt)",
  yourCustomerNumber: "Ihre Bridgestone-Kundennummer*:",
  enterCustomerNumber: "Geben Sie Ihre Kundennummer ein",
  waitPersonalisedView:
    "Bitte warten Sie einen Moment, Ihre personalisierte Ansicht von BRIDGESTONE PLUS wird erstellt.",
  personalised: "PERSONALISIEREN",
  plusInfo:
    "Hier finden Sie Informationen zu Ihrem Plus-Konto und Ihrem Plus-Posteingang.",
  continue: "Fortfahren",
  skip: "Überspringen",
  oneOfTwo: "1 von 2",
  openApps:
    "Sie können die Apps, für die Sie bereits registriert sind, direkt öffnen. Sie können auch Zugang zu anderen Apps anfordern, die für Sie relevant sein könnten.",
  requiredFields:
    "Bitte füllen Sie die Pflichtfelder aus: Titel, Vorname, Nachname, Vorname",
  stage: "Schritt",
  chooseOne: "(Bitte mindestens ein Kästchen ankreuzen)",
  required: "*Pflichtfelder",
  lengthCsNumber: "Länge der Kundennummer: 6–10 Ziffern",
};

export default {
  messages,
};

const messages = {
    "titleColorRev": "homokszínű",
    "buttons": {
        "pending": "Függőben",
        "open": "Nyitott",
        "requestAccess": "Hozzáférés kérése"
    },
    "my": "Alkalmazásaim",
    "directRoute": "Az Ön közvetlen linkjei a Bridgestone webalkalmazásaihoz.",
    "welcomeMessage": "Üdvözöljük a BRIDGESTONE PLUS <span class=\"text-danger\">PLUS</span>-ban, %{first_name} %{last_name}",
    "showProfile": "Profil megtekintése",
    "newMessages": "Új üzenet a bejövő mappában",
    "myApps": "Alkalmazásaim",
    "allApps": "Összes alkalmazás",
    "myAppsDescription": "Minden Bridgestone webalkalmazás, amelyben regisztrált.",
    "allAppsDescription": "Minden Bridgestone webalkalmazás, amely releváns lehet az Ön számára.",
    "myProfile": {
        "welcomeMessage": "Üdvözöljük a személyes területén. Itt frissítheti vagy módosíthatja adatait, és megtekintheti a beérkezett üzeneteit.",
        "masterData": "Alapadatok",
        "completeDetails": "Töltse ki személyes és vállalati adatait a helyes regisztráció érdekében.",
        "goToMasterData": "Ugrás az adataimhoz",
        "goToInbox": "Ugrás a beérkezett üzenetekhez"
    },
    "masterData": {
        "welcomeMessage": "Üdvözöljük a személyes területén. Itt frissítheti vagy módosíthatja alapadatait.",
        "personalDetails": "Személyes adatok",
        "companyDetails": "Vállalati adatok",
        "emailAddress": "E-mail cím",
        "title": "Megszólítás",
        "surname": "Vezetéknév",
        "firstName": "Keresztnév",
        "jobPosition": "Munkakör",
        "streetAndHouseNumber": "Cég címe",
        "postalCode": "Irányítószám",
        "city": "Város",
        "country": "Ország",
        "legalStructure": "Jogi forma",
        "customerNumber": "Bridgestone ügyfélszám",
        "importantPleaseRead": "Fontos, kérjük, olvassa el",
        "customerNumberInfo": "A gumiabroncs-rendelések pontos hozzárendeléséhez az egyes forgalmazókhoz fontos, hogy megadja az ügyfélszámát.",
        "multipleCustomerNumbers": "Ha több ügyfélszáma van egy forgalmazónál, például a fővállalat és a fiókok számára, először a fővállalat ügyfélszámát kell megadnia.",
        "salesAssignment": "Az egyéb ügyfélszámokhoz kapcsolódó értékesítés a fővállalathoz kerül hozzárendelésre.",
        "supplier": "Szállító",
        "addAnotherCustomerNumber": "Adjon hozzá ügyfélszámot",
        "save": "Mentés",
        "delete": "Törlés"
    },
    "inbox": {
        "welcomeMessage": "Itt talál értesítéseket és fontos híreket.",
        "sender": "Feladó",
        "subject": "Tárgy",
        "date": "Dátum",
        "formConsulting": "Űrlap érkezett - Köszönjük a kitöltést",
        "back": "Vissza"
    },
    "legalAndContact": {
        "cookieSettings": "Cookie-beállítások",
        "dataPolicy": "Adatvédelmi irányelvek",
        "termsOfUse": "Felhasználási feltételek",
        "helpAndContact": "Segítség és kapcsolat"
    },
    "logInSignUp": {
        "logIn": "Bejelentkezés",
        "signUp": "Regisztráció",
        "logInMessage": "Jelentkezzen be e-mail címével és jelszavával.",
        "signUpMessage": "Ha új felhasználó, kérjük, regisztráljon."
    }
}

export default {
    messages
}
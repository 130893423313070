const messages = {
  makeChoice: "Faça aqui a sua escolha.",
  seeAll: "Ver tudo",
  clear: "Eliminar",
  back: "Volver atrás",
  save: "Guardar",
  close: "Fechar",
  of: "De",
  to: "à",
  copyrights: "© {date} Bridgestone Solutions. Todos os direitos reservados.",
  pageNotFound: "Página não encontrada",
  accessDenied: "Acesso negado",
  search: "Pesquisar",
  buttons: {
    export: "Export",
    delete: "Eliminar",
    save: "Guardar",
    cancel: "Cancelar",
    next: "Continuar",
    back: "Volver atrás",
    skip: "Saltar",
    send: "Enviar",
    clear: "Eliminar",
    readNow: "Leia agora",
    pointsList: "Lista de pontos",
    more: "Mais",
    close: "Fechar",
  },
  tooltips: {
    info: "Precisa de Ajuda? +351 Xxx-xxx-xxx",
  },
  confirmation: {
    header: "Confirmação necessária",
    message: "Deseja eliminar este registo?",
  },
  nav: {
    dashboard: "Início",
    account: "O meu perfil",
    mailbox: "Caixa de entrada",
    singleMailbox: "Mensagen",
    stammdaten: "Dados Principais",
    news_feeds: "Notícias",
    pointsOverview: "Points overview",
    about: "Sobre a Bridgestone",
    service_number: "Contactos e Serviços",
  },
  menu: {
    account: "O meu perfil",
    plus: 'Sobre a Bridgestone <span class="text-danger">Plus</span>',
    salesSupport: "Suporte de vendas",
    mailbox: "Caixa de entrada",
    about: "Sobre a Bridgestone",
    trainingAndCourses: "Formações e cursos",
    externalTraining: "Formações externas",
    productInfo: "Informações sobre os produtos",
    productBrochures: "Brochuras de produtos",
    priceLists: "Listas de preços",
    price: "Pricat",
    complaintForm: "Formulário de reclamação",
    sales_support: "Suporte de vendas",
    contact_service: "CONTACTOS E SERVIÇOS",
    logOut: "Desconectar",
    news: "Notícias",
    news_feeds: "Notícias",
    service_number: "Contactos e Serviços",
  },
  validation: {
    requiredField: "Campo obrigatório",
  },
};

export default {
  messages,
};

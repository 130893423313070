const messages = {
  title: 'Bem-vindo ao BRIDGESTONE <span class="text-danger">PLUS</span>',
  termsAndConditions: "Termos e condições",
  accept: "Aceitar",
  decline: "Recusar",
  dataModal:
    "Para podermos adaptar esta oferta aos seus interesses, pedimos-lhe que indique brevemente os seus interesses em termos de produtos ou áreas de negócio.",
  scrollToAccept:
    "Por favor, continue a ler para aceitar as nossas condições para continuar",
  welcomeMessage: "Bem-vindo à BRIDGESTONE PLUS",
  tailorOffer:
    "Para podermos adaptar esta oferta aos seus interesses, pedimos-lhe que indique brevemente os seus interesses em termos de produtos ou áreas de negócio.",
  selectAreas: "Por favor, seleccione uma das seguintes áreas*",
  multipleAnswers: "(É possivel selecionar várias respostas)",
  personalPreferences: "Preferências Pessoais",
  carTyres: "Pneus para automóveis, SUV, veículos utilitários ",
  motorcyclesTyres: "Pneus para motocicletas",
  truckBusTyres: "Pneus para camiões e autocarros",
  agricultureTyres: "Pneus para veículos agrícolas",
  otrTyres: "Pneus OTR",
  save: "Guardar",
  businessRelationship: "Qual é a sua relação comercial com a Bridgestone?",
  selectRelationship: "Por favor, selecione uma das seguintes opções:",
  tradingPartner: "Sou um parceiro comercial que está…",
  tradingPartnerDirect: "... a comprar diretamente da Bridgestone",
  tradingPartnerIndirect:
    "… a comprar indiretamente (via distribuidores, por exemplo)",
  tradingPartnerBoth:
    "... a realizar compras tanto diretamente como indiretamente",
  fleetResponsibleDirect: "Sou um gestor de frota que está... ",
  fleetResponsibleIndirect:
    "... a encomendar indiretamente (via distribuidores ou grossistas)",
  fleetResponsibleBoth: "... a encomendar tanto diretamente como indiretamente",
  noBusinessRelationship:
    "<b>Não tenho qualquer relação comercial com a Bridgestone </b>(nem direta, nem indireta)",
  yourCustomerNumber: "O seu número de cliente Bridgestone*: ",
  enterCustomerNumber: "Insira o seu número de cliente",
  waitPersonalisedView:
    "Por favor, aguarde um momento enquanto a sua visão personalizada do BRIDGESTONE PLUS é criada.",
  personalised: "Personalizado",
  plusInfo:
    "Aqui encontrará informações sobre a sua conta Plus e a sua caixa de correio Plus.",
  continue: "Continuar",
  skip: "Ignorar",
  oneOfTwo: "1 de 2",
  openApps:
    "Pode aceder diretamente às aplicações para as quais já está registado. Também pode solicitar acesso a outras aplicações que possam ser relevantes para si.",
  requiredFields:
    "Preencher os campos obrigatórios: Título, Nome próprio, Apelido",
  stage: "etapa",
  chooseOne: "(Assinale pelo menos 1 opção)",
  required: "*campos obrigatórios",
  lengthCsNumber: "Comprimento do número de cliente: 6 dígitos",
};
export default {
  messages,
};

const messages = {
  title: "Моят личен банков извлечение",
  desc: "Тук можете да видите вашето лично извлечение по сметката Bridgestone Plus с получените и използваните от вас бонус точки. Моля, обърнете внимание, че кредитирането на точки за вашите поръчки на гуми, в зависимост от източника, може да отнеме до 6 седмици след датата на поръчката.",
  score: "Салдо на точки",
  regularPoints: "Редовни точки",
  boosterPoints: "Бонус точки",
  date: "Дата",
  supplySourceReason: "Източник/Причина за покупката",
  article: "Артикул",
  brand: "Марка",
  product: "Продукт",
  type: "Тип",
  inch: "Инча",
  season: "Сезон",
  amount: "Количество",
  standardPoints: "Точки",
  boosterProduct: "Бонус продукт",
  boosterSeason: "Бонус сезон",
  otherActions: "Други действия или причини",
  totalPoints: "Общо точки",
  income: "Приходи",
  spending: "Разходи",
  lastMonth: "Заключително салдо",
  loadMore: "Зареди още 20 транзакции",
  typeFilter: "Филтрирай по източник",
  dateFilter: "Филтрирай по период",
  customer_number: "Номер на клиент",
  currentPoints: "Текущи точки",
  calculationDate: "Дата на изчисление",
  positivePoints: "Позитивни точки",
  negativePoints: "Вече изразходвани",
  tyresAmount: "Количество гуми годишно",
  pointsTotal: "Общо точки",
  currentPointState: "Текущо състояние на точките",
  currentKickbackState: "Текущ кешбек", // "Kickback" може да се преведе като "кешбек" или "отстъпка", в зависимост от контекста
};
export default {
  messages,
};

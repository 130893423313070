<template>
  <MDBCard class="py-0 border-0">
    <CategoryTop :title="$t('bankstatement.title')" :breadcrumbs-parent-routes="['account']"
      :description="$t('bankstatement.desc')" content-class="px-4 mt-4" :leftCol="isDutch ? '7' : '8'"
      :rightCol="isDutch ? '5' : '4'">
      <template #right>
        <MDBTable borderless class="points-overview points-overview--small">
          <tbody>
            <tr class="success-green fw-bold summary-title">
              <td>
                {{ $t("bankstatement.score") }}
                {{ dates.parseDate(currentDate) }}
              </td>
              <td>
                <strong>{{ user.display_points ? points?.sumStandardPoints : 0 }}
                </strong>
              </td>
              <td v-if="isDutch">
                {{ $t("kickback.title") }}
              </td>
              <td v-if="isDutch">
                <strong>{{
                  user.display_kickbacks
                    ? points?.sumStandardKickbacks
                      ? points?.sumStandardKickbacks.toFixed(2)
                      : "0.00"
                    : "0.00"
                }}&euro;
                </strong>
              </td>
            </tr>
            <tr class="summary-details">
              <td>
                {{ $t("bankstatement.income") }}
                {{ getMonth(currentDate.getMonth() + 1) }}
              </td>
              <td>
                <strong>{{
                  user.display_points ? currentMonthPlus : 0
                }}</strong>
              </td>

              <td v-if="isDutch">
                {{ $t("bankstatement.income") }}
                {{ getMonth(currentDate.getMonth() + 1) }}
              </td>
              <td v-if="isDutch">
                <strong>{{
                  user.display_kickbacks ? currentMonthKickbacksPlus : "0"
                  }}&euro;</strong>
              </td>
            </tr>
            <tr class="summary-details">
              <td>
                {{ $t("bankstatement.spending") }}
                {{ getMonth(currentDate.getMonth() + 1) }}
              </td>
              <td>
                <strong class="text-danger">{{ user.display_points ? currentMonthMinus : 0 }}
                </strong>
              </td>
              <td v-if="isDutch">
                {{ $t("bankstatement.spending") }}
                {{ getMonth(currentDate.getMonth() + 1) }}
              </td>
              <td v-if="isDutch">
                <strong class="text-danger">{{
                  user.display_kickbacks
                    ? currentMonthKickbacksMinus
                      ? currentMonthKickbacksMinus.toFixed(2)
                      : "0.00"
                    : "0.00"
                }}&euro;
                </strong>
              </td>
            </tr>
            <tr class="summary-details">
              <td>
                {{ $t("bankstatement.lastMonth") }}
                {{ getMonth(currentDate.getMonth()) }}
              </td>
              <td>
                <strong>{{ user.display_points ? lastMonthPoints : 0 }}
                </strong>
              </td>
              <td v-if="isDutch">
                {{ $t("bankstatement.lastMonth") }}
                {{ getMonth(currentDate.getMonth()) }}
              </td>
              <td v-if="isDutch">
                <strong>{{
                  user.display_kickbacks
                    ? lastMonthKickbacks
                      ? lastMonthKickbacks.toFixed(2)
                      : "0.00"
                    : "0.00"
                }}&euro;
                </strong>
              </td>
            </tr>
          </tbody>
        </MDBTable>
      </template>
    </CategoryTop>

    <MDBRow>
      <MDBCol col="12" v-if="isDutch">
        <MDBRow class="points-overview">
          <MDBCol>
            <div class="btn btn-danger text-uppercase px-5" @click="showVoucherModal()" v-if="canSeeVoucherBtn">
              {{ $t('buttons.exchangeVoucher') }}
            </div>
            <div class="btn btn-danger text-uppercase px-5" @click="showExchangeKickbackModal()"
              v-if="canSeeKickbackBtn">
              {{ $t('buttons.exchangeKickback') }}
            </div>
          </MDBCol>
        </MDBRow>
      </MDBCol>
      <MDBCol>
        <div class="table-responsive">
          <MDBRow class="points-overview">
            <MDBCol xl="6" md="6" class="mb-2">
              <label for="pointType" class="label px-3 text-uppercase">
                {{ $t("bankstatement.typeFilter") }}
              </label>
              <select class="form-select mb-1" aria-label=".form-select-lg example" v-model="pointType"
                @change="filterResults" id="pointType">
                <option :value="null">
                  {{ $t("global.makeChoice") }}
                </option>
                <option value="bsPlus">BRIDGESTONE PLUS</option>
                <option :value="'bsDe_' + wsh.id" v-for="(wsh, index) in wshSelect" :key="index">
                  {{ wsh.name }}
                </option>
              </select>
            </MDBCol>
            <MDBCol xl="6" md="6" class="mb-2">
              <label for="date" class="label px-3 text-uppercase">
                {{ $t("bankstatement.dateFilter") }}
              </label>
              <VueDatePicker v-model="date" range :locale="datePickerLocale" :enable-time-picker="false"
                :cancelText="$t('buttons.cancel')" :selectText="$t('buttons.select')"
                @update:model-value="filterResults" :format="formatDatePicker" id="date"></VueDatePicker>
            </MDBCol>
          </MDBRow>
          <MDBTable borderless class="points-overview">
            <thead>
              <tr>
                <th v-for="(head, id) in tableHeaders" :key="`key_${id}`">
                  {{ head.label }}
                  <div class="d-flex justify-content-end py-1" @click="
                    sort({
                      field: head.field,
                      factory: head.factory ? head.factory : undefined,
                    })
                    ">
                    <MDBIcon v-bind:icon="head.field == sortData.field
                      ? `sort-${sortData.order}`
                      : 'sort'
                      " />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(account, key) in bankData" :key="key">
                <td>{{ dates.parseDate(account.date) }}</td>
                <td v-html="account.source ? setSource(account.source) : '-'"></td>
                <td>
                  {{ account.customer_number ? account.customer_number : "-" }}
                </td>
                <td>{{ account.article ? account.article : "-" }}</td>
                <td>{{ account.brand ? account.brand : "-" }}</td>
                <td>{{ account.product ? account.product : "-" }}</td>
                <td>{{ account.type ? account.type : "-" }}</td>
                <td>{{ account.dimension ? account.dimension : "-" }}</td>
                <td>
                  {{ account.saison ? setSeason(account.saison) : "-" }}
                </td>
                <td>{{ account.qty ? account.qty : "-" }}</td>

                <td>{{ account.provenance ? account.provenance : "-" }}</td>
                <td :class="{ 'text-danger': account.points < 0 }" v-if="user.display_points">
                  {{ account.sumPoints }}
                </td>
                <td :class="{ 'text-danger': account.kickBackFee < 0 }" v-if="isDutch && user.display_kickbacks">
                  {{ account.kickBackFee ? account.kickBackFee.toFixed(2) : 0 }}
                  &euro;
                </td>
              </tr>
            </tbody>
          </MDBTable>
          <div v-if="loading">
            <Loader :show="true" spinner="spinner-red.svg" />
          </div>
          <div class="load-more d-flex justify-content-center">
            <div @click="loadMore" class="btn btn-danger text-uppercase px-5">
              {{ $t("bankstatement.loadMore") }}
            </div>
          </div>
        </div>
      </MDBCol>
    </MDBRow>
  </MDBCard>
  <MDBModal v-model="modal" tabindex="-1" centered size="xl">
    <KickbackExchange @close="(e) => showHideModal()" />
  </MDBModal>
  <MDBModal v-model="voucherModal" tabindex="-1" centered size="xl">
    <VoucherModal @close="(e) => showVoucherModal()" />
  </MDBModal>
</template>

<script setup>
import CategoryTop from "../General/CategoryTop/index.vue";
import Loader from "../General/Loader/index.vue";
import KickbackExchange from "./PointsOverview/KickbackExchange.vue";
import VoucherModal from "./PointsOverview/VoucherModal.vue";
import { MDBCard, MDBRow, MDBCol, MDBTable, MDBIcon, MDBModal } from "mdb-vue-ui-kit";
import VueDatePicker from "@vuepic/vue-datepicker";
import TokenService from "@/services/storage.service";

import { useStore } from "vuex";
import { computed, ref, reactive, watch, toRaw, onMounted } from "vue";

import { useI18n } from "vue-i18n";
const { t } = useI18n() // Import the translation function from i18n
import dates from "@/plugins/dates";
const LANG = localStorage.getItem("lang");
const datePickerLocale = 'en'
const store = useStore();
const modal = ref(false)
const voucherModal = ref(false)
const isDutch = computed(() => LANG === "nl");
const user = computed(() => store.getters.getMe);
const page = ref(0);
const bankData = ref([]);
const pointType = ref(null);
const date = ref([]);
const canSeeVoucherBtn = ref(false)
const canSeeKickbackBtn = ref(false)
const tableHeaders = ref([
  {
    label: t("bankstatement.date"),
    field: "date",
    factory: (v) => new Date(v).getTime(),
  },
  {
    label: t("bankstatement.supplySourceReason"),
    field: "source",
  },
  {
    label: t("bankstatement.customer_number"),
    field: "customer_number",
  },
  {
    label: t("bankstatement.article"),
    field: "article",
  },
  {
    label: t("bankstatement.brand"),
    field: "brand",
  },
  {
    label: t("bankstatement.product"),
    field: "product",
  },
  {
    label: t("bankstatement.type"),
    field: "type",
  },
  {
    label: t("bankstatement.inch"),
    field: "dimension",
  },
  {
    label: t("bankstatement.season"),
    field: "saison",
    factory: (v) => {
      return !v ? setSeason("") : setSeason(v);
    },
  },
  {
    label: t("bankstatement.amount"),
    field: "qty",
  },
  {
    label: t("bankstatement.otherActions"),
    field: "provenance",
  },
]);

const sortData = reactive({
  field: "",
  order: "",
  factory: (v) => v,
  random: "",
});

store.dispatch("bankStatement", page.value);
store.dispatch("fetchWshSelect");

const loading = computed(() => store.getters.getPending);

const points = computed(() => store.getters.getPoints);
const bank = computed(() => store.getters.getBankStatementData);

const lastMonthPoints = computed(() => store.getters.getLastMonthPoints);
const currentMonthMinus = computed(() => store.getters.getCurrentMonthMinus);
const currentMonthPlus = computed(() => store.getters.getCurrentMonthPlus);

const lastMonthKickbacks = computed(() => store.getters.getLastMonthKickbacks);
const currentMonthKickbacksMinus = computed(
  () => store.getters.getCurrentMonthKickbacksMinus
);
const currentMonthKickbacksPlus = computed(
  () =>
    `${store.getters.getCurrentMonthKickbacksPlus
      ? store.getters.getCurrentMonthKickbacksPlus.toFixed(2)
      : "0.00"
    }`
);
const wshSelect = computed(() => store.getters.getWshSelect);

const currentDate = new Date();
const getMonth = (idx) => {
  let objDate = new Date();
  objDate.setDate(1);
  objDate.setMonth(idx - 1);

  let locale = 'ro-RO'
  let month = objDate.toLocaleString(locale, { month: "long" });

  return month;
};

const setSeason = (text) => {
  const text_len = text.length;
  let data;
  if (text_len % 2 != 0) {
    let start = (text_len - 1) / 2;
    data = text.slice(start, start + 1);
  } else {
    let start = text_len / 2 - 1;
    data = text.slice(start, start + 2);
  }

  if (data == "S") {
    return t("bankstatement.seasons.summer");
  } else if (data == "W") {
    return t("bankstatement.seasons.winter");
  } else {
    return t("bankstatement.seasons.all");
  }
};

const setSource = (data) => {
  if (data == "BSPLUS") {
    return `BRIDGESTONE <span class='text-danger'>PLUS</span>`;
  } else {
    return data;
  }
};

const loadMore = async () => {
  page.value += 1;

  let query;

  const dateFrom =
    date.value && date.value[0]
      ? new Date(date.value[0]).toLocaleDateString(LANG)
      : "";
  const dateTo =
    date.value && date.value[1]
      ? new Date(date.value[1]).toLocaleDateString(LANG)
      : "";

  if (date.value?.length && pointType.value) {
    query = `source=${pointType.value}&dateFrom=${dateFrom}&dateTo=${dateTo}`;
  } else if (date.value && !pointType.value) {
    query = `dateFrom=${dateFrom}&dateTo=${dateTo}`;
  } else if (pointType.value) {
    query = `source=${pointType.value}`;
  }

  store.dispatch("bankStatement", { page: page.value, query: query });
};

const filterResults = async () => {
  page.value = 0;
  bankData.value = [];
  let query;

  const dateFrom =
    date.value && date.value[0]
      ? new Date(date.value[0]).toLocaleDateString(LANG)
      : "";
  const dateTo =
    date.value && date.value[1]
      ? new Date(date.value[1]).toLocaleDateString(LANG)
      : "";

  if (date.value?.length && pointType.value) {
    query = `source=${pointType.value}&dateFrom=${dateFrom}&dateTo=${dateTo}`;
  } else if (date.value && !pointType.value) {
    query = `dateFrom=${dateFrom}&dateTo=${dateTo}`;
  } else if (pointType.value) {
    query = `source=${pointType.value}`;
  }
  store.dispatch("bankStatement", { page: page.value, query: query });
};

const sort = (
  args = {
    field: "",
    order: "",
    factory: (v) => v,
  }
) => {
  sortData.field = args.field;
  sortData.order = args.order || sortData.order !== "down" ? "down" : "up";
  sortData.factory = args.factory
    ? args.factory
    : (v) => {
      if (v == null) return "";
      return v;
    };
  sortData.random = Math.random();
};

watch(bank, (newVal) => {
  if (newVal.length > 0 && page.value == 0) {
    bankData.value = Object.values(toRaw(newVal));
  } else {
    bankData.value.push(...Object.values(toRaw(newVal)));
  }
  sort(toRaw(sortData));
});

watch(
  sortData,
  (newVal) => {
    let sortVal = toRaw(newVal);
    bankData.value.sort((a, b) => {
      if (newVal.order == "up") {
        return sortVal.factory(a[sortVal.field]) >
          sortVal.factory(b[sortVal.field])
          ? 1
          : sortVal.factory(b[sortVal.field]) >
            sortVal.factory(a[sortVal.field])
            ? -1
            : 0;
      } else {
        return sortVal.factory(a[sortVal.field]) <
          sortVal.factory(b[sortVal.field])
          ? 1
          : sortVal.factory(b[sortVal.field]) <
            sortVal.factory(a[sortVal.field])
            ? -1
            : 0;
      }
    });
  },
  {
    deep: true,
  }
);

const formatDatePicker = (date) => {
  return `${dates.parseDate(date[0])} - ${date[1] ? dates.parseDate(date[1]) : ""
    }`;
};

watch(user, async () => {
  canSeeVoucherBtn.value = TokenService.showElement("voucherExchange.see");
  canSeeKickbackBtn.value = TokenService.showElement("kickbackExchange.see");
});

onMounted(async () => {
  if (user.value.display_points) {
    tableHeaders.value.push({
      label: t("bankstatement.totalPoints"),
      field: "points",
    });
  }

  if (
    isDutch.value &&
    user.value.display_kickbacks
  ) {
    tableHeaders.value.push({
      label: t("kickback.word"),
      field: "kickBackFee",
    });
  }

  canSeeVoucherBtn.value = TokenService.showElement("voucherExchange.see");
  canSeeKickbackBtn.value = TokenService.showElement("kickbackExchange.see");
});

const showExchangeKickbackModal = async () => {
  const currentYear = new Date().getFullYear()
  await store.dispatch('fetchTyreLimit', currentYear)
  showHideModal()
}

const showHideModal = () => {
  modal.value = !modal.value
}

const showVoucherModal = () => {
  voucherModal.value = !voucherModal.value
}
</script>

<style scoped lang="scss">
@import "../../styles/_variables.scss";
@import "@/styles/points_overview.scss";
</style>

const messages = {
  makeChoice: "Faceți alegerea dvs. aici.",
  seeAll: "Vezi tot",
  clear: "Șterge",
  back: "Înapoi",
  save: "Salvează",
  close: "Închide",
  of: "Din",
  to: "La",
  copyrights: "© {date} Bridgestone Solutions. Toate drepturile rezervate.",
  pageNotFound: "Pagină nu găsită",
  accessDenied: "Acces refuzat",
  search: "Căutare",
  buttons: {
    export: "Exportă",
    delete: "Șterge",
    save: "Salvează",
    cancel: "Anulează",
    next: "Următorul",
    back: "Înapoi",
    skip: "Sari",
    send: "Trimite",
    readNow: "Citeste acum",
    pointsList: "Lista de puncte",
    clear: "Șterge",
    more: "Mai mult",
    close: "Închide",
  },
  tooltips: {
    info: "Ai nevoie de ajutor? 00 00 00 00",
  },
  confirmation: {
    header: "Confirmare necesară",
    message: "Dorești să ștergi această intrare?",
  },
  nav: {
    dashboard: "Acasă",
    account: "Profilul meu",
    mailbox: "Inbox",
    singleMailbox: "Mesaje",
    stammdaten: "Date principale",
    news_feeds: "Ştiri",
    pointsOverview: "Points overview",
    about: "Despre BS Plus",
    service_number: "Contact service",
  },
  menu: {
    account: "Profilul meu",
    plus: 'Despre Bridgestone <span class="text-danger">Plus</span>',
    salesSupport: "Suport Vânzări",
    mailbox: "Inbox",
    about: "Despre BS Plus",
    trainingAndCourses: "Training și Cursuri",
    externalTraining: "Training Extern",
    productInfo: "Informații despre Produse",
    productBrochures: "Broșuri Produse",
    priceLists: "Liste de Prețuri",
    price: "Preț",
    complaintForm: "Formular de Reclamație",
    sales_support: "Suport Vânzări",
    contact_service: "Informații și Serviciu Contact",
    logOut: "Deconectează-te",
    news: "Știri",
    news_feeds: "Ştiri",
    service_number: "Contact service",
  },
  validation: {
    requiredField: "Câmp obligatoriu",
  },
};
export default {
  messages,
};

const messages = {
  title: "Inicio",
  welcomeMessage:
    'Bienvenido a BRIDGESTONE <span class="text-danger">PLUS</span>, %{first_name} %{last_name}',
  home: "Inicio",
  search: "Buscar",
  sortBy: "Ordenar por",
  seeMore: "Ver más ",
  bankstatement: {
    yourScore: "Su número actual de puntos de marketing:",
    lastUpdate: "Última actualización:",
    link: "Resumen del saldo de puntos",
  },
};

export default {
  messages,
};

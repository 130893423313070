const messages = {
  title: "Home",
  welcomeMessage:
    'Welkom bij BRIDGESTONE <span class="text-danger">PLUS</span>, %{first_name} %{last_name}',
  home: "Home",
  search: "Zoeken",
  sortBy: "Sorteren op",
  seeMore: "Meer weergeven",
  bankstatement: {
    yourScore: "Uw huidige aantal marketingpunten:",
    lastUpdate: "Laatste update:",
    link: "Overzicht puntensaldo",
  },
};

export default {
  messages,
};

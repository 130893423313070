const messages = {
  subtitle: "Meet Our Bridgestone team",
  title: "Contactos y Servicios",
  description:
    "Bienvenido al Area de Servicio de BRIDGESTONE PLUS. Aqui puedes encontrar los detalles de los contactos del equipo de ventas.",
  teams: {
    direct: "Direct",
    indirect: "In Direct",
    subtitle: "Meet Our Bridgestone team",
  },
};

export default {
  messages,
};

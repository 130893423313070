import global from "@/locales/en-IE/global";
import error403 from "@/locales/en-IE/errors/403";
import error404 from "@/locales/en-IE/errors/404";
import error500 from "@/locales/en-IE/errors/500";
import error503 from "@/locales/en-IE/errors/503";
import error504 from "@/locales/en-IE/errors/504";
import maintenance from "@/locales/en-IE/auth/maintenance";
import toast from "@/locales/en-IE/toast/toast";
import login from "@/locales/en-IE/auth/login";
import onboarding from "@/locales/en-IE/auth/onboarding";
import dashboard from "@/locales/en-IE/dashboard/dashboard";
import account from "@/locales/en-IE/account/account";
import mailbox from "@/locales/en-IE/account/mailbox";
import bankstatement from "@/locales/en-IE/account/bankstatement";
import myApps from "@/locales/en-IE/my-apps/myApps";
import about from "@/locales/en-IE/about/about";
import contactService from "@/locales/en-IE/about/contactService";

//prettier-ignore
const messages = {
  global: global.messages,
  error403: error403.messages,
  error404: error404.messages,
  error500: error500.messages,
  error503: error503.messages,
  error504: error504.messages,
  maintenance: maintenance.messages,
  toast: toast.messages,
  login: login.messages,
  onboarding: onboarding.messages,
  dashboard: dashboard.messages,
  account: account.messages,
  mailbox: mailbox.messages,
  myApps: myApps.messages,
  bankstatement: bankstatement.messages,
  about: about.messages,
  contactService: contactService.messages
}

export default {
  locale: "en-IE",
  messages,
};

const messages = {
  title: 'Bienvenue sur BRIDGESTONE <span class="text-danger">PLUS</span>',
  termsAndConditions: "Términos y condiciones",
  accept: "Aceptar",
  decline: "Rechazar",
  dataModal:
    "Para poder mostrarte contenido de tu interés, te pedimos que nos indiques brevemente tus intereses en cuanto a productos o áreas de negocio.",
  scrollToAccept:
    "Por favor, sigue leyendo para aceptar nuestras condiciones y continuar.",
  welcomeMessage: "Bienvenido a BRIDGESTONE PLUS",
  tailorOffer:
    "Para poder adaptar esta oferta a tus intereses, te pedimos que indiques brevemente tus intereses en términos de productos o áreas de negocio.",
  selectAreas:
    "Por favor, selecciona de las siguientes áreas aquella/s que sea/n de tu interés.",
  multipleAnswers: "(es posible seleccionar varias opciones):",
  personalPreferences: "Preferencias personales",
  carTyres: "Neumáticos para coches, SUV, vehículos utilitarios",
  motorcyclesTyres: "Neumáticos para motocicletas",
  truckBusTyres: "Neumáticos para camiones y autobuses",
  agricultureTyres: "Neumáticos para vehículos agrícolas",
  otrTyres: "Neumáticos OTR",
  save: "Guardar",
  businessRelationship: "¿Cuál es tu relación comercial con Bridgestone?",
  selectRelationship: "Por favor, selecciona de las siguientes opciones:",
  tradingPartner: "Soy un socio comercial que está...",
  tradingPartnerDirect: "... realizando pedidos directamente a Bridgestone",
  tradingPartnerIndirect:
    "... realizando pedidos de manera indirecta (a través de mayoristas, por ejemplo)",
  tradingPartnerBoth:
    "... realizando pedidos tanto directamente como indirectamente",
  fleetResponsibleDirect: "Soy responsable de flotas que está...",
  fleetResponsibleIndirect:
    " ... realizando pedidos de manera indirecta (a través del comercio o mayoristas)",
  fleetResponsibleBoth:
    "... realizando pedidos tanto directamente como indirectamente",
  noBusinessRelationship:
    "<b>No tengo relación comercial con Bridgestone </b>(ni directamente ni indirectamente)",
  yourCustomerNumber: "Tu número de cliente de Bridgestone*:",
  enterCustomerNumber: "Ingresa tu número de cliente",
  waitPersonalisedView:
    "Por favor, espera un momento mientras se genera tu vista personalizada de BRIDGESTONE PLUS.",
  personalised: "Personalizado",
  plusInfo:
    "Aquí encontrarás información sobre su cuenta Plus y tu bandeja de entrada.",
  continue: "Continuar",
  skip: "Omitir",
  oneOfTwo: "1 de 2",
  openApps:
    "Puedes abrir directamente las aplicaciones para las que ya estás registrado. También puedes solicitar acceso a otras aplicaciones que puedan ser relevantes para ti.",
  requiredFields: "Rellene los campos obligatorios: Título, Nombre, Apellidos",
  stage: "Paso",
  chooseOne: "(Assinalar pelo menos 1 quadrícula)",
  required: "*campos obligatorios",
  lengthCsNumber: "Por favor, incluye un valor entre 6-10 dígitos",
};

export default {
  messages,
};

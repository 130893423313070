const messages = {
  title: "Accueil",
  welcomeMessage:
    'Bienvenue sur BRIDGESTONE <span class="text-danger">PLUS</span>, %{first_name} %{last_name}',
  home: "Accueil",
  search: "Recherche",
  sortBy: "Trier par",
  seeMore: "Voir plus",
  bankstatement: {
    yourScore: "Votre nombre actuel de points marketing:",
    lastUpdate: "Dernière mise à jour:",
    link: "Aperçu du solde de points",
  },
};

export default {
  messages,
};

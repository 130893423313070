const messages = {
  title: "Inbox",
  desc: "Aici puteți găsi notificări și știri importante.",
  newMessages: "Mesaje noi în căsuța poștală",
  link: "Afișați mesajele",
  showMessages: "Afișați mesajele",
  message: {
    sender: "Expeditor",
    thread: "Subiect",
    date: "Data",
  },
  sender: {
    system: "BRIDGESTONE PLUS",
  },
  back: "Înapoi",
  time: {
    secondsAgo: "{time} secunde în urmă",
    minutesAgo: "{time} minute în urmă",
    hoursAgo: "{time} ore în urmă",
    daysAgo: "{time} zile în urmă",
  },
};

export default {
  messages,
};

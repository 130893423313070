const messages = {
  title: "Domů",
  welcomeMessage:
    'Vítejte v BRIDGESTONE <span class="text-danger">PLUS</span>, %{first_name} %{last_name}',
  home: "Domů",
  search: "Hledat",
  sortBy: "Seřadit podle",
  seeMore: "Zobrazit více",
  bankstatement: {
    yourScore: "Váš aktuální počet marketingových bodů:",
    lastUpdate: "Nejnovější aktualizace:",
    link: "Přehled bodového zůstatku",
  },
};
export default {
  messages,
};

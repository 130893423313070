const messages = {
  "title": "Добре дошли в BRIDGESTONE <span class='text-danger'>PLUS</span>",
  "description": "<b>Вече имате акаунт?</b><br/>Влезте с вашия имейл адрес и парола.<br/><br/><b>Нов потребител сте?</b><br/>Ако сте нов потребител, моля, регистрирайте се.<br/><br/>",
  "info": "",
  "buttons": {
    "action": "Вход / Регистрация",
    "back": "Назад към вход",
    "changeLanguage": "Смяна на езика",
    "start": "Старт"
  },
  "languageSelect": {
    "label": "Избор на език",
    "description": "Моля, изберете вашия език "
  }
}
export default {
  messages
}
<template>
  <div class="mb-4 bg-white mt-3">
    <MDBNavbar class="d-flex p-0">
      <MDBNavbarNav :class="`${menuOpen ? 'col-9' : 'col-3'} col-lg-3 d-flex flex-row`">
        <MDBNavbarItem linkClass="py-0 p-relative">
          <MDBNavbarToggler target="#navbarSupportedContent" @click="toggleMenu" />
          <div v-if="menuOpen" class="menu-wrap-bg" @click="toggleMenu"></div>
          <div v-if="menuOpen" @click="toggleMenu" class="menu-wrap">
            <MDBNavbarNav v-for="(item, id) in menuLinks" :key="`menu_${id}`" class="header_menu">
              <h4 class="fw-bold mb-0 font-25">
                <router-link class="text-black" :to="{ name: item.name }">
                  <div v-html="$t(`global.menu.${item.name}`)"></div>
                </router-link>
              </h4>
            </MDBNavbarNav>
            <MDBNavbarNav class="header_menu">
              <h4 class="fw-bold mb-0 text-black cursor--pointer font-25" @click="logout()">
                {{ $t("global.menu.logOut") }}
              </h4>
            </MDBNavbarNav>
          </div>
        </MDBNavbarItem>
        <MDBNavbarItem :class="`${menuOpen ? '' : 'd-none'} d-lg-block`">
          <router-link :to="{ name: 'dashboard' }" @click="() => (menuOpen = false)">
            <img class="menu-logo" :src="require('@/assets/img/logo.svg')" alt="logo" />
          </router-link>
        </MDBNavbarItem>
      </MDBNavbarNav>
      <MDBNavbarNav :class="`justify-content-center w-100 col flex-row d-lg-flex ${menuOpen ? 'd-none' : 'd-flex'
        }`">
        <MDBNavbarItem class="w-100 text-center font-14 font-md-16 font-lg-18 fw-bold">
          <span v-html="displayTitle || $t('global.nav.' + currentRoute)"></span>
        </MDBNavbarItem>
      </MDBNavbarNav>
      <MDBNavbarNav class="right-menu col-3 d-flex flex-row justify-content-end align-items-center pe-lg-3 pe-1">
        <LanguageSwitcher />
        <MDBNavbarItem v-if="!isStaff">
          <router-link :to="{ name: 'mailbox' }" class="nav-link px-lg-3 px-1 px-sm-2 py-0">
            <img :src="require('@/assets/img/icons/envelope.svg')" alt="envelope" />
            <MDBBadge v-if="messages > 0" color="danger" pill notification>
              {{ messages }}
            </MDBBadge>
          </router-link>
        </MDBNavbarItem>

        <MDBNavbarItem v-if="canSeeCart" class="menu-item-border">
          <router-link :to="{ name: 'warenkorb' }" class="nav-link px-lg-3 px-1 px-sm-2 py-0">
            <img :src="require('@/assets/img/icons/cart.svg')" alt="cart" />
            <MDBBadge v-if="cart && cart.items && cart.items.length > 0" color="danger" pill notification>
              {{ itemsAmount(cart.items) }}
            </MDBBadge>
          </router-link>
        </MDBNavbarItem>
        <MDBNavbarItem class="menu-item-rounded p-relative" linkClass="py-0">
          <MDBTooltip v-model="tooltip1" tag="a" direction="left">
            <template #reference>
              <img :src="require('@/assets/img/icons/question-mark.svg')" alt="help" class="cursor--pointer" />
            </template>
            <template #tip>
              <div class="tooltip-min-width" v-html="$t('global.tooltips.info')"></div>
            </template>
          </MDBTooltip>
        </MDBNavbarItem>
      </MDBNavbarNav>
    </MDBNavbar>
  </div>
</template>

<script setup>
import {
  MDBNavbar,
  MDBNavbarNav,
  MDBNavbarToggler,
  MDBNavbarItem,
  MDBBadge, MDBTooltip
} from "mdb-vue-ui-kit";
import { useRoute } from "vue-router";
import { computed, ref, watch, defineProps, onMounted } from "vue";
import { useStore } from "vuex";
import i18n from "@/plugins/i18n";
import TokenService from "@/services/storage.service";
import LanguageSwitcher from "@/components/LanguageSwitcher.vue";

const store = useStore();
const route = useRoute();
const storedLocale = computed(() => store.getters.getCurrentLocale);
const isLite = computed(() => store.getters.isLiteVersion);

const menuOpen = ref(false);
const tooltip1 = ref(false);

const cart = computed(() => store.getters.getCart);
const me = computed(() => store.getters.getMe);
const messages = computed(() => {
  return store.getters.getUnreadMessages;
});

const currentRoute = computed(() => {
  return route.name;
});

const itemsAmount = (data) => {
  if (data && data.length > 0) {
    return data.reduce((accumulator, object) => {
      return accumulator + object.quantity;
    }, 0);
  }
};

const props = defineProps({
  title: String,
});

const toggleMenu = () => (menuOpen.value = !menuOpen.value);
const textPage = computed(() => store.getters.getTextPage);
const hunter = computed(() => store.getters.getSingleHunter);
const mediaCategory = computed(() => store.getters.getSingleMediaCategory);
const files = computed(() => store.getters.getSinglePriceList);
const fetchedMenu = computed(() => store.getters.getFetchMenu);

const menuLinks = computed(() => {
  return store.getters.getMenu;
});

const displayTitle = computed(() => {
  if (props.title) {
    return props.title;
  } else if (textPage.value && currentRoute.value == "text_page") {
    return textPage.value.title[storedLocale.value];
  } else if (hunter.value && currentRoute.value == "hunter") {
    return `${i18n.global.t("pageName.hunterShop")} ${hunter.value.title}`;
  } else if (mediaCategory.value && currentRoute.value == "single_media") {
    return mediaCategory.value.name;
  } else if (files.value && currentRoute.value == "pkw") {
    return i18n.global.t(`productList.${route.params.slug}.title`);
  } else {
    return false;
  }
});

const isWholesaler = ref(false);
const isWholesalerStaff = ref(false);
const isStaff = ref(false);
const canSeeCart = ref(false);

watch(me, async (newVal) => {
  if (newVal && newVal.roles.find((item) => item.name == "wholesaler")) {
    isWholesaler.value = true;
  }
  if (newVal && newVal.roles.find((item) => item.name == "wholesalerStaff")) {
    isWholesalerStaff.value = true;
  }
  if (newVal && newVal.roles.find((item) => item.name == "staff")) {
    isStaff.value = true;
  }

  canSeeCart.value =
    !isWholesaler.value &&
    !isStaff.value &&
    !isWholesalerStaff.value &&
    TokenService.showElement("cartIcon.see");
});

const logout = async () => {
  await store.dispatch("logout");
};

onMounted(async () => {
  if (!fetchedMenu.value) {
    await store.dispatch("loadUnreadMessages");
    if (!isLite.value) {
      await store.dispatch("fetchUserShopCart");
    }

    store.dispatch('checkMenuFetched')
  }

  await store.dispatch("loadUserSlides");
})
</script>

<style scoped lang="scss">
@import "../../styles/_variables.scss";

.menu-logo {
  padding-left: 15px;
  margin-left: 5px;
  position: relative;
  top: 0px;
  border-left: 1px solid $borderLightGray;

  @media screen and (max-width: $lgBreakpoint) {
    padding-left: 0;
    margin-left: 0;
    border-left: 0px solid white;
    width: 130px;
    position: relative;
    z-index: 9999;
  }
}

.menu-item-rounded {
  background: $backgroundGray;
  border-radius: 50px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 0 5px 0.5rem;
  font-size: 12px;
  font-weight: bold;

  @media screen and (max-width: $lgBreakpoint) {
    background: transparent;
    margin-left: 0;
    margin-right: 0.5rem;
    width: 15px;
  }

  @media screen and (max-width: $smBreakpoint) {
    margin-right: 0.2rem;
    width: auto;
  }

  &--dark {
    background: $darkGray;
    color: white;

    span {
      color: white;
    }
  }
}

.menu-item-border {
  border-right: 1px solid $borderLightGray;
  margin-right: 0.5rem;

  @media screen and (max-width: $smBreakpoint) {
    margin-right: 5%;
    padding-right: 2%;
  }
}

.menu-wrap {
  position: absolute;
  left: 0;
  top: 42px;
  width: 512px;
  padding: 55px;
  background: white;
  z-index: 9999;
  box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.05);

  @media screen and (max-width: $lgBreakpoint) {
    padding: 2rem;
    max-width: 100%;
  }
}

.menu-wrap-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9998;
}

:deep(.header_menu.navbar-nav:not(:last-of-type)) {
  margin-bottom: 11px;
  padding-bottom: 10px;
  border-bottom: 1px solid $borderLightGray;
  width: 100%;
  max-width: 320px;
}

.tooltip-min-width {
  min-width: 100px;
}
</style>

const messages = {
  title: "Avaleht",
  welcomeMessage:
    'Tere tulemast BRIDGESTONE <span class="text-danger">PLUS</span>, %{first_name} %{last_name}',
  home: "Avaleht",
  search: "Otsi",
  sortBy: "Sorteeri",
  seeMore: "Vaata rohkem",
  bankstatement: {
    yourScore: "Teie praegune turunduspunktide arv:",
    lastUpdate: "Viimane värskendus:",
    link: "Punktide saldo ülevaade",
  },
};

export default {
  messages,
};

const messages = {
  title: "Mon profil",
  desc: "Bienvenue dans votre espace personnel. Ici, vous pouvez mettre à jour ou adapter vos<br/>données de référence et consulter votre boîte de réception.",
  descStaff:
    "Bienvenue dans votre espace personnel. Ici, vous pouvez mettre à jour ou adapter vos données de référence.",
  buttons: {
    showProfile: "Afficher le profil",
    userProfile: "Accéder à vos données de référence",
    mailbox: "Accéder à la boîte de récéption",
  },
  categories: {
    baseData: {
      title: "Données de référence",
      desc: "Complétez vos coordonnées personnelles et celles de votre entreprise pour une inscription réussie.",
    },
    mailbox: {
      desc: "Retrouvez les notifications importantes et nos actualités dans votre boîte de réception.",
    },
    bankstatement: {
      title: "Relevé de compte",
      desc: "Ici, vous pouvez consulter votre solde actuel de points de récompense et les mouvements de votre compte.",
    },
  },
  profile: {
    general: "Données personnelles Coordonnées de l'entreprise",
    title: "Données de référence",
    description:
      "Bienvenue dans votre espace personnel. Ici, vous pouvez mettre à jour ou adapter vos données de référence.",
    personalData: "Données personnelles",
    password: "Mot de passe",
    password1: "Mot de passe (au choix)*",
    password2: "Mot de passe (répétition)*",
    formOfAddress: "Titre de civilité",
    choose: "Veuillez choisir",
    mr: "Monsieur",
    mrs: "Madame",
    diverse: "Divers",
    firstname: "Prénom",
    surname: "Nom de famille",
    position: "Position dans l'entreprise",
    operatingData: "Coordonnées de l'entreprise",
    company: "Société",
    legalForm: "Structure juridique",
    name: "Nom",
    streetHouseNr: "Numéro et nom de rue",
    street: "Rue",
    postcode: "CODE POSTAL",
    location: "Ville",
    country: "Pays",
    de: "Allemagne",
    at: "Autriche",
    ch: "Suisse",
    nl: "Pays-Bas",
    it: "Italie",
    language: "Langue",
    french: "Français",
    italian: "Italien",
    german: "Allemagne",
    nederland: "Pays-Bas",
    openingHours: "Heures d'ouverture & contact",
    phone: "Téléphone",
    website: "Site web",
    operatingIndicators: "Chiffres clés de l'exploitation",
    tireSale: "Chiffre d'affaires pneus par an env. en pièces",
    tireSaleLabel: "Vendre des pneus en grande quantité",
    inches: "Dont ≥ 18 pouces (en %)",
    inchesLabel: "Dont 18 pouces et plus en %",
    segmentSale:
      "Ventes de pneus par segment (part Budget / Mid / Premium en %)",
    premBudget: "Premium",
    midBudget: "Mid",
    budBudget: "Budget",
    distribution: "RÉPARTITION ÉTÉ / HIVER / TOUTES LES SAISONS (en %)",
    tyreSizes: "Nombre de dépôts par saison (jeux de pneus)",
    diffDeliveryAddres: "Ajouter une adresse de livraison",
    yourEmailAddress: "votre adresse e-mail*.",
    clientNr: "Numéros de clients",
    clientNrSingular: "Numéro de client",
    clientNrContent:
      "<span class='text-danger fw-bold'>IMPORTANT - VEUILLEZ LIRE :</span><br /><br /> Pour l'attribution de vos commandes de pneus chez un grossiste et / ou chez Bridgestone, il est impératif que <b>vous indiquiez vos numéros de client respectifs.</b><br/><br/>Si vous <b>avez plusieurs numéros de client chez un grossiste et / ou chez Bridgestone,</b> par exemple pour votre magasin principal et vos filiales, indiquez d'abord le numéro de client du magasin principal.<br/><br/><b>Les ventes des autres numéros de client seront ensuite attribuées à votre magasin principal.</b>",
    supplySource: "Source d'approvisionnement",
    email: "Addresse e-mail",
    staffEmail: "Adresse e-mail du participant",
    registered: "Enregistré le",
    registeredAt: "Enregistré le",
    lastLogIn: "Dernière connexion",
    details: "Détails",
    inDetail: "{name} en détail",
    bsClientNr: "Votre numéro de client Bridgestone",
    bftClientNr: "Votre numéro de client Best4Tires",
    acceptTerms:
      "Accepter les conditions générales et les conditions de participation.",
    newCustomerNumbersInfo:
      "<span class='text-danger fw-bold'>IMPORTANT - VEUILLEZ LIRE :</span><br />Lors de la première saisie du numéro de client, vos données sont vérifiées quant à leur exactitude et à nos critères de distribution. La décision d'activer le(s) numéro(s) de client et de le(s) relier à votre profil peut prendre jusqu'à 5 jours ouvrables.",
    selectWholeSaler: "chez le fournisseur (veuillez choisir)",
    cooperation: "MEMBRE / COOPÉRATION",
    error100: "La somme des valeurs doit être égale à 100%.",
    errorRange: "La valeur doit être comprise entre 0 et 100",
    btw_number_label: "le numéro d'identification à la TVA de votre entreprise",
    btw_number: "Numéro d'identification de la taxe sur le chiffre d'affaires",
    mobile: "NUMÉRO DE TÉLÉPHONE",
    mobile_label: "Votre numéro de téléphone (portable)",
    iban_number: "Numéro IBAN de VOTRE ENTREPRISE",
    iban_number_label: "Numéro IBAN",
    yourCsNr: "Vos numéros de client",
    yourLastName: "Votre nom de famille",
    positionLabel: "Sélectionner une position",
    yourFirstName: "Votre prénom",
    companyLabel: "Société",
    numberOfSetsOfSeasonalTiresBudget: "Stockage par an",
    contactInfo: "Si vous avez des questions, veuillez contacter:",
    addAnotherCsNumber: "ajouter un autre numéro de client bridgestone",
    activityAndProfile: "SECTEUR D'ACTIVITÉ ET PROFIL",
  },
};

export default {
  messages,
};

import { useStore } from "vuex";

export default {
  methods: {
    hasTenantPermission(permission) {
      const store = useStore(); // Access the Vuex store
      // Return a computed ref that dynamically checks the permission
      return store.getters.getTenantPermissions().includes(permission)
    }
  }
};
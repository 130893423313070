const messages = {
  title: "Startseite",
  welcomeMessage:
    'Willkommen bei BRIDGESTONE <span class="text-danger">PLUS</span>, %{first_name} %{last_name}',
  home: "Startseite",
  search: "Suche",
  sortBy: "Sortieren nach",
  seeMore: "Alle anzeigen",
  bankstatement: {
    yourScore: "Ihre aktuelle Anzahl an Marketingpunkten:",
    lastUpdate: "Letztes Update:",
    link: "Übersicht über den Punktestand",
  },
};

export default {
  messages,
};

const messages = {
  title: "Mein persönlicher Kontoauszug",
  desc: "Hier können Sie Ihren persönlichen Bridgestone Plus Kontoauszug zu Ihren erhaltenen und verwendeten Prämienpunkten einsehen. Bitte beachten Sie, dass Punktegutschriften für Ihre Reifenorder je nach Bezugsquelle erst nach bis zu 6 Wochen nach Orderzeitpunkt gutgeschrieben werden.",
  score: "Punktestand",
  regularPoints: "Reguläre Punkte",
  boosterPoints: "Booster Punkte",
  date: "Datum",
  supplySourceReason: "Bezugsquelle / Grund",
  article: "Artikel",
  brand: "Marke",
  product: "Produkt",
  type: "Typ",
  inch: "Zoll",
  season: "Saison",
  amount: "Menge",
  standardPoints: "Punkte",
  boosterProduct: "Booster Produkt",
  boosterSeason: "Booster Saison",
  otherActions: "Sonstige Handlung bzw. Ursache",
  totalPoints: "Punkte Gesamt",
  income: "Zugänge",
  spending: "Abgänge",
  lastMonth: "Stand Ende",
  loadMore: "Weitere 20 umsätze laden",
  typeFilter: "Filtern nach Bezugsquelle",
  dateFilter: "Filtern nach Datumsbereich",
  customer_number: "Kdnr.",
  currentPoints: "Aktuelle Punkte",
  calculationDate: "Berechnungsdatum",
  positivePoints: "Positive Punkte",
  negativePoints: "Bereits ausgegeben",
  tyresAmount: "Reifen Menge jährlich",
  pointsTotal: "Gesamtpunktzahl",
  currentPointState: "Aktueller Punktestand",
  currentKickbackState: "Aktueller kickback",
};
export default {
  messages,
};

import ApiService from "./api.service";

const ContactService = {
  async pickupLocations() {
    try {
      return await ApiService.get("pickupLocations");
    } catch (error) {
      return error.response;
    }
  },
  async store(data) {
    try {
      return await ApiService.post("complaints", data);
    } catch (e) {
      console.log(e);
    }
  },
  async damageTypes() {
    try {
      return await ApiService.get("damageTypes");
    } catch (error) {
      return error.response;
    }
  },
  async tyrePositions() {
    try {
      return await ApiService.get("tyrePositions");
    } catch (error) {
      return error.response;
    }
  },
  async vehicleTyres() {
    try {
      return await ApiService.get("vehicleTyres");
    } catch (error) {
      return error.response;
    }
  },
  async tyreWears() {
    try {
      return await ApiService.get("tyreWears");
    } catch (error) {
      return error.response;
    }
  },
  async salutations() {
    try {
      return await ApiService.get("salutations");
    } catch (error) {
      return error.response;
    }
  },
  async storeConfirmation(data) {
    try {
      return await ApiService.post(`complaints/file/${data.id}`, data.form);
    } catch (e) {
      console.log(e);
    }
  },
  async contactServices() {
    try {
      return await ApiService.get("contact-service-info");
    } catch (error) {
      return error.response;
    }
  },
  async showDescription() {
    try {
      return await ApiService.get(`contact-service-description`);
    } catch (error) {
      return error.response;
    }
  },
};

export { ContactService };

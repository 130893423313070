const messages = {
  title: "Mājas",
  welcomeMessage:
    'Laipni lūdzam BRIDGESTONE <span class="text-danger">PLUS</span>, %{first_name} %{last_name}',
  home: "Mājas",
  search: "Meklēt",
  sortBy: "Kārtot pēc",
  seeMore: "Skatīt vairāk",
  bankstatement: {
    yourScore: "Jūsu pašreizējais mārketinga punktu skaits:",
    lastUpdate: "Jaunākais atjauninājums:",
    link: "Punktu bilances pārskats",
  },
};

export default {
  messages,
};

<template>
  <MDBCardBody class="card--height">
    <MDBCardTitle class="text-start font-35 mb-3">
      <div v-html="$t('login.title')"></div>
    </MDBCardTitle>
    <template v-if="currentLocale === 'nl-BE'">
      <p class="font-16">
        <b>Heeft u al een account?</b><br/>Log in met uw e-mailadres en wachtwoord.<br/><br/><b>Bent u nieuw hier?</b><br/>Als u een nieuwe gebruiker bent, registreer u dan.<br/><br/><b>Heb je een vraag?</b><br/>Schrijf ons op plusbelux@bridgestone.eu
      </p>
    </template>
    <template v-else-if="currentLocale === 'fr-BE'">
      <p class="font-16">
        <b>Vous avez déjà un compte?</b><br/>Connectez-vous à l'aide de votre adresse e-mail et de votre mot de passe.<br/><br/><b>Vous êtes nouveau ici?</b><br/>Cliquez ci-dessous pour créer votre compte.<br/><br/><b>Une question?</b><br/>Veuillez nous écrire à plusbelux@bridgestone.eu
      </p>
    </template>
    <template v-else>
      <MDBCardText class="pb-0">
        <p class="font-16" v-html="$t('login.description')"></p>
        <p class="font-16" v-html="$t('login.info')"></p>
      </MDBCardText>
    </template>
    <ButtonMediumVue
        className="btn btn-danger btn-lg d-flex justify-content-center align-items-center font-12 btn-position ripple-surface"
        div-class-name="text-white fw-bold margin-reset font-16"
        property1="default"
        :text="$t('login.buttons.action')"
        @click="handleSsoRedirection"
        :disableState="loading"
    />
    <!-- Trigger Language Selector -->
    <button
        v-if="showLanguageButton"
        class="btn btn-primary mt-3"
        @click="$emit('toggleLanguageSelector')"
    >
      {{ $t("login.buttons.changeLanguage") }}
    </button>
  </MDBCardBody>
</template>

<script setup>
import { computed, defineProps } from "vue";
import {
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
} from "mdb-vue-ui-kit";
import ButtonMediumVue from "@/components/General/Buttons/ButtonMedium.vue";
// Props
const props = defineProps({
  loading: Boolean,
  handleSsoRedirection: Function,
  tenantConfig: Object,
  availableLocales: { type: Array, required: true },
});

// Computed property to check if language button should be displayed
const showLanguageButton = computed(() => props.availableLocales.length > 1);
</script>

<style scoped>
.btn-primary {
  margin-top: 20px;
}
</style>

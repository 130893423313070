const messages = {
  title: "Iesūtne",
  desc: "Šeit varat atrast svarīgus paziņojumus un jaunumus.",
  newMessages: "Jauni ziņojumi jūsu iesūtnē",
  link: "Skatīt ziņojumus",
  showMessages: "Rādīt ziņojumus",
  message: {
    sender: "Nosūtītājs",
    thread: "Temats",
    date: "Datums",
  },
  sender: {
    system: "BRIDGESTONE PLUS",
  },
  back: "Atpaka",
  time: {
    secondsAgo: "{time} sekundžių atgal",
    minutesAgo: "{time} minučių atgal",
    hoursAgo: "{time} valandų atgal",
    daysAgo: "{time} dienų atgal",
  },
};

export default {
  messages,
};

const messages = {
  title: "Начало",
  welcomeMessage:
    'Добре дошли в BRIDGESTONE <span class="text-danger">PLUS</span>, %{first_name} %{last_name}',
  home: "Начало",
  search: "Търсене",
  sortBy: "Сортиране по",
  seeMore: "Виж повече",
  bankstatement: {
    yourScore: "Вашият текущ брой маркетингови точки:",
    lastUpdate: "Последна актуализация:",
    link: "Преглед на баланса на точките",
  },
};
export default {
  messages,
};

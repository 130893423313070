const messages = {
  title: "Vítejte v Bridgestone <span class='text-danger'>Plus</span>",
  termsAndConditions: "Podmínky a ujednání",
  accept: "Přijmout",
  decline: "Odmítnout",
  dataModal:
    "Abychom mohli nabídku přizpůsobit vašim zájmům, prosíme vás o krátké uvedení vašich produktových zájmů nebo obchodních oblastí.",
  scrollToAccept:
    "Pro pokračování prosím přejděte dolů a přijměte naše podmínky",
  welcomeMessage: "Vítejte v Bridgestone Plus",
  tailorOffer:
    "Abychom mohli nabídku přizpůsobit vašim zájmům, prosíme vás o krátké uvedení vašich produktových zájmů nebo obchodních oblastí.",
  selectAreas: "Prosím, vyberte z následujících oblastí",
  multipleAnswers: "(může být více odpovědí):",
  personalPreferences: "Osobní preference",
  carTyres: "Pneumatiky pro osobní auta, SUV a užitková vozidla",
  motorcyclesTyres: "Pneumatiky pro motocykly",
  truckBusTyres: "Pneumatiky pro nákladní auta a autobusy",
  agricultureTyres: "Pneumatiky pro zemědělská vozidla",
  otrTyres: "OTR pneumatiky",
  save: "Uložit",
  businessRelationship: "Jaký je váš obchodní vztah s Bridgestone?",
  selectRelationship: "Prosím, vyberte*:",
  tradingPartner: "Jsem obchodní partner, který...",
  tradingPartnerDirect: "... objednává přímo od Bridgestone",
  tradingPartnerIndirect:
    "... objednává nepřímo (například prostřednictvím velkoobchodu)",
  tradingPartnerBoth: "... objednává jak přímo, tak nepřímo",
  fleetResponsibleDirect: "Jsem správce flotily, který...",
  fleetResponsibleIndirect:
    "... objednává nepřímo (prostřednictvím obchodníka nebo velkoobchodu)",
  fleetResponsibleBoth: "... objednává jak přímo, tak nepřímo",
  noBusinessRelationship:
    "<b>Nemám žádný obchodní vztah s Bridgestone</b> (ani přímo, ani nepřímo)",
  yourCustomerNumber: "Vaše zákaznické číslo Bridgestone*:",
  enterCustomerNumber: "Zadejte své zákaznické číslo",
  waitPersonalisedView:
    "Prosím, čekejte chvíli, vaše personalizované zobrazení BRIDGESTONE PLUS bude generováno.",
  personalised: "PERSONALIZOVANÉ",
  plusInfo: "Zde najdete informace o vašem účtu Plus a vaší schránce Plus.",
  continue: "Pokračovat",
  skip: "Přeskočit",
  oneOfTwo: "1 z 2",
  openApps:
    "Aplikace, ke kterým jste již registrováni, můžete otevřít přímo. Můžete také požádat o přístup k dalším aplikacím, které by pro vás mohly být relevantní.",
  requiredFields:
    "Prosím, vyplňte povinná pole: Oslovení, Jméno, Příjmení, Název společnosti",
  stage: "krok",
  chooseOne: "(Prosím, zaškrtněte alespoň 1 políčko)",
  required: "*povinná pole",
  lengthCsNumber: "Délka zákaznického čísla: 6–10 číslic",
};

export default {
  messages,
};

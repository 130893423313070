const messages = {
  title: "Strona główna",
  welcomeMessage:
    'Witamy w BRIDGESTONE <span class="text-danger">PLUS</span>, %{first_name} %{last_name}',
  home: "Strona główna",
  search: "Wyszukiwanie",
  sortBy: "Sortuj według",
  seeMore: "Zobacz więcej",
  bankstatement: {
    yourScore: "Liczba punktów:",
    lastUpdate: "Ostatnia aktualizacja:",
    link: "Zobacz saldo",
  },
};

export default {
  messages,
};

const messages = {
  title: "Mijn persoonlijke rekeningoverzicht",
  desc: "Hier kunt u uw persoonlijke Bridgestone Plus rekeningoverzicht bekijken met uw verdiende en gebruikte bonuspunten. Houd er rekening mee dat het bijschrijven van punten voor uw bandenbestellingen tot wel 6 weken kan duren, afhankelijk van de bron.",
  score: "Punten saldo",
  regularPoints: "Standaard punten",
  boosterPoints: "Bonuspunten",
  date: "Datum",
  supplySourceReason: "Bron/Aankoop reden",
  article: "Artikel",
  brand: "Merk",
  product: "Product",
  type: "Type",
  inch: "Inch",
  season: "Seizoen",
  amount: "Aantal",
  standardPoints: "Punten",
  boosterProduct: "Bonusproduct",
  boosterSeason: "Bonusseizoen",
  otherActions: "Andere acties of redenen",
  totalPoints: "Totaal aantal punten",
  income: "Inkomsten",
  spending: "Uitgaven",
  lastMonth: "Slotstand",
  loadMore: "Laad nog 20 transacties",
  typeFilter: "Filter op bron",
  dateFilter: "Filter op periode",
  customer_number: "Klantnummer",
  currentPoints: "Huidige punten",
  calculationDate: "Berekeningsdatum",
  positivePoints: "Positieve punten",
  negativePoints: "Al uitgegeven",
  tyresAmount: "Aantal banden per jaar",
  pointsTotal: "Totaal aantal punten",
  currentPointState: "Huidige puntensaldo",
  currentKickbackState: "Huidige terugbetaling", // "Kickback" kan worden vertaald als "terugbetaling"
};
export default {
  messages,
};

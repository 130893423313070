<template>
  <Loader :show="loading" v-if="loading" />
  <div v-else>
    <MDBRow>
      <MDBCol md="12" cols="12">
        <MDBTabs v-model="activeTabId1" v-if="content">
          <!-- Tabs navs -->
          <MDBTabNav v-if="tabs.length > 1">
            <MDBTabItem v-for="(item, index) in tabs" :tabId="`ex1-${index}`" :href="`ex1-${index}`"
              class="contactTabs text-capitalize" :key="index">{{ $t(`contactService.teams.${item}`) }}
            </MDBTabItem>
          </MDBTabNav>

          <MDBTabContent>
            <MDBTabPane v-for="(contentItem, key, index) in content" :tabId="`ex1-${index}`" :key="index">
              <MDBRow class="pb-4" v-for="(item, itemIndex) in contentItem" :key="itemIndex"
                :class="`${!item.is_main ? 'd-none' : ''}`">
                <MDBCol md="8" cols="12">
                  <div class="bg-white mainBoxContainer px-4 py-3">
                    <h5 class="pb-2">{{ $t("contactService.subtitle") }}</h5>

                    <MDBRow>
                      <MDBCol md="6" cols="12">
                        <div class="d-flex flex-column justify-content-end h-100">
                          <div class="pt-2" v-html="mapData(item).name">
                          </div>
                          <div v-html="mapData(item).description">
                          </div>

                          <div class="d-grid gap-2 pt-4">
                            <MDBBtn type="button" color="danger" class="mt-4" @click="openEmail(mapData(item).link)">{{
                              $t("global.buttons.makeContact") }}</MDBBtn>
                          </div>
                        </div>
                      </MDBCol>
                      <MDBCol md="6" cols="12" class="d-flex justify-content-end">
                        <img class="cto" :src="mapData(item).image" alt="" />
                      </MDBCol>
                    </MDBRow>
                  </div>
                </MDBCol>
                <MDBCol md="4" cols="12" v-if="description && description[currentLocale]">
                  <div class="bg-white px-4 py-3 h-100">
                    <div class="d-block" v-html="description[currentLocale]"></div>
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow v-if="content">
                <MDBCol cols="12" lg="6" xl="4" class="mb-4" v-for="(item, itemIndex) in contentItem" :key="itemIndex"
                  :class="`${item.is_main ? 'd-none' : ''}`">
                  <Card :data="mapData(item)" :linkText="mapData(item).linkText" :simpleLink="true" target="_blank"
                    class="h-100" />
                </MDBCol>
              </MDBRow>
            </MDBTabPane>
          </MDBTabContent>
        </MDBTabs>
      </MDBCol>
      <MDBCol cols="12" v-if="description && description[currentLocale] && (Object.keys(content).length == 0)">
        <div class="bg-white px-4 py-3 h-100">
          <div class="d-block" v-html="description[currentLocale]"></div>
        </div>
      </MDBCol>
    </MDBRow>
  </div>
</template>

<script setup>
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import Loader from "@/components/General/Loader/index.vue";
import Card from "@/components/General/Card/index.vue";

import { MDBRow, MDBCol, MDBBtn } from "mdb-vue-ui-kit";
import { MDBTabs, MDBTabNav, MDBTabItem, MDBTabContent, MDBTabPane } from "mdb-vue-ui-kit";
import { computed, ref, onMounted } from "vue";
import { ContactService } from "@/services/contact.service";

const currentLocale = localStorage.getItem("currentLocale");

const store = useStore();

store.dispatch("loadContacts");
const { t } = useI18n() // Import the translation function from i18n
const activeTabId1 = ref('ex1-0');
const description = ref(null)
const tabs = computed(() => Object.keys(store.getters.getContactService))
const content = computed(() => store.getters.getContactService)
const loading = computed(() => store.getters.getPending);

const mapData = (item) => {
  return {
    name: `<div class="sub-title-contact">${Object.keys(item.job_position).length ? item.job_position[currentLocale] : ''}</div><h2>${item.first_name} ${item.last_name}</h2>`,
    link: item.email,
    description: `<span class="font-12 font-xxl-16"><b>E-Mail:</b> ${item.email}<br/>
<b>${t('profile.phone')}:</b> ${item.phone}</span>`,
    image: item.image,
    linkText: t("global.buttons.makeContact"),
    is_main: item.is_main

  }
}

onMounted(async () => {
  const response = await ContactService.showDescription()
  if (response.data) {
    description.value = response.data.description
  }
})
</script>

<style scoped>
.mainBoxContainer {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
</style>
const messages = {
    "titleColorRev": "истина",
    "buttons": {
        "pending": "Изчаква",
        "open": "Отворено",
        "requestAccess": "Заявете достъп"
    },
    "my": "Моите приложения",
    "directRoute": "Вашите директни връзки към уеб приложенията на Bridgestone.",
    "welcomeMessage": "Добре дошли в BRIDGESTONE <span class=\"text-danger\">PLUS</span>, %{first_name} %{last_name}",
    "showProfile": "Покажи профил",
    "newMessages": "Ново съобщение във входящата поща",
    "myApps": "Моите приложения",
    "allApps": "Всички приложения",
    "myAppsDescription": "Всички уеб приложения на Bridgestone, в които сте регистрирани.",
    "allAppsDescription": "Всички уеб приложения на Bridgestone, които може да са релевантни за вас.",
    "myProfile": {
        "welcomeMessage": "Добре дошли във вашата лична зона. Тук можете да актуализирате или коригирате вашите данни и да прегледате входящата си поща.",
        "masterData": "Основни данни",
        "completeDetails": "Попълнете вашите лични и фирмени данни, за да осигурите коректна регистрация.",
        "goToMasterData": "Отидете на моите данни",
        "goToInbox": "Отидете във входящата поща"
    },
    "masterData": {
        "welcomeMessage": "Добре дошли във вашата лична зона. Тук можете да актуализирате или коригирате вашите основни данни.",
        "personalDetails": "Лични данни",
        "companyDetails": "Фирмени данни",
        "emailAddress": "Имейл адрес",
        "title": "Обръщение",
        "surname": "Фамилия",
        "firstName": "Име",
        "jobPosition": "Позиция",
        "streetAndHouseNumber": "Адрес на фирмата",
        "postalCode": "Пощенски код",
        "city": "Град",
        "country": "Държава",
        "legalStructure": "Правна структура",
        "customerNumber": "Bridgestone клиентски номер",
        "importantPleaseRead": "Важно, моля прочетете",
        "customerNumberInfo": "За да можем правилно да присвоим вашите поръчки на гуми към различни доставчици, е важно да въведете вашия клиентски номер.",
        "multipleCustomerNumbers": "Ако имате няколко клиентски номера при доставчик, например за вашата главна компания и филиали, първо въведете клиентския номер на главната компания.",
        "salesAssignment": "Продажбите, свързани с допълнителни клиентски номера, ще бъдат присвоени на вашата главна компания.",
        "supplier": "Доставчик",
        "addAnotherCustomerNumber": "Добавете клиентски номер",
        "save": "Запази",
        "delete": "Изтрий"
    },
    "inbox": {
        "welcomeMessage": "Тук можете да намерите известия и важни новини.",
        "sender": "Изпращач",
        "subject": "Тема",
        "date": "Дата",
        "formConsulting": "Формуляр получен - Благодарим, че го попълнихте",
        "back": "Назад"
    },
    "legalAndContact": {
        "cookieSettings": "Настройки за бисквитки",
        "dataPolicy": "Политика за данни",
        "termsOfUse": "Условия за ползване",
        "helpAndContact": "Помощ и контакт"
    },
    "logInSignUp": {
        "logIn": "Вход",
        "signUp": "Регистрация",
        "logInMessage": "Влезте с вашия имейл адрес и парола.",
        "signUpMessage": "Ако сте нов потребител, моля регистрирайте се."
    }
}
export default {
    messages
}
const messages = {
  makeChoice: "Pasirinkite čia.",
  seeAll: "Rodyti viską",
  clear: "Išvalyti",
  back: "Atgal",
  save: "Išsaugoti",
  close: "Uždaryti",
  of: "Iš",
  to: "į",
  copyrights: "© {date} Bridgestone Solutions. Visos teisės saugomos.",
  pageNotFound: "Puslapis nerastas",
  accessDenied: "Prieiga uždrausta",
  search: "Ieškoti",
  buttons: {
    export: "Eksportuoti",
    delete: "Ištrinti",
    save: "Išsaugoti",
    cancel: "Atšaukti",
    next: "Kitas",
    back: "Atgal",
    skip: "Praleisti",
    send: "Siųsti",
    readNow: "Skaitykite dabar",
    pointsList: "Taškų sąrašas",
    clear: "Išvalyti",
    more: "Di più",
    close: "Uždaryti",
  },
  tooltips: {
    info: "Reikia pagalbos? +371 26 12 67 71",
  },
  confirmation: {
    header: "Reikalingas patvirtinimas",
    message: "Ar tikrai norite ištrinti šį įrašą?",
  },
  nav: {
    dashboard: "Pagrindinis",
    account: "Mano Profilis",
    mailbox: "Pašto dėžutė",
    singleMailbox: "Žinutės",
    stammdaten: "Pagrindiniai duomenys",
    news_feeds: "Naujienos",
    pointsOverview: "Points overview",
    about: "Apie BS Plus",
    service_number: "Contact service",
  },
  menu: {
    account: "Mano Profilis",
    plus: 'Apie Bridgestone <span class="text-danger">Plus</span>',
    salesSupport: "Pardavimo palaikymas",
    mailbox: "Pašto dėžutė",
    about: "Apie BS Plus",
    trainingAndCourses: "Mokymai ir kursai",
    externalTraining: "Išoriniai mokymai",
    productInfo: "Produkto informacija",
    productBrochures: "Produktų brošiūros",
    priceLists: "Kainoraščiai",
    price: "Kaina",
    complaintForm: "Skundų forma",
    sales_support: "Pardavimo palaikymas",
    contact_service: "Kontaktai ir aptarnavimas",
    logOut: "Atsijungti",
    news: "Naujienos",
    news_feeds: "Naujienos",
    service_number: "Contact service",
  },
  validation: {
    requiredField: "Campo obbligatorio",
  },
};

export default {
  messages,
};

const messages = {
  title: "Benvenuti Bridgestone <span class='text-danger'>PLUS</span>",
  termsAndConditions: "Termini e condizioni",
  accept: "Accetta",
  decline: "Rifiuta",
  dataModal:
    "Per adattare questa offerta ai tuoi interessi, ti chiediamo gentilmente di specificare brevemente i tuoi interessi sui prodotti o i settori di attività.",
  scrollToAccept:
    "Scorri verso il basso per accettare i nostri termini e procedere",
  welcomeMessage: "Benvenuto in Bridgestone Plus",
  tailorOffer:
    "Per adattare questa offerta ai tuoi interessi, ti chiediamo gentilmente di specificare brevemente i tuoi interessi sui prodotti o i settori di attività.",
  selectAreas: "Seleziona per favore tra le seguenti aree",
  multipleAnswers: "(sono possibili più risposte):",
  personalPreferences: "Preferenze personali",
  carTyres: "Pneumatici per auto, SUV e veicoli commerciali",
  motorcyclesTyres: "Pneumatici per motociclette",
  truckBusTyres: "Pneumatici per autocarrio e autobus",
  agricultureTyres: "Pneumatici per veicoli agricoli",
  otrTyres: "Pneumatici OTR",
  save: "Salva",
  businessRelationship: "Qual è la tua relazione commerciale con Bridgestone?",
  selectRelationship: "Seleziona per favore*:",
  tradingPartner: "Sono un partner commerciale che...",
  tradingPartnerDirect: "... ordina direttamente da Bridgestone",
  tradingPartnerIndirect: "... ordina indirettamente (tramite grossisti, ecc.)",
  tradingPartnerBoth: "... ordina sia direttamente che indirettamente",
  fleetResponsibleDirect: "Sono un responsabile della flotta che...",
  fleetResponsibleIndirect:
    "... ordina indirettamente (tramite commercio o all'ingrosso)",
  fleetResponsibleBoth: "... ordina sia direttamente che indirettamente",
  noBusinessRelationship:
    "<b>Non ho alcuna relazione commerciale con Bridgestone</b> (né diretta né indiretta)",
  yourCustomerNumber: "Il tuo numero cliente Bridgestone*:",
  enterCustomerNumber: "Inserisci il tuo numero cliente",
  waitPersonalisedView:
    "Attendi un momento, la tua vista personalizzata di BRIDGESTONE PLUS sarà generata.",
  personalised: "PERSONALIZZATO",
  plusInfo:
    "Qui puoi trovare informazioni sul tuo account Plus e sulla tua posta in arrivo Plus.",
  continue: "Continua",
  skip: "Salta",
  oneOfTwo: "1 di 2",
  openApps:
    "Puoi aprire direttamente le app per le quali sei già registrato. Puoi anche richiedere l'accesso ad altre app che potrebbero essere rilevanti per te.",
  requiredFields:
    "Per favore, completa i campi obbligatori: Titolo, Nome, Cognome",
  stage: "Fase",
  chooseOne: "(Seleziona almeno 1 casella)",
  required: "*campi obbligatori",
  lengthCsNumber: "Lunghezza del numero cliente: 6-10 cifre",
};

export default {
  messages,
};

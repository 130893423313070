'<template>
  <MDBContainer>
    <Menu />
    <MDBRow>
      <MDBCol col="lg-3" :class="`column-order justify-content-start ${props.switchOrderMobile ? 'order-lg-1 order-2 mt-lg-0 mt-5' : null
        }`">
        <LeftColumnLV />
      </MDBCol>
      <MDBCol col="lg-9" :class="`${props.switchOrderMobile ? 'order-lg-2 order-1' : null}`">
        <props.content />
      </MDBCol>
    </MDBRow>
    <MDBRow class="mt-2">
      <MDBCol col="lg-3">
        <MDBRow v-if="route.name == 'dashboard'">
          <MDBCol>
            <h5>&nbsp;</h5>
          </MDBCol>
        </MDBRow>
        <PointBox />
      </MDBCol>
      <MDBCol col="lg-9">
        <props.data />
      </MDBCol>
    </MDBRow>
    <AppsPanel v-if="hasTenantPermission('my-bs-app')" />

    <Footer />
  </MDBContainer>

  <!-- <MDBModal v-model="showDisclaimer" tabindex="-1" centered size="xl" @hide="closeDisclaimer" class="bg-modal">
    <Marketing @close="(e) => closeDisclaimer()" />
  </MDBModal> -->

  <MDBModal v-model="showFirstInitialStep" tabindex="-1" centered size="xl" class="bg-modal">
    <PartOne />
  </MDBModal>
  <MDBModal v-model="showSecondInitialStep" tabindex="-1" centered size="xl" class="bg-modal">
    <PartTwo />
  </MDBModal>
  <MDBModal v-model="showThirdInitialStep" tabindex="-1" centered size="xl" class="bg-modal">
    <PartThree />
  </MDBModal>
</template>

<script setup>
import { MDBCol, MDBRow, MDBContainer, MDBModal } from "mdb-vue-ui-kit";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import Menu from "@/components/Layout/Menu.vue";
import Footer from "@/components/Layout/Footer.vue";
import LeftColumnLV from "@/components/Layout/LeftColumnLV.vue";
// import Marketing from "@/components/Intro/Marketing.vue";
import AppsPanel from "@/components/AppsPanel/AppsPanel.vue";
import PartOne from "@/components/Intro/InitialSurvey/PartOne.vue";
import PartTwo from "@/components/Intro/InitialSurvey/PartTwo.vue";
import PartThree from "@/components/Intro/InitialSurvey/PartThree.vue";
import PointBox from "@/components/Dashboard/PointBox.vue";
import { defineProps, ref, watch, computed, onMounted } from "vue";
const store = useStore();
const route = useRoute();

// Create a reactive computed property for permissions
const props = defineProps({
  content: Object,
  switchOrderMobile: Boolean,
  data: Object,
});

const user = computed(() => store.getters.getMe);
const initialStep = computed(() => store.getters.getInitialSurveyStep);
const meIsPending = computed(() => store.getters.getIsMePending);

const isWholesalerStaff = ref(false);
const isWholesaler = ref(false);
watch(user, async (newVal) => {
  if (newVal && newVal.roles.find((item) => item.name == "wholesaler")) {
    isWholesaler.value = true;
  }
  if (newVal && newVal.roles.find((item) => item.name == "wholesalerStaff")) {
    isWholesalerStaff.value = true;
  }
});

const showFirstInitialStep = computed(() => {
  return initialStep.value == 1
});

const showSecondInitialStep = computed(() => {
  return initialStep.value == 2
}
);

const showThirdInitialStep = computed(() => {
  return initialStep.value == 3
}
);

onMounted(async () => {
  if (!meIsPending.value) {
    await store.dispatch("me");
  }
});
</script>

<style lang="scss" scoped>
@import "@/styles/_variables.scss";

.bg-modal {
  background-color: rgba(0, 0, 0, 0.8) !important;
}
</style>
'
const messages = {
  title: "Mano asmeninė sąskaitos išrašas",
  desc: "Čia galite peržiūrėti savo asmeninį Bridgestone Plus sąskaitos išrašą su uždirbtais ir panaudotais premijos taškais. Atkreipkite dėmesį, kad už padangų užsakymus skirti taškai gali būti įskaityti per 6 savaites, priklausomai nuo šaltinio.",
  score: "Taškų balansas",
  regularPoints: "Įprasti taškai",
  boosterPoints: "Premijos taškai",
  date: "Data",
  supplySourceReason: "Šaltinis/Pirkimo priežastis",
  article: "Prekė",
  brand: "Prekės ženklas",
  product: "Produktas",
  type: "Tipas",
  inch: "Colio",
  season: "Sezonas",
  amount: "Kiekis",
  standardPoints: "Taškai",
  boosterProduct: "Premijos produktas",
  boosterSeason: "Premijos sezonas",
  otherActions: "Kiti veiksmai arba priežastys",
  totalPoints: "Iš viso taškų",
  income: "Pajamos",
  spending: "Išlaidos",
  lastMonth: "Baigiamasis balansas",
  loadMore: "Įkelti dar 20 operacijų",
  typeFilter: "Filtruoti pagal šaltinį",
  dateFilter: "Filtruoti pagal laikotarpį",
  customer_number: "Kliento numeris",
  currentPoints: "Dabartiniai taškai",
  calculationDate: "Skaičiavimo data",
  positivePoints: "Pozityvūs taškai",
  negativePoints: "Jau išleisti",
  tyresAmount: "Padangų skaičius per metus",
  pointsTotal: "Iš viso taškų",
  currentPointState: "Dabartinė taškų būsena",
  currentKickbackState: "Dabartinė grąžinamoji išmoka", // "Kickback" galima versti kaip "grąžinamoji išmoka"
};
export default {
  messages,
};

const messages = {
  title: "Oma henkilökohtainen tilinoteeraus",
  desc: "Täältä voit tarkastella henkilökohtaista Bridgestone Plus -tiliotetta, jossa on ansaitut ja käytetyt bonuspisteesi. Huomaa, että renkaiden tilausten pisteiden lisäys voi kestää jopa 6 viikkoa lähteen mukaan.",
  score: "Pisteet",
  regularPoints: "Normaali pisteet",
  boosterPoints: "Bonuspisteet",
  date: "Päivämäärä",
  supplySourceReason: "Lähde/Ostosyksikön syy",
  article: "Tuote",
  brand: "Merkki",
  product: "Tuote",
  type: "Tyyppi",
  inch: "Tuuma",
  season: "Kausi",
  amount: "Määrä",
  standardPoints: "Pisteet",
  boosterProduct: "Bonustuote",
  boosterSeason: "Bonuskaus",
  otherActions: "Muut toimet tai syyt",
  totalPoints: "Yhteensä pisteet",
  income: "Tulot",
  spending: "Menot",
  lastMonth: "Loppusaldo",
  loadMore: "Lataa lisää 20 tapahtumaa",
  typeFilter: "Suodata lähteen mukaan",
  dateFilter: "Suodata ajan mukaan",
  customer_number: "Asiakasnumero",
  currentPoints: "Nykyiset pisteet",
  calculationDate: "Laskentapäivä",
  positivePoints: "Positiiviset pisteet",
  negativePoints: "Jo käytetty",
  tyresAmount: "Renkaiden määrä vuodessa",
  pointsTotal: "Yhteensä pisteet",
  currentPointState: "Nykyinen pistetilanne",
  currentKickbackState: "Nykyinen hyvitys", // "Kickback" voidaan kääntää myös "raha takaisin"
};
export default {
  messages,
};
